import {
    GET_CUSTOMERS,
    ADD_CUSTOMER,
    CLEAR_CUSTOMER,
    UPDATE_CUSTOMER,
    CLEAR_UPDATE_CUSTOMER,
    DELETE_CUSTOMER
} from '../../actions/admin/types';

export default function(state={},action){
    switch(action.type){
        case GET_CUSTOMERS:
            return {
                ...state, 
                customers: action.payload
            }
        case ADD_CUSTOMER:
            return {
                ...state,
                addCustomer: action.payload
            }
        case CLEAR_CUSTOMER:
            return {
                ...state,
                addCustomer: action.payload
            }
        case UPDATE_CUSTOMER:
            return {
                ...state,
                updateCustomer: action.payload
            }
        case CLEAR_UPDATE_CUSTOMER:
            return {
                ...state,
                updateCustomer: action.payload
            }
        case DELETE_CUSTOMER:
            return {
                ...state,
                deleteCustomer: action.payload
            }
        default:
            return state;
    }
}