import React from 'react';
import MemberLoginForm from './memberloginform';

const MemberLoginPage = () => {

    const [height, heightHandler] = React.useState(window.innerHeight);
    const [imageStatus, imageStatusHandler] = React.useState(false);

    React.useEffect(() => {

        function handleWindowSizeChange() {
            heightHandler(window.innerHeight);
        };

        // Bind the event listener
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const handleImageLoaded = () => {
        imageStatusHandler(true);
    }

    const handleImageErrored = () => {
        imageStatusHandler(false);
    }

    return (
        <div className="loginWrapper" style={{
            backgroundImage: 'url(/assets/images/dark-material-bg.jpg)'
        }}>
            <div className="loginMemberContainer">
                <div className="loginFlexDirectionRow" style={{
                    minHeight: height
                }}>
                    <div className="loginMemberTitle textWhite">
                        <div className="memberLoginLogo">
                            <img
                                className="logo_img pcHidden"
                                src={window.location.origin + "/assets/images/lumisoft_white.png"}
                                alt=""
                                onLoad={handleImageLoaded}
                                onError={handleImageErrored}
                            />
                        </div>
                        <div className="memberLoginTitle">
                            <h1 className="mb1rem textWhite">
                                Welcome to LUMISOFT
                            </h1>
                        </div>
                        <div className="memberLoginDescription">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </div>
                    {
                        imageStatus ?
                            <div className={imageStatus ? "loginFormWrapper loginFormWrapperOpen" : "loginFormWrapper"}>
                                <div className="loginFormInner">
                                    <div className="memberLoginFormLogo">
                                        <img
                                            className="logo_img pcHidden"
                                            src={window.location.origin + "/assets/images/memberMobileLogo.png"}
                                            alt=""
                                            onLoad={handleImageLoaded}
                                            onError={handleImageErrored}
                                            width="190px"
                                        />
                                    </div>
                                    <div className="memberLoginFormTitle mb30">LOGIN TO YOUR ACCOUNT</div>
                                    <MemberLoginForm />
                                </div>
                            </div>
                            : null
                    }

                </div>
            </div>
        </div>
    );
}


export default MemberLoginPage;