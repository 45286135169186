import React from 'react';

import {
    useSelector,
    useDispatch
} from 'react-redux';

import MainLayout from '../../hoc/mainlayout';
import CustomerTable from './customerTable';
import { auth } from '../../../reduceractions/actions/admin/user_actions';
import { getCustomers } from '../../../reduceractions/actions/admin/customer_actions';
import {
    getProvince,
    getCity
} from '../../../reduceractions/actions/admin/ongkir_actions';
import { useNavigate } from 'react-router-dom';

const MainCustomers = () => {

    const customers = useSelector(state => state.customers.customers && state.customers.customers.customers);
    const province = useSelector(state => state.ongkir.province && state.ongkir.province.province);
    const city = useSelector(state => state.ongkir.city && state.ongkir.city.city);
    // const subdistrict = useSelector(state => state.ongkir.subdistrict && state.ongkir.subdistrict.subdistricts);
    const userdata = useSelector(state => state.user.userData);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [width, widthHandler] = React.useState(window.innerWidth);

    const [number, numberHandler] = React.useState('');

    const isMobile = width <= 767.98;
    const isTablet = width >= 767.99 && width <= 799.98;
    const isTabletPlus = width >= 799.99 && width <= 835.98;
    const isLaptop = width >= 835.99 && width <= 1025.98;
    const isMoreThanLaptop = width >= 1025.99;
    let yearNow = new Date().getFullYear().toString();
    var datanumber = `${userdata && userdata.code}/CS/${yearNow}/${number + 1}`;

    const [alldata, alldataHandler] = React.useState([]);
    const [allprovince, allprovinceHandler] = React.useState([]);
    const [allcity, allcityHandler] = React.useState([]);
    const [mycustomers, mycustomersHandler] = React.useState([]);

    const [createstatus, createstatusHandler] = React.useState(false);
    const [editstatus, editstatusHandler] = React.useState(false);
    const [selectedThisData, selectedThisDataHandler] = React.useState({});

    React.useEffect(() => {

        function handleWindowSizeChange() {
            widthHandler(window.innerWidth);
        };

        // Bind the event listener
        document.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (userdata && customers && customers.length > 0) {
                let mycustomers = [];
                customers.forEach((item, index) => {
                    if (item.uid._id.toString() === userdata.id.toString()) {
                        mycustomers.push(item)
                    }
                })
                alldataHandler(customers)
                mycustomersHandler(mycustomers)
                numberHandler(userdata.number)
            }
            if (province && province.length > 0) {
                allprovinceHandler(province)
            }
            if (city && city.length > 0) {
                allcityHandler(city)
            }
        }
        return () => {
            mounted = false;
        };
    }, [city, customers, province, userdata])

    const selectedNumber = (selected) => {
        numberHandler(selected)
    }

    const onRefresh = () => {
        const fetchUser = async () => {
            return dispatch(auth())
        }
        const fetchData = async (URLs) => {
            const promise = await Promise.all(URLs.map(fetchBulkData));
            const allEqual = promise.every((val) => val.success === true);
            return allEqual;
        }
        let URLs = [
            dispatch(getCustomers()),
            dispatch(getProvince()),
            dispatch(getCity())
        ]
        fetchUser().then(async (response) => {
            let user = response.payload;
            if (!user.isAuth) {
                navigate('/auth')
            } else {
                await fetchData(URLs)
            }
        })
    }

    async function fetchBulkData(URL) {
        return URL.then(function (response) {
            if (response.payload.success) {
                return response.payload;
            } else {
                let failedResponse = { success: false };
                return failedResponse
            }
        }).catch(function (error) {
            return { success: false, error: error };
        });
    }

    return (
        <MainLayout>
            <div className="masterContainer">
                <div className="masterContent">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${isMobile ? "card" : "card minHeightCustomer"}`}>
                                <h4 className="cardTitle"><strong>Customer</strong>
                                    {
                                        createstatus &&
                                            number !== "" ? <span> No: {datanumber}</span>
                                            : editstatus ? <span> Edit: {selectedThisData && selectedThisData.customerid}</span>
                                                : <span> List</span>
                                    }
                                </h4>
                                <CustomerTable
                                    // loadingHandler={loadinglocalHandler}
                                    alldata={alldata}
                                    allprovince={allprovince}
                                    allcity={allcity}
                                    handleRefresh={onRefresh}
                                    tableName="customerTable"
                                    isMobile={isMobile}
                                    isTablet={isTablet}
                                    isTabletPlus={isTabletPlus}
                                    isLaptop={isLaptop}
                                    isMoreThanLaptop={isMoreThanLaptop}
                                    createstatus={createstatus}
                                    createstatusHandler={createstatusHandler}
                                    editstatus={editstatus}
                                    editstatusHandler={editstatusHandler}
                                    selectednumber={selectedNumber}
                                    selectedThisData={selectedThisData}
                                    selectedThisDataHandler={selectedThisDataHandler}
                                    datanumber={datanumber}
                                    mycustomers={mycustomers}
                                    userdata={userdata}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default MainCustomers;