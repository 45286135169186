import axios from 'axios';
import {
    UPLOADPDF_QUOTATION
} from './types';

import { PDF_SERVER } from '../../misc';

export function uploadpdfQuotation(dataToSubmit){
    const request = axios.post(`${PDF_SERVER}/pdfquotation`,dataToSubmit)
        .then(response => response.data);

    return {
        type: UPLOADPDF_QUOTATION,
        payload: request
    }
}