import React from 'react';

import { FaBars } from 'react-icons/fa'

const drawerToggle = props => (
    <button className="toggle_button" onClick={props.click}>
        <FaBars className="icon" />
    </button>
);

export default drawerToggle;