/////////////////////////////////////////////////////////////////////
///////////////////////////// PRODUCTS //////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_PRODUCTS = 'get_products';
export const ADD_PRODUCT = 'add_product';
export const CLEAR_PRODUCT = 'clear_product';
export const UPDATE_PRODUCT = 'update_product';
export const UPDATE_PRODUCT_SINGLE = 'update_product_single';
export const UPDATE_ALL_PRODUCT = 'update_all_product';
export const CLEAR_UPDATE_PRODUCT = 'clear_update_product';
export const DELETE_PRODUCT = 'delete_product';