import {
    GET_PROVINCE,
    GET_CITY,
    GET_SUBDISTRICT,
    POST_COST
} from '../../actions/admin/types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_PROVINCE:
            return {
                ...state,
                province: action.payload
            }
        case GET_CITY:
            return {
                ...state,
                city: action.payload
            }
        case GET_SUBDISTRICT:
            return {
                ...state,
                subdistrict: action.payload
            }
        case POST_COST:
            return {
                ...state,
                shippingcost: action.payload
            }
        default:
            return state;
    }
}