import React from 'react';

// import CircularProgress from '@material-ui/core/CircularProgress';
// import moment from 'moment';

// import BackDrop from '../../../layout/components/memberheaderfooter/backdrop';
import Form from './form';
import Pagination from '../../../utils/pagination';
import EditForm from './editForm';
import PasswordForm from './passwordForm';
import { Exportxls } from '../../../utils/exportxls';

import { FaDownload, FaKey, FaPencilAlt, FaPlus, FaRedoAlt, FaSearch } from 'react-icons/fa'
import { useSelector } from 'react-redux';

const UserTable = ({
    // loadingHandler,
    userdata,
    alldata,
    allroles,
    handleRefresh,
    tableName,
    isMobile,
    createstatusHandler,
    editstatusHandler,
    changepasswordHandler,
    selectednumber,
    selectedThisData,
    selectedThisDataHandler,
    currentnumber
}) => {

    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings);

    const numberThisData = alldata && alldata.length;
    const [filterText, filterTextHandler] = React.useState('');
    const [currentPage, currentPageHandler] = React.useState(1);
    const [pageSize, pageSizeHandler] = React.useState(10);
    const [showForm, showFormHandler] = React.useState(false);
    const [showEditForm, showEditFormHandler] = React.useState(false);
    const [showPasswordForm, showPasswordFormHandler] = React.useState(false);

    const [dataCount, dataCountHandler] = React.useState("");
    const [mydata, mydataHandler] = React.useState([]);

    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    pageSizeHandler(selected.pagesize)
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber);
    }

    const gotoDetails = React.useCallback((data) => {
        filterTextHandler('')
        currentPageHandler(1);
        showEditFormHandler(prevState => !prevState.showEditForm)
        selectedThisDataHandler(data)
        editstatusHandler(true);
    }, [selectedThisDataHandler, editstatusHandler])

    const gotoPassword = React.useCallback((data) => {
        filterTextHandler('')
        currentPageHandler(1);
        showPasswordFormHandler(prevState => !prevState.showPasswordForm)
        selectedThisDataHandler(data);
        changepasswordHandler(true);
    }, [selectedThisDataHandler, changepasswordHandler])

    const gotoDetailsRef = React.useRef(gotoDetails);
    const gotoPasswordRef = React.useRef(gotoPassword);

    React.useEffect(() => {
        gotoDetailsRef.current = gotoDetails;
    }, [gotoDetails]);

    React.useEffect(() => {
        gotoPasswordRef.current = gotoPassword;
    }, [gotoPassword]);

    function fn(text, count) {
        // return text.slice(0, count);
        return text.slice(0, count) + (text.length > count ? "..." : "");
    }

    const TableRow = React.useCallback((thedata, index) => {
        return (
            <tr className="verticalCenter" key={index}>
                <td>
                    {index + 1}
                </td>
                <td>
                    {thedata.code ? thedata.code : ""}_{thedata.number}
                </td>
                <td>
                    {fn(thedata.name, 20)}
                </td>
                <td>
                    {thedata.role ? thedata.role.name : ""}
                </td>
                <td>
                    {thedata.upline ? thedata.upline.name : ""}
                </td>

                <td>
                    <div className="inlineFlex">
                        <div
                            className="agrapanaBtn redBackground p8 mr5"
                            title=""
                            onClick={() => gotoDetailsRef.current(thedata)}
                        >
                            <FaPencilAlt className="icon agraicon w18px" />
                        </div>
                        {
                            userdata && userdata.masteradmin === 1 ?
                                <div
                                    className="agrapanaBtn blueBackground p8"
                                    title=""
                                    onClick={() => gotoPasswordRef.current(thedata)}
                                >
                                    <FaKey className="icon agraicon w18px" />
                                </div>
                                : null
                        }
                    </div>
                </td>
            </tr>
        );
    }, [userdata])

    React.useEffect(() => {
        var results = [];
        var resultsdata = [];
        var offset = (currentPage - 1) * pageSize;
        if (alldata) {
            // var filterdata = alldata.filter((data, i) => data.name !== 'LUMISOFT');
            results = alldata.filter(data =>
            (
                data.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                || data.code.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                || data.role.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                || data.upline.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                || data.phone.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
            ))
            resultsdata = results.map((item, index) => TableRow(item, index))
            var semuadata = [...resultsdata];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            dataCountHandler(results.length);
            mydataHandler(mydatas);
        }

    }, [filterText, currentPage, alldata, pageSize, TableRow])

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1);
    }

    const handleUpdate = () => {
        filterTextHandler('')
        currentPageHandler(1);
        handleRefresh();
    }

    const handleAdd = () => {
        filterTextHandler('')
        currentPageHandler(1);
        createstatusHandler(true)
        selectednumber(numberThisData)
        showFormHandler(true)
    }
   
    const exportXls = () => {
        var re = alldata;
        var data = [];
        var sales = [];
        var head = [
            "No",
            "Usercode",
            "Name",
            "KTP",
            "Email",
            "Phone",
            "Date of birth",
            "Role",
            "Upline"
        ]

        var headsales = [
            "No",
            "Usercode",
            "Name",
            "KTP",
            "Email",
            "Phone",
            "Date of birth"
        ]

        for (var item = 0; item < re.length; item++) {
            let roleFolder = re[item].role
            let uplineFolder = re[item].upline === undefined ? { name: 'Owner' } : re[item].upline;

            data.push([
                item + 1,
                re[item].usercode,
                re[item].name,
                re[item].ktp,
                re[item].email,
                re[item].phone,
                re[item].dob,
                roleFolder.name,
                uplineFolder.name
            ]);
        }

        for (var salesman = 0; salesman < re.length; salesman++) {
            // const allProductSelectedCopy = re[product].product;

            // for (let key in allProductSelectedCopy) {
            //     products[key] = []
            //     products[key].push(allProductSelectedCopy[key].name);
            // }

            sales[salesman] = [];
            for (var q = 0; q < re[salesman].downline.length; q++) {
                sales[salesman].push([
                    q + 1,
                    re[salesman].downline[q].usercode,
                    re[salesman].downline[q].name,
                    re[salesman].downline[q].ktp,
                    re[salesman].downline[q].email,
                    re[salesman].downline[q].phone,
                    re[salesman].downline[q].dob
                ])
            }
        }

        // Exportxls(head, data)
        Exportxls(head, data, sales, headsales)
    }

    const wizardForm = `${showForm || showEditForm || showPasswordForm ? 'displayNone' : 'cardBody'}`;

    return (
        <div>
            <Form
                alldata={alldata}
                allroles={allroles}
                show={showForm}
                showFormHandler={showFormHandler}
                isMobile={isMobile}
                createstatusHandler={createstatusHandler}
                currentnumber={currentnumber}
            />

            <EditForm
                alldata={alldata}
                allroles={allroles}
                showEdit={showEditForm}
                showFormHandler={showFormHandler}
                showEditFormHandler={showEditFormHandler}
                handleRefresh={handleRefresh}
                isMobile={isMobile}
                numberThisData={numberThisData}
                selectednumber={selectednumber}
                editstatusHandler={editstatusHandler}
                selectedThisData={selectedThisData}
                selectedThisDataHandler={selectedThisDataHandler}
                userdata={userdata}
            />

            <PasswordForm
                showPassword={showPasswordForm}
                showPasswordFormHandler={showPasswordFormHandler}
                isMobile={isMobile}
                numberThisData={numberThisData}
                selectednumber={selectednumber}
                changepasswordHandler={changepasswordHandler}
                selectedThisData={selectedThisData}
            />


            <div className={wizardForm}>
                <div className="flexbox mb20 centerXs">
                    <div className="lookup">
                        <div className="iconPosition">
                            <FaSearch className="icon agraicon w18px" />
                        </div>
                        <input
                            className="w200px"
                            type="text"
                            name="Search"
                            placeholder="Search"
                            title="Type in a name"
                            value={filterText}
                            onChange={(event) => handleChange(event)}
                        />
                        <div className="pageNumber">{currentPage}/{parseInt((dataCount / pageSize) + ((dataCount % pageSize) > 0 ? 1 : 0))} Pages</div>

                    </div>
                    <div className="btnToolbar">
                        <div className="btnGroup btnGroupSM">
                            <button
                                className="btn"
                                title=""
                                onClick={() => handleUpdate()}
                            >
                                <FaRedoAlt className="icon agraicon w18px" />
                            </button>
                            <button
                                className="btn"
                                title=""
                                onClick={() => handleAdd()}
                            >
                                <FaPlus className="icon agraicon w18px" />
                            </button>
                        </div>
                        {
                            isMobile ? null
                                :
                                userdata && userdata.masteradmin === 1 ?
                                    <div className="btnGroup btnGroupSM mlHalfRem dFlex">
                                        <button
                                            className="btn"
                                            title=""
                                            onClick={() => exportXls()}
                                        >
                                            <FaDownload className="icon agraicon w18px" />
                                        </button>
                                    </div>
                                    : null
                        }
                    </div>
                </div>
                <div>
                    <div className="tableResponsiveSm">
                        <table className="table" id={tableName}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th>Upline</th>
                                    <th>Action</th>

                                    {/* <th>Province</th>
                                            <th>City</th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    alldata.length === 0 ?
                                        <tr className="textCenter fw500 bg_table textWhite">
                                            <td colSpan={"7"}>
                                                Now Loading....
                                            </td>
                                        </tr>
                                        :
                                        mydata.length === 0
                                            ?
                                            <tr className="textCenter fw500 bg_table textWhite">
                                                <td colSpan={"6"}>
                                                    Data not exist
                                                </td>
                                            </tr>
                                            : mydata
                                }
                            </tbody>

                        </table>
                    </div>
                    {
                        alldata.length === 0 ?
                            null
                            :
                            <nav className="mt10">
                                <Pagination Size={dataCount} pageLimit={pageSize} onPageChanged={pageChanged} currentPage={currentPage} width={isMobile} />
                            </nav>
                    }

                </div>

            </div>
        </div>
    );
}

export default UserTable;