import {
    GET_SETTING,
    UPDATE_SETTING,
    CLEAR_UPDATE_SETTING
} from '../../actions/general/types';

export default function(state={},action){
    switch(action.type){
        case GET_SETTING:
            return {
                ...state, 
                settings: action.payload
            }
        case UPDATE_SETTING:
            return {
                ...state,
                updateSetting: action.payload
            }
        case CLEAR_UPDATE_SETTING:
            return {
                ...state,
                updateSetting: action.payload
            }
        default:
            return state;
    }
}