import React, { Component } from 'react';
// import { NavLink, withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll';

import DrawerToggle from './sidedrawer/drawertoggle';

// import { connect } from 'react-redux';
import { FaHome, FaDesktop } from 'react-icons/fa';

class HeaderSection extends Component {

    state = {
        localCartItems: [],
        headerShow: false,
        page: [
            {
                name: 'Home',
                linkTo: 'home',
                public: true,
                faicons: <FaHome className="icon" />
            },
            {
                name: 'About',
                linkTo: 'about',
                public: true,
                faicons: <FaDesktop className="icon" />
            },
            {
                name: 'Contact',
                linkTo: 'contact',
                public: true,
                faicons: <FaDesktop className="icon" />
            }
            // {
            //     name: 'Products',
            //     linkTo: 'products',
            //     public: true,
            //     faicons: faDesktop
            // },

        ],
        // user: [
        //     {
        //         name: 'Login',
        //         linkTo: '/login',
        //         public: true,
        //         faicons: faSignInAlt
        //     },
        //     {
        //         name: 'Logout',
        //         linkTo: '/logout',
        //         public: false,
        //         faicons: faSignOutAlt
        //     }
        // ]
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    scrollToElement = (element) => {
        scroller.scrollTo(element, {
            duration: 1000,
            delay: 100,
            smooth: true
        });
    }

    defaultLink = (item, i) => {
        return (
            <li
                key={i}
            >
                <button
                    // to={item.linkTo}
                    onClick={() => this.scrollToElement(item.linkTo)}
                // activeClassName="active"
                // exact
                >
                    <div className="itemContent">
                        <div className="itemIcon">
                            {item.faicons}
                        </div>
                    </div>
                </button>
            </li>
        )
    }


    userLink = (item, i) => (
        // item.name === 'Logout' ?
        //     <li key={i}>
        //         <NavLink
        //             to={item.linkTo}
        //         >
        //             <div className="itemContent">
        //                 <div className="itemIcon">
        //                     <FontAwesomeIcon
        //                         icon={item.faicons}
        //                         className="icon"
        //                     />
        //                 </div>
        //             </div>
        //         </NavLink>
        //     </li>
        //     :
        <li
            key={i}
        >
            <button
                onClick={() => this.scrollToElement(item.linkTo)}
            // activeClassName="active"
            // exact
            >
                <div className="itemContent">
                    <div className="itemInner">
                        <span className="title">
                            {item.name}
                        </span>
                    </div>
                </div>
            </button>
        </li>
    )

    showLinks = (type) => {
        let list = [];
        type.forEach((item) => {
            if (item.public === true) {
                list.push(item)
            }
        })

        // if (this.props.user.userData) {
        //     type.forEach((item) => {
        //         if (!this.props.user.userData.isAuth) {
        //             if (item.public === true) {
        //                 list.push(item)
        //             }
        //         } else {
        //             if (item.name !== 'Login') {
        //                 list.push(item)
        //             }
        //         }
        //     });
        // }

        return list.map((item, i) => {
            if (item.name !== 'Login') {
                return this.userLink(item, i)
            } else {
                return this.defaultLink(item, i)
            }
        })
    }

    handleScroll = () => {
        let nowscrolling = window.scrollY;
        let isScrolling = nowscrolling > 10;
        if (isScrolling) {
            this.setState({
                headerShow: true
            })
        } else {
            this.setState({
                headerShow: false
            })
        }
    }

    render() {
        const { headerShow } = this.state;
        return (
            <header className={headerShow ? "topBar black" : "topBar transparent"}>
                <div className="container">
                    <div style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div className="topBarLeft">
                            <div className="toggleButton white">
                                <DrawerToggle click={this.props.drawerClickHandler} />
                            </div>
                            <div className="logo">
                                <a href="/">
                                    <img className="logo_img mobileHidden" src={headerShow ? window.location.origin + "/assets/images/dianjelitalogo.png" : window.location.origin + "/assets/images/dianjelitalogo.png"} alt="" width="50" />
                                    <img className="logo_img pcHidden" src={headerShow ? window.location.origin + "/assets/images/dianjelitalogo.png" : window.location.origin + "/assets/images/dianjelitalogo.png"} alt="" width="50" />
                                </a>
                            </div>
                        </div>
                        <div className="topBarRight">
                            <div className="clear"></div>
                            <div className={headerShow ? "navigation_items" : "navigation_items white"}>
                                <ul>
                                    <span className="mobileDisplayNone">{this.showLinks(this.state.page)}</span>

                                    {/* {this.showLinks(this.state.profile)} */}
                                    {/* <span className="mobileDisplayNone">
                                {this.showLinks(this.state.user)}
                            </span> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default HeaderSection;