import axios from 'axios';
import {
    GET_SETTING,
    UPDATE_SETTING,
    CLEAR_UPDATE_SETTING
} from './types';

import { SETTING_SERVER } from '../../misc';

export function getSetting(){
    const request = axios.get(`${SETTING_SERVER}/getsetting`)
    .then(response => response.data);

    return {
        type: GET_SETTING,
        payload: request
    }
}

export function updateSetting(dataToSubmit){
    const request = axios.post(`${SETTING_SERVER}/updatesetting`,dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_SETTING,
        payload: request
    }
}

export function clearUpdateSetting(){
    return {
        type: CLEAR_UPDATE_SETTING,
        payload: ''
    }
}