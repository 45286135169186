import React from 'react';
import { FaPlus, FaRedoAlt, FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTerms } from '../../../reduceractions/actions/admin/termofpayment_actions';
import Pagination from '../../../utils/pagination';

import MainLayout from '../../hoc/mainlayout';
import TermsFormEdit from './editForm';
import TermsFormAdd from './form';

const TermsMainPage = () => {

    const userdata = useSelector(state => state.user.userData);
    const getallterms = useSelector(state => state.termofpayment.terms && state.termofpayment.terms.termofpayments)
    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [width, widthHandler] = React.useState(window.innerWidth);
    const isMobile = width <= 767.98;

    const [allterms, alltermsHandler] = React.useState([])

    const [showForm, showFormHandler] = React.useState(false);
    const [showEditForm, showEditFormHandler] = React.useState(false);

    const [filterText, filterTextHandler] = React.useState('');
    const [currentPage, currentPageHandler] = React.useState(1);
    const [pageSize, pageSizeHandler] = React.useState(10);
    const [dataCount, dataCountHandler] = React.useState("");
    const [mydata, mydataHandler] = React.useState([]);

    const [selectedThisData, selectedThisDataHandler] = React.useState({});

    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    pageSizeHandler(selected.pagesize)
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    React.useEffect(() => {

        function handleWindowSizeChange() {
            widthHandler(window.innerWidth);
        };

        // Bind the event listener
        document.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    }, [])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (userdata && userdata.masteradmin !== 1) {
                navigate('/admin/notauthorize')
            }

        }
        return () => {
            mounted = false;
        };
    }, [userdata, navigate])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                if (getallterms && getallterms.length > 0) {
                    alltermsHandler(getallterms);
                }
            }
            fetchData()

        }
        return () => {
            mounted = false;
        };
    }, [getallterms])

    const gotoDetails = React.useCallback((data) => {
        filterTextHandler('')
        currentPageHandler(1);
        showEditFormHandler(prevState => !prevState.showEditForm)
        selectedThisDataHandler(data)
    }, [selectedThisDataHandler])

    const gotoDetailsRef = React.useRef(gotoDetails);

    React.useEffect(() => {
        gotoDetailsRef.current = gotoDetails;
    }, [gotoDetails]);

    function fn(text, count) {
        // return text.slice(0, count);
        return text.slice(0, count) + (text.length > count ? "..." : "");
    }

    const TableRow = React.useCallback((thedata, index) => {
        return (
            <tr className="verticalCenter" onClick={() => gotoDetailsRef.current(thedata)} key={index}>
                <td>
                    {index + 1}
                </td>
                <td>
                    {fn(thedata.name, 50)}
                </td>
            </tr>
        );
    }, [])

    React.useEffect(() => {
        var results = [];
        var resultsdata = [];
        var offset = (currentPage - 1) * pageSize;
        if (allterms) {
            // var filterdata = allterms.filter((data, i) => data.name !== 'LUMISOFT');
            results = allterms.filter(data =>
            (
                data.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
            ))
            resultsdata = results.map((item, index) => TableRow(item, index))
            var semuadata = [...resultsdata];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            dataCountHandler(results.length);
            mydataHandler(mydatas);
        }

    }, [filterText, currentPage, allterms, pageSize, TableRow])


    const onRefresh = async () => {
        const getTheTerms = await dispatch(getTerms());

        if (getTheTerms.payload.success) {
            alltermsHandler(getTheTerms.payload.termofpayments);
        }
    }

    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber);
    }

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1);
    }

    const handleUpdate = () => {
        filterTextHandler('')
        currentPageHandler(1);
        onRefresh();
    }

    const handleAdd = () => {
        filterTextHandler('')
        currentPageHandler(1);
        showFormHandler(true)
    }

    const wizardForm = `${showForm || showEditForm ? 'displayNone' : 'cardBody'}`;

    return (
        <MainLayout>
            <div className="masterContainer">
                <div className="masterContent">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${isMobile ? "card" : "card minHeightCustomer"}`}>
                                <h4 className="cardTitle">
                                    <strong>Term of payment</strong>
                                </h4>
                                <div>
                                    <TermsFormAdd
                                        show={showForm}
                                        isMobile={isMobile}
                                        showFormHandler={showFormHandler}
                                    />

                                    <TermsFormEdit
                                        show={showEditForm}
                                        isMobile={isMobile}
                                        selectedThisData={selectedThisData}
                                        showEditFormHandler={showEditFormHandler}
                                    />
                                    <div className={wizardForm}>
                                        <div className="flexbox mb20 centerXs">
                                            <div className="lookup">
                                                <div className="iconPosition">
                                                    <FaSearch className="icon agraicon w18px" />
                                                </div>
                                                <input
                                                    className="w200px"
                                                    type="text"
                                                    name="Search"
                                                    placeholder="Search"
                                                    title="Type in a name"
                                                    value={filterText}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                <div className="pageNumber">{currentPage}/{parseInt((dataCount / pageSize) + ((dataCount % pageSize) > 0 ? 1 : 0))} Pages</div>

                                            </div>
                                            <div className="btnToolbar">
                                                <div className="btnGroup btnGroupSM">
                                                    <button
                                                        className="btn"
                                                        title=""
                                                        onClick={() => handleUpdate()}
                                                    >
                                                        <FaRedoAlt className="icon agraicon w18px" />
                                                    </button>
                                                    <button
                                                        className="btn"
                                                        title=""
                                                        onClick={() => handleAdd()}
                                                    >
                                                        <FaPlus className="icon agraicon w18px" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="tableResponsiveSm">
                                                <table className="table" id="termTable" >
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            allterms.length === 0 ?
                                                                <tr className="textCenter fw500 bg_table textWhite">
                                                                    <td colSpan={"7"}>
                                                                        Now Loading....
                                                                    </td>
                                                                </tr>
                                                                :
                                                                mydata.length === 0
                                                                    ?
                                                                    <tr className="textCenter fw500 bg_table textWhite">
                                                                        <td colSpan={"6"}>
                                                                            Data not exist
                                                                        </td>
                                                                    </tr>
                                                                    : mydata
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                            {
                                                allterms.length === 0 ?
                                                    null
                                                    :
                                                    <nav className="mt10">
                                                        <Pagination Size={dataCount} pageLimit={pageSize} onPageChanged={pageChanged} currentPage={currentPage} width={isMobile} />
                                                    </nav>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )

}

export default TermsMainPage;