import axios from 'axios';
import {
    GET_REQUESTS,
    GET_QUOTATIONS,
    GET_QUOTATION_HISTORY,
    ADD_QUOTATION,
    CLEAR_QUOTATION,
    UPDATE_QUOTATION,
    CLEAR_UPDATE_QUOTATION,
    REVISI_QUOTATION,
    CLEAR_REVISI_QUOTATION,
    APPROVE_QUOTATION,
    REJECT_QUOTATION
} from './types';

import {
    QUOTATION_SERVER
} from '../../misc';

export function getRequests() {
    const request = axios.get(`${QUOTATION_SERVER}/requests`)
        .then(response => response.data);

    return {
        type: GET_REQUESTS,
        payload: request
    }
}

export function getQuotations() {
    const request = axios.get(`${QUOTATION_SERVER}/quotations`)
        .then(response => response.data);

    return {
        type: GET_QUOTATIONS,
        payload: request
    }
}

export function getQuotationHistory() {
    const request = axios.get(`${QUOTATION_SERVER}/quotationhistory`)
        .then(response => response.data);

    return {
        type: GET_QUOTATION_HISTORY,
        payload: request
    }
}

export function addQuotation(dataToSubmit) {
    const request = axios.post(`${QUOTATION_SERVER}/quotation`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_QUOTATION,
        payload: request
    }
}

export function clearQuotation() {
    return {
        type: CLEAR_QUOTATION,
        payload: ''
    }
}

export function updateQuotation(dataToSubmit) {
    const request = axios.post(`${QUOTATION_SERVER}/update`, dataToSubmit)
        .then(response => response.data);
    // .then(response => {
    //     return response.data
    // });

    return {
        type: UPDATE_QUOTATION,
        payload: request
    }
}

export function clearUpdateQuotation() {
    return {
        type: CLEAR_UPDATE_QUOTATION,
        payload: ''
    }
}

export function revisiQuotation(dataToSubmit) {
    const request = axios.post(`${QUOTATION_SERVER}/revisi`, dataToSubmit)
        .then(response => response.data);
    // .then(response => {
    //     return response.data
    // });

    return {
        type: REVISI_QUOTATION,
        payload: request
    }
}

export function clearRevisiQuotation() {
    return {
        type: CLEAR_REVISI_QUOTATION,
        payload: ''
    }
}

export function approveQuotation(dataToSubmit) {
    const request = axios.post(`${QUOTATION_SERVER}/approve`, dataToSubmit)
        .then(response => response.data);
    // .then(response => {
    //     return response.data
    // });

    return {
        type: APPROVE_QUOTATION,
        payload: request
    }
}

export function rejectQuotation(dataToSubmit) {
    const request = axios.post(`${QUOTATION_SERVER}/reject`, dataToSubmit)
        .then(response => response.data);
    // .then(response => {
    //     return response.data
    // });

    return {
        type: REJECT_QUOTATION,
        payload: request
    }
}
// export function deleteCustomer(customerid){
//     const request = axios.delete(`${QUOTATION_SERVER}/deletecustomer/${customerid}`)
//         .then(response => response.data);

//     return {
//         type: DELETE_CUSTOMER,
//         payload: request
//     }
// }