import React from 'react';
import {
    useDispatch
} from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import CircularProgress from '@material-ui/core/CircularProgress';
import Loadingscreen from '../../../utils/loadingscreen';
import { logoutUser } from '../../../reduceractions/actions/admin/user_actions';

const LogoutPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(logoutUser()).then(response => {
            if (response.payload.success) {
                navigate('/auth');
            }
        })
    }, [dispatch, navigate])

    return (
        <Loadingscreen />
    );
}

export default LogoutPage;