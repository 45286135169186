import React, { Component } from 'react';
// import { connect } from 'react-redux';

// import { NavLink, withRouter } from 'react-router-dom';
// import { logoutUser } from '../../../../../reducersactions/actions/website/user_actions';
import { scroller } from 'react-scroll';

import { FaHome, FaListUl, FaUser } from 'react-icons/fa';

class SideDrawer extends Component {

    state = {
        publicnav: [
            {
                name: 'Home',
                linkTo: 'home',
                public: true,
                faicons: <FaHome className="icon" />
            },
            {
                name: 'About',
                linkTo: 'about',
                public: true,
                faicons: <FaListUl className="icon" />
            },
            {
                name: 'Contact',
                linkTo: 'contact',
                public: true,
                faicons: <FaUser className="icon" />
            }
        ],
    }

    scrollToElement = (element) => {
        scroller.scrollTo(element, {
            duration: 1000,
            delay: 100,
            smooth: true
        });
        this.props.click();
    }

    publicLink = (items) => (
        items.map((item, i) => (
            <li key={i}>
                <button
                    onClick={() => this.scrollToElement(item.linkTo)}
                >
                    <div className="itemContent">
                        <div className="itemIcon">
                            {item.faicons}
                        </div>
                        <div className="itemInner">
                            <span className="title">{item.name}</span>
                        </div>
                    </div>
                </button>
            </li>
        ))
    )

    render() {
        const className = `sideDrawer ${this.props.show ? 'open' : ''}`;
        return (
            <nav className={className}>
                <div className="sideDrawerLogo">
                    <img className="logo_img" src={window.location.origin + "/assets/images/dianjelitalogo.png"} alt="" width="50" />
                </div>

                <ul>
                    {this.publicLink(this.state.publicnav)}
                </ul>
            </nav >
        );

    }

};


export default SideDrawer;