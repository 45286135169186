import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import CircularProgress from '@material-ui/core/CircularProgress';
import BackDrop from '../../../admin/components/headerfooter/backdrop';
import DoubleBackDrop from '../../../admin/components/headerfooter/doublebackdrop';

import SearchForm from '../../../utils/searchform/cannotadd';
import PopupMessageNoAdd from '../../../utils/popupmessage/noadd';

import FormField from '../../../utils/form/formfield';
import {
    update,
    validate,
    generateData,
    isFormValid,
    resetFields,
    provinceCheckoutOptionFields,
    cityCheckoutOptionFields
    // subdistrictCheckoutOptionFields
} from '../../../utils/form/formactions';
import {
    addCustomer,
    clearCustomer
} from '../../../reduceractions/actions/admin/customer_actions';

// import { 
//     getProvince, 
//     getCity, 
//     getSubdistrict, 
//     postCost 
// } from '../../../reduceractions/actions/admin/ongkir_actions';
import {
    getSubdistrict
} from '../../../reduceractions/actions/admin/ongkir_actions';

import { FaChevronLeft, FaEnvelope, FaMapMarker, FaMobileAlt, FaRoad, FaUser } from 'react-icons/fa'

const CustomerForm = ({
    allprovince,
    allcity,
    show,
    showFormStatus,
    // handleRefresh,
    isMobile,
    // isTablet,
    // isTabletPlus,
    // isLaptop,
    // isMoreThanLaptop,
    numberThisData,
    selectednumber,
    // createstatus,
    createstatusHandler,
    loadingHandler,
    // code,
    datanumber,
    // mycustomers,
    userdata
}) => {

    const dispatch = useDispatch();

    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings);

    const [overlayForm, overlayFormHandler] = React.useState(false);
    const [filterText, filterTextHandler] = React.useState('');
    const [currentPage, currentPageHandler] = React.useState(1);
    const [pageSize, pageSizeHandler] = React.useState(10);

    const [process, processHandler] = React.useState(false);
    const [myformData, myformDataHandler] = React.useState('');
    const [myformTitle, myformTitleHandler] = React.useState('');

    const [selectedProvince, selectedProvinceHandler] = React.useState();

    const [city, cityHandler] = React.useState();
    const [selectedCity, selectedCityHandler] = React.useState();

    const [selectedSubdistrict, selectedSubdistrictHandler] = React.useState();

    const [popupMessage, popupMessageHandler] = React.useState(false);
    const [formError, formErrorHandler] = React.useState(false);

    const [formSuccess, formSuccessHandler] = React.useState(false);

    const [errorMessage, errorMessageHandler] = React.useState('');
    const [formdata, formdataHandler] = React.useState({
        name: {
            element: 'input',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter full name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        address: {
            element: 'input',
            value: '',
            config: {
                name: 'addressInput',
                type: 'text',
                placeholder: 'Street address Line 1'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        details: {
            element: 'input',
            value: '',
            config: {
                name: 'detailsInput',
                type: 'text',
                placeholder: 'Street address Line 2 (Optional)'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        province: {
            element: 'input',
            value: '',
            config: {
                name: 'provinceInput',
                options: [],
                placeholder: 'Choose your Province'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        city: {
            element: 'input',
            value: '',
            config: {
                name: 'cityInput',
                options: [],
                placeholder: 'Choose your City'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        subdistrict: {
            element: 'input',
            value: '',
            config: {
                name: 'subdistrictInput',
                options: [],
                placeholder: 'Choose your Subdistrict'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        postcode: {
            element: 'input',
            value: '',
            config: {
                name: 'postcodeInput',
                type: 'text',
                placeholder: 'Postcode / ZIP'
            },
            validation: {
                required: true,
                number: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        email: {
            element: 'input',
            value: '',
            config: {
                name: 'emailInput',
                type: 'email',
                placeholder: 'Enter your email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        phone: {
            element: 'input',
            value: '',
            config: {
                name: 'phoneInput',
                type: 'text',
                placeholder: 'Enter phone number'
            },
            validation: {
                required: true,
                phone: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    })

    const executedAnotherOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    pageSizeHandler(selected.pagesize)
                    executedAnotherOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        if (executedOnceRef.current) { return; }
        if (allprovince.length > 0 && allcity.length > 0) {
            const newFormDataProvince = provinceCheckoutOptionFields(formdata, allprovince, 'province');
            const newFormDataCity = cityCheckoutOptionFields(formdata, allcity, 'city');
            formdataHandler(newFormDataProvince);
            formdataHandler(newFormDataCity);
            executedOnceRef.current = true;
        }

    }, [allcity, allprovince, formdata])



    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber);
    }

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'customer');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }

    const selectedItems = (selected) => {
        // console.log(selected)
        var formTitle = myformTitle;

        if (formTitle === "province") {

            const newFormdata = {
                ...formdata
            }

            const newElement = {
                ...newFormdata[formTitle]
            }

            const newElementCity = {
                ...newFormdata['city']
            }

            const newElementSubdistrict = {
                ...newFormdata['subdistrict']
            }

            newElement.value = selected.key;
            newElementCity.value = '';
            newElementSubdistrict.value = '';

            let validData = validate(newElement, formdata);
            newElement.valid = validData[0];
            newElement.validationMessage = validData[1];

            let validDataCity = validate(newElementCity, formdata);
            newElementCity.valid = validDataCity[0];
            newElementCity.validationMessage = validDataCity[1];

            let validDataSubdistrict = validate(newElementSubdistrict, formdata);
            newElementSubdistrict.valid = validDataSubdistrict[0];
            newElementSubdistrict.validationMessage = validDataSubdistrict[1];

            newFormdata[formTitle] = newElement;
            newFormdata['city'] = newElementCity;
            newFormdata['subdistrict'] = newElementSubdistrict;

            if (formTitle === "province") {
                formErrorHandler(false);
                formdataHandler(newFormdata);
                selectedProvinceHandler(selected);
            }

            var newPromise = new Promise((resolve, reject) => {
                let choosencity = [];
                let allcity = formdata.city.config.options;

                allcity.forEach((item, index) => {
                    if (item.provinceid === selected.key) {
                        choosencity.push(item)
                    }
                })
                // let getprovince = JSON.parse('[' + this.props.ongkir.province.province + ']')[0].rajaongkir.results

                if (choosencity) {
                    resolve(choosencity)
                } else {
                    reject("error")
                }
            })

            newPromise.then(result => {
                cityHandler(result);
                overlayFormHandler(false);
            }).catch(error => {
                console.log(error)
            })
        } else if (formTitle === "city") {

            const newFormdata = {
                ...formdata
            }

            const newElement = {
                ...newFormdata[formTitle]
            }

            const newElementSubdistrict = {
                ...newFormdata['subdistrict']
            }

            newElement.value = selected.key;
            newElementSubdistrict.value = '';

            let validData = validate(newElement, formdata);
            newElement.valid = validData[0];
            newElement.validationMessage = validData[1];

            let validDataSubdistrict = validate(newElementSubdistrict, formdata);
            newElementSubdistrict.valid = validDataSubdistrict[0];
            newElementSubdistrict.validationMessage = validDataSubdistrict[1];

            newFormdata[formTitle] = newElement;
            newFormdata['subdistrict'] = newElementSubdistrict;

            if (formTitle === "city") {
                formErrorHandler(false);
                formdataHandler(newFormdata);
                selectedCityHandler(selected);

                var newSubdistrict = new Promise((resolve, reject) => {
                    dispatch(getSubdistrict(selected.key)).then(result => {
                        if (result.payload.success) {
                            let getsubdistrict = result.payload.subdistrict;
                            if (getsubdistrict) {
                                resolve(getsubdistrict)
                            } else {
                                reject("error")
                            }
                        }
                    })
                })

                newSubdistrict.then(result => {
                    // const newFormData = subdistrictCheckoutOptionFields(formdata, result, 'subdistrict');
                    // formdataHandler(newFormData)
                    const newArray = [];
                    result.forEach(item => {
                        newArray.push({
                            key: item.subdistrict_id,
                            name: item.subdistrict_name
                        });
                    });
                    formdataHandler(prevState => {
                        let newFormdata = { ...prevState };
                        newFormdata['subdistrict'].config.options = newArray;
                        return newFormdata
                    })
                    overlayFormHandler(false);
                }).catch(error => {
                    console.log(error)
                })
            }
        } else if (formTitle === 'subdistrict') {
            const newFormdata = {
                ...formdata
            }

            const newElement = {
                ...newFormdata[formTitle]
            }

            newElement.value = selected.key;

            let validData = validate(newElement, formdata);
            newElement.valid = validData[0];
            newElement.validationMessage = validData[1];

            newFormdata[formTitle] = newElement;
            if (formTitle === "subdistrict") {
                formErrorHandler(false);
                formdataHandler(newFormdata);
                selectedSubdistrictHandler(selected);
                overlayFormHandler(false);
            }
        }
    }

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        myformTitleHandler('');
        filterTextHandler('');
        currentPageHandler(1);
        overlayFormHandler(false)
    }

    const backdropPopupMessage = () => {
        document.body.style.overflow = 'overlay';
        goBackToLists();
    }

    const goBackToLists = () => {
        formErrorHandler(false);
        popupMessageHandler(false);
        filterTextHandler('');
        errorMessageHandler('');
    }

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1);
    }

    const searchForm = (item, title, itemid, commonId) => {
        if (title === "province") {
            // loadingHandler(true);
            myformDataHandler(item);
            myformTitleHandler(title);
            selectedProvinceHandler('');
            selectedCityHandler('');
            selectedSubdistrictHandler('')
        } else if (title === "city") {
            // loadingHandler(true);
            myformDataHandler(item);
            myformTitleHandler(title);
            selectedCityHandler('');
            selectedSubdistrictHandler('')
        } else if (title === "subdistrict") {
            // loadingHandler(true);
            myformDataHandler(item);
            myformTitleHandler(title);
            selectedSubdistrictHandler('')
        } else {
            // loadingHandler(true);
            myformDataHandler(item);
            myformTitleHandler(title);
        }
        overlayFormHandler(prevState => !prevState.overlayForm)
    }

    const goBackToTable = () => {
        const newFormData = resetFields(formdata, 'customer');
        formdataHandler(newFormData)
        filterTextHandler('');
        currentPageHandler(1);
        createstatusHandler(false)
        showFormStatus(false)
        resetEditFieldHandler()
        selectednumber(numberThisData)
    }

    const resetEditFieldHandler = () => {
        formErrorHandler(false);
        formSuccessHandler(false);
        processHandler(false);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const submitNewAddress = (event) => {
        event.preventDefault();
        processHandler(true);
        let dataToSubmit = generateData(formdata, 'addNewAddressCustomer');
        let formIsValid = isFormValid(formdata, 'addNewAddressCustomer');

        let totalDataToSubmit = {
            ...dataToSubmit,
            customerid: datanumber,
            provincename: selectedProvince.name,
            cityname: selectedCity.name,
            subdistrictname: selectedSubdistrict.name,
            uid: userdata.id
        }

        if (formIsValid) {
            // console.log(totalDataToSubmit, "<============")
            dispatch(addCustomer(totalDataToSubmit)).then((customerFeedback) => {
                if (customerFeedback.payload.success) {
                    formSuccessHandler(true);
                    setTimeout(() => {
                        dispatch(clearCustomer());
                        formSuccessHandler(false);
                        resetEditFieldHandler();
                        refreshPage();
                    }, 1000)
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(customerFeedback.payload.message);
                }
            })
        } else {
            // console.log("error 3");
            formErrorHandler(true);
            errorMessageHandler('Form not valid');
            processHandler(false);
        }
    }

    const className = `${show ? 'cardBody' : 'displayNone'}`;

    return (
        <div className={className}>
            <SearchForm
                // ref={'SearchForm'}
                show={overlayForm}
                handleChange={handleChange}
                totalData={myformData}
                filterText={filterText}
                pageLimit={pageSize}
                pageChanged={pageChanged}
                currentPage={currentPage}
                title={myformTitle}
                selectedItems={selectedItems}
            />
            <PopupMessageNoAdd
                show={popupMessage}
                overlayFormStatus={backdropPopupMessage}
                errorMessage={errorMessage}
            />
            {
                overlayForm &&
                <BackDrop click={backdropClickHandler} />
            }
            {
                popupMessage &&
                <DoubleBackDrop click={backdropPopupMessage} />
            }

            <div className="row">
                <div className="col-md-4 col-xs-12">
                    <div className="formGroup mb20">
                        <label className="controlLabel">Full Name</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaUser className="icon agraicon" />
                            </div>
                            <FormField
                                id={'name'}
                                formdata={formdata.name}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Address Line 1</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaRoad className="icon agraicon" />
                            </div>
                            <FormField
                                id={'address'}
                                formdata={formdata.address}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Address Line 2</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaMapMarker className="icon agraicon" />
                            </div>
                            <FormField
                                id={'details'}
                                formdata={formdata.details}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-xs-12">
                    <div className="formGroup mb20">
                        <label className="controlLabel">Province</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaEnvelope className="icon agraicon" />
                            </div>
                            <div
                                id={'province'}
                                onClick={() => searchForm(formdata.province.config.options, "province")}
                                className={`${formdata.province.config.options && formdata.province.config.options.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            >
                                {
                                    selectedProvince ? selectedProvince.name : formdata.province.config.placeholder
                                }
                            </div>
                            {/* <FormField
                                                                        id={'province'}
                                                                        formdata={this.state.formdata.province}
                                                                        change={(element) => this.updateForm(element)}
                                                                        myclass={'form-control'}
                                                                    /> */}
                        </div>
                    </div>

                    <div className="formGroup mb20">
                        <label className="controlLabel">City</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaEnvelope className="icon agraicon" />
                            </div>
                            <div
                                id={'city'}
                                onClick={() => searchForm(city, "city")}
                                className={`${selectedProvince && city ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            >
                                {
                                    selectedProvince && selectedCity ? selectedCity.name : formdata.city.config.placeholder
                                }
                            </div>
                        </div>
                    </div>

                    <div className="formGroup mb20">
                        <label className="controlLabel">Subdistrict</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaEnvelope className="icon agraicon" />
                            </div>
                            <div
                                id={'subdistrict'}
                                onClick={() => searchForm(formdata.subdistrict.config.options, "subdistrict")}
                                className={`${selectedProvince && selectedCity && formdata.subdistrict.config.options.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            >
                                {
                                    selectedProvince && selectedCity && selectedSubdistrict ? selectedSubdistrict.name : formdata.subdistrict.config.placeholder
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-md-4 col-xs-12">
                    <div className="formGroup mb20">
                        <label className="controlLabel">Email</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaEnvelope className="icon agraicon" />
                            </div>
                            <FormField
                                id={'email'}
                                formdata={formdata.email}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Phone Number</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaMobileAlt className="icon agraicon" />
                            </div>
                            <FormField
                                id={'phone'}
                                formdata={formdata.phone}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Postcode / ZIP</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaEnvelope className="icon agraicon" />
                            </div>
                            <FormField
                                id={'postcode'}
                                formdata={formdata.postcode}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12 col-xs-12">
                    {
                        formSuccess ?
                            <div className="successLabel">
                                Add Customer Success, Now Loading...
                            </div>
                            : null
                    }
                    {
                        formError ?
                            <div className="errorLabel">
                                PLEASE CHECK YOUR DATA
                            </div>
                            : null

                    }
                </div>
                <div className={`${isMobile ? "col-md-12 col-xs-12" : "col-md-12 col-xs-12 posAbsolute confirmInvoiceButton"}`}>
                    <div
                        className="inlineFlex"
                        style={{
                            width: '100%',
                            paddingRight: 15,
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            className="agrapanaBtn redBackground p8"
                            title=""
                            onClick={() => goBackToTable()}
                        >
                            <FaChevronLeft className="icon agraicon w18px" />
                        </div>
                        <div className="doubleButton">
                            <button onClick={(event) => submitNewAddress(event)} className={`${process ? "agrapanaBtn agrapanaBtnPrimary divBtn ml10 disabled" : "agrapanaBtn agrapanaBtnPrimary divBtn ml10"}`}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CustomerForm;