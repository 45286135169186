import React from 'react';

// import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

// import BackDrop from '../../../layout/components/memberheaderfooter/backdrop';
import Form from './form';
import Pagination from '../../../utils/pagination';
import EditForm from './editForm';
// import PasswordForm from './passwordForm';
import { Exportxls } from '../../../utils/exportxls';
import {
    convertToRupiah,
} from '../../../utils/form/formactions';

import { FaArrowLeft, FaCheck, FaDownload, FaEdit, FaExclamation, FaPlus, FaRedoAlt, FaSearch, FaTasks, FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux';

const QuotationTable = ({
    userdata,
    allusers,
    allrequests,
    allquotations,
    allproducts,
    allterms,
    allquotationhistory,
    allcustomers,
    handleRefresh,
    tableName,
    isMobile,
    createstatus,
    createstatusHandler,
    editstatus,
    editstatusHandler,
    revisistatus,
    revisistatusHandler,
    showhistoryFunction,
    showhistory,
    showhistoryHandler,
    selectedNumber,
    selectedThisData,
    selectedThisDataHandler,
    selectedHistory,
    selectedHistoryHandler,
    allQuotationHistory,
    datanumber,
    myquotations,
    mycustomers
}) => {

    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings);

    const numberThisData = myquotations && myquotations.length;
    const [filterText, filterTextHandler] = React.useState('');
    // const [totalPage, totalPageHandler] = React.useState(0);
    const [currentPage, currentPageHandler] = React.useState(1);
    const [pageSize, pageSizeHandler] = React.useState(10);

    const [showForm, showFormHandler] = React.useState(false);
    const [showEditForm, showEditFormHandler] = React.useState(false);
    const [showHistoryForm, showHistoryFormHandler] = React.useState(false);
    // const [showRevisiForm, showRevisiFormHandler] = React.useState(false);

    const [dataCount, dataCountHandler] = React.useState("");
    const [mydata, mydataHandler] = React.useState([]);

    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    pageSizeHandler(selected.pagesize)
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber)
    }

    const gotoDetails = React.useCallback((data) => {
        filterTextHandler('')
        currentPageHandler(1);
        showEditFormHandler(prevState => !prevState.showEditForm)
        selectedThisDataHandler(data)
        editstatusHandler(true);
    }, [selectedThisDataHandler, editstatusHandler])

    const gotoHistory = React.useCallback((data) => {
        filterTextHandler('')
        currentPageHandler(1);
        showhistoryHandler(true);
        showHistoryFormHandler(prevState => !prevState.showHistoryForm)
        selectedThisDataHandler(data);
        selectedHistoryHandler(data)
        showhistoryFunction(data)
    }, [selectedHistoryHandler, showhistoryFunction, selectedThisDataHandler, showhistoryHandler])

    const gotoDetailsRef = React.useRef(gotoDetails);
    const gotoHistoryRef = React.useRef(gotoHistory);

    React.useEffect(() => {
        gotoDetailsRef.current = gotoDetails;
    }, [gotoDetails]);

    React.useEffect(() => {
        gotoHistoryRef.current = gotoHistory;
    }, [gotoHistory]);

    // function fn(text, count) {
    //     // return text.slice(0, count);
    //     return text.slice(0, count) + (text.length > count ? "..." : "");
    // }

    const TableRow = React.useCallback((thedata, index) => {

        var purple = 0;
        var red = 0;
        var lightblue = 0;
        var green = 0;
        // console.log(this.props.thedata, "<<<<<<<<<<this.props.thedata.products")
        thedata.products.forEach((item, index) => {
            // console.log(item, "<<<<<<<<<<item")
            if (item.valid && item.color === "#FF0000") {
                red = red + 1;
            } else if (item.valid && item.color === "#800080") {
                purple = purple + 1;
            } else if (item.valid && item.color === "#add8e6") {
                lightblue = lightblue + 1;
            } else {
                green = green + 1;
            }
        })
        return (
            <tr className="verticalCenter" key={index}>
                <td>
                    {index + 1}
                </td>
                <td>
                    {thedata.quotationid ? thedata.quotationid : ""}
                </td>
                <td>
                    {thedata.createdAt ? moment(thedata.createdAt).format('DD/MM/YY') : "Waiting"}
                </td>
                <td>
                    {thedata.date ? moment(thedata.date).format('DD/MM/YY') : "Waiting"}
                </td>
                <td>
                    {thedata.title ? thedata.title : ""}
                </td>
                <td>
                    {thedata.picname ? thedata.picname : ""}
                </td>
                <td>
                    {thedata.customer ? thedata.customer.name : ""}
                </td>
                <td>
                    <div className="inlineFlex">
                        {
                            thedata.approve || thedata.reject ? null
                                :
                                <div
                                    className="agrapanaBtn midnightblueBackground p8 mr5"
                                    title=""
                                    onClick={() => gotoDetailsRef.current(thedata)}
                                >
                                    <FaEdit className="icon agraicon w18px" />
                                </div>
                        }
                        <div
                            className="agrapanaBtn grayBackground p8 mr5"
                            title=""
                            onClick={() => gotoHistoryRef.current(thedata)}
                        >
                            <FaTasks className="icon agraicon w18px" />
                        </div>
                        {
                            thedata.approve ?
                                <div
                                    className="agrapanaBtn blueBackground p8 mr5"
                                    title=""
                                // onClick={() => this.props.approve(this.props)}
                                >
                                    <FaCheck className="icon agraicon w18px" />
                                </div>
                                : null
                        }
                        {
                            thedata.reject ?
                                <div
                                    className="agrapanaBtn redBackground p8 mr5"
                                    title=""
                                // onClick={() => this.props.reject(this.props)}
                                >
                                    <FaTimes className="icon agraicon w18px" />
                                </div>
                                : null
                        }
                        {
                            !thedata.approve && !thedata.reject ?
                                <div
                                    className={thedata.process ? "agrapanaBtn redBackground p8 mr5" : "agrapanaBtn salmonBackground p8 mr5"}
                                    title=""
                                // onClick={() => this.props.reject(this.props)}
                                >
                                    <FaExclamation className="icon agraicon w18px" />
                                </div>
                                : null
                        }
                        {
                            red > 0 ?
                                <div
                                    className="agrapanaBtn redBackground p8 mr5 br50"
                                    title=""
                                ></div>
                                : red < 1 && purple > 0 ?
                                    <div
                                        className="agrapanaBtn purpleBackground p8 mr5 br50"
                                        title=""
                                    // onClick={() => this.props.reject(this.props)}
                                    ></div>
                                    : red < 1 && purple < 1 && lightblue > 0 ?
                                        <div
                                            className="agrapanaBtn lightblueBackground p8 mr5 br50"
                                            title=""
                                        ></div>
                                        : red < 1 && purple < 1 && lightblue < 1 && green > 0 ?
                                            <div
                                                className="agrapanaBtn greenBackground p8 mr5 br50"
                                                title=""
                                            // onClick={() => this.props.reject(this.props)}
                                            ></div>
                                            : red < 1 && purple < 1 && lightblue < 1 && green < 1 ? null
                                                : null
                        }
                    </div>
                </td>
            </tr>
        );
    }, [])

    const TableHistory = React.useCallback((thedata, index) => {

        return (
            <tr className="verticalCenter" key={index}>
                <td>
                    {index + 1}
                </td>
                <td>
                    {thedata.quotationid}
                </td>
                <td>
                    {moment(thedata.createdAt).format('DD/MM/YY')}
                </td>
                <td>
                    {moment(thedata.date).format('DD/MM/YY')}
                </td>
                <td>
                    {thedata.title}
                </td>
                <td>
                    {thedata.picname}
                </td>
                <td>
                    {thedata.customer.name}
                </td>
                <td>
                    <div className="inlineFlex">
                        <div
                            className="agrapanaBtn midnightblueBackground p8 mr5"
                            title=""
                            onClick={() => gotoDetailsRef.current(thedata)}
                        >
                            <FaEdit className="icon agraicon w18px" />
                        </div>
                    </div>
                </td>
            </tr>
        );
    }, [])

    React.useEffect(() => {
        var results = [];
        var resultsdata = [];
        var totalData = []
        var offset = (currentPage - 1) * pageSize;

        // let typeSelectedHistory = typeof selectedHistory;
        // let getRealSelectedHistory = typeSelectedHistory === 'object' ? [selectedHistory] : selectedHistory;
        totalData = showHistoryForm ? allQuotationHistory : allquotations;
        if (totalData) {
            // var filterdata = totalData.filter((data, i) => data.name !== 'LUMISOFT');
            if(filterText) {
                results = totalData.filter(data =>
                    (
                        data.quotationid.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                        || data.date.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                        || data.title.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                        || data.picname.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                        || data.customer.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                    ))
            } else {
                results = totalData;
            }

            if (showHistoryForm) {
                resultsdata = results.map((item, index) => TableHistory(item, index))
                // alldata = alldata.map((data, index) => <TableHistory user={this.props.user} thedata={data} key={index} number={index} handleEdit={this.handleEdit} />)
            } else {
                resultsdata = results.map((item, index) => TableRow(item, index))
                // alldata = alldata.map((data, index) => <TableRow user={this.props.user} thedata={data} key={index} number={index} handleEdit={this.handleEdit} handlePassword={this.handlePassword} historyPage={this.historyPage} approve={this.approve} reject={this.reject} />)
            }
            var semuadata = [...resultsdata];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            dataCountHandler(results.length);
            mydataHandler(mydatas);
        }

    }, [filterText, currentPage, allquotations, pageSize, TableRow, TableHistory, showHistoryForm, selectedHistory, allQuotationHistory])

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1);
    }

    const handleUpdate = () => {
        filterTextHandler('')
        currentPageHandler(1);
        handleRefresh();
    }

    const handleAdd = () => {
        filterTextHandler('')
        currentPageHandler(1);
        createstatusHandler(true)
        selectedNumber(numberThisData)
        showFormHandler(prevState => !prevState.showForm)
    }

    // const handleEdit = (data) => {
    //     selectedThisDataHandler(data);
    //     filterTextHandler('')
    //     currentPageHandler(1);
    //     editstatusHandler(true);
    //     showEditFormHandler(prevState => !prevState.showeditForm);
    // }

    // const handleRevisi = (data) => {
    //     selectedThisDataHandler(data);
    //     filterTextHandler('')
    //     currentPageHandler(1);
    //     revisistatusHandler(true);
    //     showRevisiFormHandler(prevState => !prevState.showRevisiForm)
    // }

    const handleBackToMainTable = () => {
        showHistoryFormHandler(false);
        filterTextHandler('')
        currentPageHandler(1);
        showhistoryHandler(false);
    }

    const exportXls = () => {
        var re = allquotations;
        var histories = allquotationhistory;
        var data = [];
        var products = [];
        var head = [
            "No",
            "No Quotation",
            "Project Name",
            "CreatedAt",
            "Delivery",
            "Customer",
            "PIC Name",
            "PIC Phone",
            "Term of payment",
            "Subtotal",
            "Discount",
            "Totals",
            "Tax",
            "Grand total",
            "Revision",
            "Salesman",
            "Status"
        ]

        var headproducts = [
            "No",
            "Product ID",
            "Product Name",
            "Category",
            "Price",
            "Quantity",
            "Discount"
        ]

        for (var item = 0; item < re.length; item++) {
            var status = re[item].process === true ? 'on revision' : re[item].approve === true && re[item].reject === false ? 'approved' : re[item].approve === false && re[item].reject === true ? 'rejected' : 'on progress';
            data.push([
                item + 1,
                re[item].quotationid,
                re[item].title,
                moment(re[item].createdAt).format('DD-MM-YY'),
                moment(re[item].date).format('DD-MM-YY'),
                re[item].customer.name,
                re[item].picname,
                re[item].picphone,
                re[item].termofpayment.name,
                convertToRupiah(re[item].subtotal),
                convertToRupiah(re[item].discount),
                convertToRupiah(re[item].totals),
                convertToRupiah(re[item].tax),
                convertToRupiah(re[item].grandtotal),
                re[item].revision,
                re[item].uid.name,
                status,
            ]);
        }

        for (var item2 = 0; item2 < re.length; item2++) {
            if (re[item2].approve === true && re[item2].reject === false) {
                console.log("approved")
                for (var sejarah = 0; sejarah < histories.length; sejarah++) {
                    if (histories[sejarah].noquotation === re[item2]._id) {
                        products[item2] = [];
                        for (var z = 0; z < histories[sejarah].products.length; z++) {
                            products[item2].push([
                                z + 1,
                                histories[sejarah].products[z].productid.productId,
                                histories[sejarah].products[z].productid.name,
                                histories[sejarah].products[z].productid.categories,
                                histories[sejarah].products[z].productid.price,
                                histories[sejarah].products[z].qty,
                                `${histories[sejarah].products[z].discount} %`,
                            ])
                        }
                    }
                }
            } else if (re[item2].approve === false && re[item2].reject === true) {
                console.log("rejected")
                for (var sejarah2 = 0; sejarah2 < histories.length; sejarah2++) {
                    if (histories[sejarah2].noquotation === re[item2]._id) {
                        products[item2] = [];
                        for (var g = 0; g < histories[sejarah2].products.length; g++) {
                            products[item2].push([
                                g + 1,
                                histories[sejarah2].products[g].productid.productId,
                                histories[sejarah2].products[g].productid.name,
                                histories[sejarah2].products[g].productid.categories,
                                histories[sejarah2].products[g].productid.price,
                                histories[sejarah2].products[g].qty,
                                `${histories[sejarah2].products[g].discount} %`,
                            ])
                        }
                    }
                }
            } else {
                products[item2] = [];
                for (var q = 0; q < re[item2].products.length; q++) {
                    products[item2].push([
                        q + 1,
                        re[item2].products[q].productid.productId,
                        re[item2].products[q].productid.name,
                        re[item2].products[q].productid.categories,
                        re[item2].products[q].productid.price,
                        re[item2].products[q].qty,
                        `${re[item2].products[q].discount} %`,
                    ])
                }
            }
            // const allProductSelectedCopy = re[product].product;

            // for (let key in allProductSelectedCopy) {
            //     products[key] = []
            //     products[key].push(allProductSelectedCopy[key].name);
            // }

        }

        // Exportxls(head, data)
        Exportxls(head, data, products, headproducts)
    }

    const wizardForm = `${showForm || showEditForm || showHistoryForm ? 'displayNone' : 'cardBody'}`;
    const editHistoryForm = `${showEditForm ? 'displayNone' : 'cardBody'}`;

    return (
        <div>
            <Form
                allusers={allusers}
                allrequests={allrequests}
                allquotations={allquotations}
                allproducts={allproducts}
                allterms={allterms}
                allcustomers={allcustomers}
                show={showForm}
                showFormStatus={showFormHandler}
                handleRefresh={handleRefresh}
                isMobile={isMobile}
                numberThisData={numberThisData}
                selectednumber={selectedNumber}
                createstatus={createstatus}
                createstatusHandler={createstatusHandler}
                datanumber={datanumber}
                userdata={userdata}
                myquotations={myquotations}
                mycustomers={mycustomers}
            />
            
            <EditForm
                allusers={allusers}
                allrequests={allrequests}
                allquotations={allquotations}
                allproducts={allproducts}
                allterms={allterms}
                allcustomers={allcustomers}
                showEdit={showEditForm}
                showHistory={showHistoryForm}
                showEditFormStatus={showEditFormHandler}
                showHistoryFormStatus={showHistoryFormHandler}
                handleRefresh={handleRefresh}
                isMobile={isMobile}
                numberThisData={numberThisData}
                selectednumber={selectedNumber}
                editstatus={editstatus}
                editstatusHandler={editstatusHandler}
                revisistatus={revisistatus}
                revisistatusHandler={revisistatusHandler}
                selectedThisData={selectedThisData}
                selectedThisDataHandler={selectedThisDataHandler}
                datanumber={datanumber}
                userdata={userdata}
                mycustomers={mycustomers}
            />

            {
                showhistory ?
                    <div className={editHistoryForm}>
                        <div className="flexbox mb20 centerXs">
                            <div className="lookup">
                                <div className="iconPosition">
                                    <FaSearch className="icon agraicon w18px" />
                                </div>
                                <input
                                    className="w200px"
                                    type="text"
                                    name="Search"
                                    placeholder="Search"
                                    title="Type in a name"
                                    value={filterText}
                                    // ref="filterTextInput"
                                    onChange={(event) => handleChange(event)}
                                />
                                <div className="pageNumber">{currentPage}/{parseInt((dataCount / pageSize) + ((dataCount % pageSize) > 0 ? 1 : 0))} Pages</div>

                            </div>
                            <div className="btnToolbar">
                                <div className="btnGroup btnGroupSM">
                                    <button
                                        className="btn"
                                        title=""
                                        onClick={() => handleUpdate()}
                                    >
                                        <FaRedoAlt className="icon agraicon w18px" />
                                    </button>
                                    <button
                                        className="btn blueBackground"
                                        title=""
                                        onClick={() => handleBackToMainTable()}
                                    >
                                        <FaArrowLeft className="icon agraicon w18px" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="tableResponsiveSm">
                                <table className="table" id={tableName}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Code</th>
                                            <th>createdAt</th>
                                            <th>Delivery</th>
                                            <th>Title</th>
                                            <th>PIC</th>
                                            <th>Customer</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            allquotations.length === 0 ?
                                                <tr className="textCenter fw500 bg_table textWhite">
                                                    <td colSpan={"8"}>
                                                        Data Empty
                                                    </td>
                                                </tr>
                                                :
                                                mydata.length === 0
                                                    ?
                                                    <tr className="textCenter fw500 bg_table textWhite">
                                                        <td colSpan={"8"}>
                                                            Data not exist
                                                        </td>
                                                    </tr>
                                                    : mydata
                                        }
                                    </tbody>

                                </table>
                            </div>
                            {
                                allquotations.length === 0 ?
                                    null
                                    :
                                    <nav className="mt10">
                                        <Pagination Size={dataCount} pageLimit={pageSize} onPageChanged={pageChanged} currentPage={currentPage} width={isMobile} />
                                    </nav>
                            }

                        </div>

                    </div>
                    :
                    <div className={wizardForm}>
                        <div className="flexbox mb20 centerXs">
                            <div className="lookup">
                                <div className="iconPosition">
                                    <FaSearch className="icon agraicon w18px" />
                                </div>
                                <input
                                    className="w200px"
                                    type="text"
                                    name="Search"
                                    placeholder="Search"
                                    title="Type in a name"
                                    value={filterText}
                                    // ref="filterTextInput"
                                    onChange={(event) => handleChange(event)}
                                />
                                <div className="pageNumber">{currentPage}/{parseInt((dataCount / pageSize) + ((dataCount % pageSize) > 0 ? 1 : 0))} Pages</div>

                            </div>
                            <div className="btnToolbar">
                                <div className="btnGroup btnGroupSM">
                                    <button
                                        className="btn"
                                        title=""
                                        onClick={() => handleUpdate()}
                                    >
                                        <FaRedoAlt className="icon agraicon w18px" />
                                    </button>
                                    <button
                                        className="btn"
                                        title=""
                                        onClick={() => handleAdd()}
                                    >
                                        <FaPlus className="icon agraicon w18px" />
                                    </button>
                                </div>
                                {
                                    isMobile || allquotations.length < 1 ? null
                                        :
                                        userdata.masteradmin === 1 ?
                                            <div className="btnGroup btnGroupSM mlHalfRem dFlex">
                                                <button
                                                    className="btn"
                                                    title=""
                                                    onClick={() => exportXls()}
                                                >
                                                    <FaDownload className="icon agraicon w18px" />
                                                </button>
                                            </div>
                                            : null

                                }

                            </div>
                        </div>
                        <div>
                            <div className="tableResponsiveSm">
                                <table className="table" id={tableName}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Code</th>
                                            <th>createdAt</th>
                                            <th>Delivery</th>
                                            <th>Title</th>
                                            <th>PIC</th>
                                            <th>Customer</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            allquotations.length === 0 ?
                                                <tr className="textCenter fw500 bg_table textWhite">
                                                    <td colSpan={"8"}>
                                                        Data Empty
                                                    </td>
                                                </tr>
                                                :
                                                mydata.length === 0
                                                    ?
                                                    <tr className="textCenter fw500 bg_table textWhite">
                                                        <td colSpan={"8"}>
                                                            Data not exist
                                                        </td>
                                                    </tr>
                                                    : mydata
                                        }
                                    </tbody>

                                </table>
                            </div>
                            {
                                allquotations.length === 0 ?
                                    null
                                    :
                                    <nav style={{ marginTop: '100px' }}>
                                        <Pagination Size={dataCount} pageLimit={pageSize} onPageChanged={pageChanged} currentPage={currentPage} width={isMobile} />
                                    </nav>
                            }

                        </div>

                    </div>

            }
        </div>
    );
}

export default QuotationTable;