import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

// import CircularProgress from '@material-ui/core/CircularProgress';
import BackDrop from '../../../admin/components/headerfooter/backdrop';
// import BackDropWithAdd from '../../../admin/components/headerfooter/backdrop';
import DoubleBackDrop from '../../../admin/components/headerfooter/doublebackdrop';
// import TripleBackDrop from '../../../admin/components/headerfooter/doublebackdrop';

import SearchForm from '../../../utils/searchform/cannotadd';
// import SearchFormWithAdd from '../../../utils/searchform';
// import PopupMessage from '../../../utils/popupmessage';
import PopupMessageNoAdd from '../../../utils/popupmessage/noadd';

import FormField from '../../../utils/form/formfield';
import {
    update,
    validate,
    generateData,
    isFormValid,
    resetFields
    // populateOptionFields,
    // editFormAdd
} from '../../../utils/form/formactions';

// import { 
//     getProvince, 
//     getCity, 
//     getSubdistrict, 
//     postCost 
// } from '../../../reduceractions/actions/admin/ongkir_actions';
import {
    updateUserData,
    clearUpdateUser
} from '../../../reduceractions/actions/admin/user_actions';

// import {
//     addRole,
//     clearRole
// } from '../../../reduceractions/actions/general/role_actions';

import { FaCalendarCheck, FaChessKing, FaChevronLeft, FaCode, FaEnvelope, FaIdCard, FaKey, FaLock, FaMobileAlt, FaPercentage, FaUser } from 'react-icons/fa';

const EditUserForm = ({
    alldata,
    allroles,
    showEdit,
    showFormHandler,
    showEditFormHandler,
    isMobile,
    numberThisData,
    selectednumber,
    editstatusHandler,
    selectedThisData,
    // selectedThisDataHandler,
    // loadingHandler,
    code,
    datanumber,
    userdata
}) => {
    const dispatch = useDispatch();

    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings);

    const [editOverlayForm, editOverlayFormHandler] = React.useState(false);
    const [filterText, filterTextHandler] = React.useState('');
    const [currentPage, currentPageHandler] = React.useState(1);
    const [pageSize, pageSizeHandler] = React.useState(10);

    const [process, processHandler] = React.useState(false);
    const [myformData, myformDataHandler] = React.useState('');
    const [myformTitle, myformTitleHandler] = React.useState('');
    const [date, dateHandler] = React.useState();

    const [selectedRole, selectedRoleHandler] = React.useState();
    const [selectedUpline, selectedUplineHandler] = React.useState();

    const [popupMessage, popupMessageHandler] = React.useState(false);
    const [formError, formErrorHandler] = React.useState(false);
    const [formSuccess, formSuccessHandler] = React.useState(false);
    const [errorMessage, errorMessageHandler] = React.useState('');

    const [formdata, formdataHandler] = React.useState({
        name: {
            element: 'input',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter full name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        email: {
            element: 'input',
            value: '',
            config: {
                name: 'emailInput',
                type: 'email',
                placeholder: 'Enter your email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        ktp: {
            element: 'input',
            value: '',
            config: {
                name: 'ktpInput',
                type: 'text',
                placeholder: 'Enter ktp name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        dob: {
            element: 'input',
            value: '',
            config: {
                name: 'dobInput',
                type: 'text',
                placeholder: 'Enter date of birth'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        code: {
            element: 'input',
            value: '',
            config: {
                name: 'codeInput',
                type: 'text',
                placeholder: 'Enter code'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        manager: {
            element: 'input',
            value: '',
            config: {
                name: 'managerInput',
                type: 'number',
                placeholder: 'Enter manager access'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        phone: {
            element: 'input',
            value: '',
            config: {
                name: 'phoneInput',
                type: 'text',
                placeholder: 'Enter phone number'
            },
            validation: {
                required: true,
                phone: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        max: {
            element: 'input',
            value: '',
            config: {
                name: 'formicaInput',
                type: 'text',
                placeholder: 'Max discount formica'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        max2: {
            element: 'input',
            value: '',
            config: {
                name: 'performInput',
                type: 'text',
                placeholder: 'Max discount perform'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        max3: {
            element: 'input',
            value: '',
            config: {
                name: 'specialInput',
                type: 'text',
                placeholder: 'Max discount special'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        role: {
            element: 'input',
            value: '',
            config: {
                name: 'roleInput',
                options: [],
                placeholder: 'Choose Role'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        rolestock: {
            element: 'input',
            value: '',
            config: {
                name: 'roleStockInput',
                type: 'number',
                placeholder: 'insert 1 until 2'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        roleadmin: {
            element: 'input',
            value: '',
            config: {
                name: 'roleAdminInput',
                type: 'number',
                placeholder: 'insert 1 until 2'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        rolesales: {
            element: 'input',
            value: '',
            config: {
                name: 'roleSalesInput',
                type: 'number',
                placeholder: 'insert 1 until 2'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        roleterm: {
            element: 'input',
            value: '',
            config: {
                name: 'roleTermInput',
                type: 'number',
                placeholder: 'insert 1 until 2'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        upline: {
            element: 'input',
            value: '',
            config: {
                name: 'uplineInput',
                options: [],
                placeholder: 'Choose Upline'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    })

    const executedAnotherOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    pageSizeHandler(selected.pagesize)
                    executedAnotherOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    const executedOnceRef = React.useRef(false);

    const functionConfigOptions = React.useCallback(async (alldata, selectedThisData) => {

        const newExtradata = [];
        const newExtradata2 = [];
        alldata.forEach(item => {
            newExtradata.push({ key: item._id, name: `${item.name} (${item.code}_${item.role && item.role.name})` });
        });
        allroles.forEach(item => {
            newExtradata2.push({ key: item._id, name: item.name });
        });

        let uplineName = selectedThisData && selectedThisData['upline'] ? selectedThisData && selectedThisData['upline'] : { _id: '', name: 'upline Name' }
        // console.log(uplineName, '<<<upline')
        formdataHandler(prevState => {
            let newFormdata = { ...prevState };

            for (let key in newFormdata) {
                const newElement = {
                    ...newFormdata[key]
                }

                if (key === 'upline') {
                    newElement.config.options = newExtradata;
                    newElement.value = uplineName._id;
                } else if (key === 'role') {
                    newElement.config.options = newExtradata2;
                    newElement.value = selectedThisData && selectedThisData['role']._id;
                } else {
                    newElement.value = selectedThisData && selectedThisData[key];
                }
                newElement.valid = true;
                newElement.touched = true;
                newElement.validationMessage = '';
                newFormdata[key] = newElement;
            }

            return newFormdata
        })
        dateHandler(selectedThisData && selectedThisData['dob'])
        selectedRoleHandler(selectedThisData && selectedThisData['role'])
        selectedUplineHandler(uplineName);
    }, [allroles])

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (showEdit && alldata && alldata.length > 0) {
                    await functionConfigOptions(alldata, selectedThisData)
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [alldata, functionConfigOptions, selectedThisData, showEdit])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {

            if (selectedThisData !== undefined && selectedThisData !== {}) {
                let selectedUplineData = selectedThisData && selectedThisData.upline;
                let selectedRolesData = selectedThisData && selectedThisData.role;
                if (selectedUplineData !== undefined && selectedRolesData !== undefined) {
                    selectedUplineHandler({
                        key: selectedUplineData._id,
                        name: selectedUplineData.name
                    })
                    selectedRoleHandler({
                        key: selectedRolesData._id,
                        name: selectedRolesData.name
                    })
                    dateHandler(selectedThisData && selectedThisData.dob)
                }
            }
        }
        return () => {
            mounted = false;
        };
    }, [selectedThisData])
    // console.log(selectedThisData, '<<<selectedthisdata')
    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber);
    }

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'users');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }

    const onDateChange = (date) => {
        // console.log(date, "<========== date")
        const newFormdata = {
            ...formdata
        }

        const newElement = {
            ...newFormdata['dob']
        }

        newElement.value = date._d;

        let validData = [true, ""];
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];

        newFormdata['dob'] = newElement;
        formdataHandler(newFormdata);
        dateHandler(date._d)
    }

    const selectedItems = (selected) => {
        // console.log(selected)
        var formTitle = myformTitle;
        if (formTitle === "role" || formTitle === "upline") {
            const newFormdata = {
                ...formdata
            }

            const newElement = {
                ...newFormdata[formTitle]
            }

            newElement.value = selected.key;

            let validData = validate(newElement, formdata);
            newElement.valid = validData[0];
            newElement.validationMessage = validData[1];

            newFormdata[formTitle] = newElement;

            if (formTitle === "role") {
                formErrorHandler(false);
                formdataHandler(newFormdata);
                selectedRoleHandler(selected);
                backdropClickHandler()
            }
            if (formTitle === "upline") {
                formErrorHandler(false);
                formdataHandler(newFormdata);
                selectedUplineHandler(selected);
                backdropClickHandler()
            }
        }
    }

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        myformTitleHandler('');
        filterTextHandler('');
        currentPageHandler(1);
        editOverlayFormHandler(false)
    }

    const backdropPopupMessage = () => {
        document.body.style.overflow = 'overlay';
        goBackToLists();
    }

    const goBackToLists = () => {
        formErrorHandler(false);
        popupMessageHandler(false);
        filterTextHandler('');
        errorMessageHandler('');
    }

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1);
    }

    const searchForm = (item, title, itemid, commonId) => {
        myformDataHandler(item);
        myformTitleHandler(title);
        editOverlayFormHandler(prevState => !prevState.editOverlayForm)
    }

    const goBackToTable = () => {
        const newFormData = resetFields(formdata, 'users');
        formdataHandler(newFormData)
        filterTextHandler('');
        currentPageHandler(1);
        resetEditFieldHandler()
        selectednumber(numberThisData)
        showEditFormHandler(false);
        showFormHandler(false);
    }

    const resetFieldHandler = () => {
        const newFormData = resetFields(formdata, 'customer');
        formdataHandler(newFormData)
        setTimeout(() => {
            resetEditFieldHandler()
        }, 500)
    }

    const resetEditFieldHandler = () => {
        formErrorHandler(false);
        formSuccessHandler(false);
        processHandler(false);
        editstatusHandler(false);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const submitNewAddress = (event) => {
        event.preventDefault();

        let dataToSubmit = generateData(formdata, 'addNewAddressCustomer');
        let formIsValid = isFormValid(formdata, 'addNewAddressCustomer');

        let totalDataToSubmit = {
            ...dataToSubmit,
            _id: selectedThisData._id
        }
        if (formIsValid) {
            dispatch(updateUserData(totalDataToSubmit)).then((thisfeedback) => {
                if (thisfeedback.payload.success) {
                    formSuccessHandler(true);
                    setTimeout(() => {
                        selectednumber(0)
                        dispatch(clearUpdateUser())
                        formSuccessHandler(false);
                        resetFieldHandler();
                        refreshPage();
                    }, 1000)
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(thisfeedback.payload.message);
                }
            })
        } else {
            // console.log("error 3");
            formErrorHandler(true);
            errorMessageHandler('Form not valid');
            processHandler(false);
        }
    }

    const className = `${showEdit ? 'cardBody' : 'displayNone'}`;

    return (
        <div className={className}>
            <SearchForm
                // ref={'SearchForm'}
                show={editOverlayForm}
                handleChange={handleChange}
                overlayFormStatus={backdropClickHandler}
                totalData={myformData}
                filterText={filterText}
                pageLimit={pageSize}
                pageChanged={pageChanged}
                currentPage={currentPage}
                title={myformTitle}
                selectedItems={selectedItems}
            // loadingHandler={loadingHandler}
            />
            <PopupMessageNoAdd
                show={popupMessage}
                overlayFormStatus={backdropPopupMessage}
                errorMessage={errorMessage}
            />
            {
                editOverlayForm &&
                <BackDrop click={backdropClickHandler} />
            }

            {
                popupMessage &&
                <DoubleBackDrop click={backdropPopupMessage} />
            }

            <div className="row">
                <div className="col-md-4 col-xs-12">
                    <div className="formGroup mb20">
                        <label className="controlLabel">Full Name</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaUser className="icon agraicon" />
                            </div>
                            <FormField
                                id={'name'}
                                formdata={formdata.name}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Email</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaEnvelope className="icon agraicon" />
                            </div>
                            <FormField
                                id={'email'}
                                formdata={formdata.email}
                                change={(element) => updateForm(element)}
                                myclass={'form-control disabled'}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-xs-12">

                    <div className="formGroup mb20">
                        <label className="controlLabel">Phone Number</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaMobileAlt className="icon agraicon" />
                            </div>
                            <FormField
                                id={'phone'}
                                formdata={formdata.phone}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Date of birth</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaCalendarCheck className="icon agraicon" />
                            </div>
                            <div className="formBlock">
                                <DatePicker
                                    dateFormat="DD-MM-YYYY"
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    value={date !== undefined ? moment(date).format('DD-MM-YYYY') : "Enter date of birth"}
                                    onChange={(date) => onDateChange(date)}
                                // myclass={'form-control'}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Role</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaKey className="icon agraicon" />
                            </div>
                            <div
                                id={'role'}
                                onClick={() => searchForm(formdata.role.config.options, "role")}
                                className={`${formdata.role ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            >
                                {
                                    selectedRole ? selectedRole.name : formdata.role.config.placeholder
                                }
                            </div>
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Upline</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaChessKing className="icon agraicon" />
                            </div>
                            <div
                                id={'upline'}
                                onClick={() => searchForm(formdata.upline.config.options, "upline")}
                                className={`${formdata.upline ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            >
                                {
                                    selectedUpline ? selectedUpline.name : formdata.upline.config.placeholder
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-md-4 col-xs-12">
                    <div className="formGroup mb20">
                        <label className="controlLabel">Code</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaCode className="icon agraicon" />
                            </div>
                            <FormField
                                id={'code'}
                                formdata={formdata.code}
                                change={(element) => updateForm(element)}
                                myclass={'form-control disabled'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Formica</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaPercentage className="icon agraicon" />
                            </div>
                            <FormField
                                id={'max'}
                                formdata={formdata.max}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Perform</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaPercentage className="icon agraicon" />
                            </div>
                            <FormField
                                id={'max2'}
                                formdata={formdata.max2}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Special</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaPercentage className="icon agraicon" />
                            </div>
                            <FormField
                                id={'max3'}
                                formdata={formdata.max3}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>

                </div>
                <hr />
                <div className='col-md-12 col-xs-12 p0'>
                    <div className='col-md-4 col-xs-12'>
                        <div className="formGroup mb20">
                            <label className="controlLabel">Manager access</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaLock className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'manager'}
                                    formdata={formdata.manager}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                        <div className="formGroup mb20">
                            <label className="controlLabel">KTP</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaIdCard className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'ktp'}
                                    formdata={formdata.ktp}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-12'>
                        <div className="formGroup mb20">
                            <label className="controlLabel">Accessing Stock</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaLock className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'rolestock'}
                                    formdata={formdata.rolestock}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                        <div className="formGroup mb20">
                            <label className="controlLabel">Update own Customer</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaLock className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'roleadmin'}
                                    formdata={formdata.roleadmin}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-12'>
                        <div className="formGroup mb20">
                            <label className="controlLabel">Accessing Quotation</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaLock className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'rolesales'}
                                    formdata={formdata.rolesales}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                        <div className="formGroup mb20">
                            <label className="controlLabel">Accessing Terms</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaLock className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'roleterm'}
                                    formdata={formdata.roleterm}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 col-xs-12">
                    {
                        formSuccess ?
                            <div className="successLabel">
                                UPDATE SUCCESS, RELOADING...
                            </div>
                            : null
                    }
                    {
                        formError ?
                            <div className="errorLabel">
                                {errorMessage}
                            </div>
                            : null
                    }
                </div>
                <div className={`${isMobile ? "col-md-12 col-xs-12" : "col-md-12 col-xs-12 confirmInvoiceButton mt30"}`}>
                    <div
                        className="inlineFlex"
                        style={{
                            width: '100%',
                            paddingRight: 15,
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            className="agrapanaBtn redBackground p8"
                            title=""
                            onClick={() => goBackToTable()}
                        >
                            <FaChevronLeft className="icon agraicon w18px" />
                        </div>
                        {
                            userdata && userdata.masteradmin === 1 ?
                                <div className="doubleButton">
                                    <button onClick={(event) => submitNewAddress(event)} className={`${process ? "agrapanaBtn agrapanaBtnPrimary divBtn ml10 disabled" : "agrapanaBtn agrapanaBtnPrimary divBtn ml10"}`}>
                                        Update
                                    </button>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}


export default EditUserForm;