import axios from 'axios';
import {
    GET_CUSTOMERS,
    ADD_CUSTOMER,
    CLEAR_CUSTOMER,
    UPDATE_CUSTOMER,
    CLEAR_UPDATE_CUSTOMER,
    DELETE_CUSTOMER
} from './types';

import { CUSTOMER_SERVER } from '../../misc';

export function getCustomers(){
    const request = axios.get(`${CUSTOMER_SERVER}/customers`)
    .then(response => response.data);

    return {
        type: GET_CUSTOMERS,
        payload: request
    }
}

export function addCustomer(dataToSubmit){
    const request = axios.post(`${CUSTOMER_SERVER}/customer`,dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_CUSTOMER,
        payload: request
    }
}

export function clearCustomer(){
    return {
        type: CLEAR_CUSTOMER,
        payload: ''
    }
}

export function updateCustomer(dataToSubmit){
    const request = axios.post(`${CUSTOMER_SERVER}/update`,dataToSubmit)
        .then(response => response.data);
        // .then(response => {
        //     return response.data
        // });

    return {
        type: UPDATE_CUSTOMER,
        payload: request
    }
}

export function clearUpdateCustomer(){
    return {
        type: CLEAR_UPDATE_CUSTOMER,
        payload: ''
    }
}

export function deleteCustomer(customerid){
    const request = axios.delete(`${CUSTOMER_SERVER}/deletecustomer/${customerid}`)
        .then(response => response.data);
    
    return {
        type: DELETE_CUSTOMER,
        payload: request
    }
}