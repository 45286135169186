import React from 'react';
import {
    // FaChevronLeft, 
    FaUser
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    clearUpdateSetting,
    // getSetting, 
    updateSetting
} from '../../../reduceractions/actions/general/setting_action';
import {
    generateData,
    isFormValid,
    // resetFields, 
    update
} from '../../../utils/form/formactions';
import Formfield from '../../../utils/form/formfield';

import MainLayout from '../../hoc/mainlayout';

const SettingMainPage = () => {

    const userdata = useSelector(state => state.user.userData);
    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [width, widthHandler] = React.useState(window.innerWidth);
    const isMobile = width <= 767.98;

    const [selectedThisData, selectedThisDataHandler] = React.useState({});

    const [formError, formErrorHandler] = React.useState(false);
    const [formSuccess, formSuccessHandler] = React.useState(false);
    const [errorMessage, errorMessageHandler] = React.useState('');

    const [formdata, formdataHandler] = React.useState({
        version: {
            element: 'input',
            value: '',
            config: {
                name: 'versionInput',
                type: 'text',
                placeholder: 'Enter version'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        laterdate: {
            element: 'input',
            value: '',
            config: {
                name: 'laterdateInput',
                type: 'number',
                placeholder: 'Enter days'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        currenttax: {
            element: 'input',
            value: '',
            config: {
                name: 'currenttaxInput',
                type: 'number',
                placeholder: 'Enter tax'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        pagesize: {
            element: 'input',
            value: '',
            config: {
                name: 'pagesizeInput',
                type: 'text',
                placeholder: 'Enter page size'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
    })

    React.useEffect(() => {

        function handleWindowSizeChange() {
            widthHandler(window.innerWidth);
        };

        // Bind the event listener
        document.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    }, [])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (userdata && userdata.masteradmin !== 1) {
                navigate('/admin/notauthorize')
            }

        }
        return () => {
            mounted = false;
        };
    }, [userdata, navigate])

    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    formdataHandler(prevState => {
                        let newFormdata = { ...prevState };

                        for (let key in newFormdata) {
                            const newElement = {
                                ...newFormdata[key]
                            }

                            newElement.value = selected[key];
                            newElement.valid = true;
                            newElement.touched = true;
                            newElement.validationMessage = '';
                            newFormdata[key] = newElement;
                        }

                        return newFormdata
                    })
                    selectedThisDataHandler(selected);
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'roles');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }

    const resetEditFieldHandler = () => {
        formErrorHandler(false);
        formSuccessHandler(false);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const updateNewSettings = (event) => {
        event.preventDefault();

        let dataToSubmit = generateData(formdata, 'setting');
        let formIsValid = isFormValid(formdata, 'setting');

        let totalDataToSubmit = {
            ...dataToSubmit,
            _id: selectedThisData._id
        }

        if (formIsValid) {
            dispatch(updateSetting(totalDataToSubmit)).then((thisfeedback) => {
                if (thisfeedback.payload.success) {
                    formSuccessHandler(true);
                    setTimeout(() => {
                        dispatch(clearUpdateSetting());
                        formSuccessHandler(false);
                        resetEditFieldHandler();
                        refreshPage();
                    }, 1000)
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(thisfeedback.payload.message);
                }
            })
        } else {
            // console.log("error 3");
            formErrorHandler(true);
            errorMessageHandler('Form not valid');
        }
    }

    return (
        <MainLayout>
            <div className="masterContainer">
                <div className="masterContent">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${isMobile ? "card" : "card minHeightCustomer"}`}>
                                <h4 className="cardTitle">
                                    <strong>Settings</strong>
                                </h4>
                                <div>
                                    <div className='cardBody'>
                                        <div className="row">
                                            <div className="col-md-12 col-xs-12">
                                                <div className="formGroup mb20">
                                                    <label className="controlLabel">Version</label>
                                                    <div className="formWrapper">
                                                        <div className="iconPosition">
                                                            <FaUser className="icon agraicon" />
                                                        </div>
                                                        <Formfield
                                                            id={'version'}
                                                            formdata={formdata.version}
                                                            change={(element) => updateForm(element)}
                                                            myclass={'form-control'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="formGroup mb20">
                                                    <label className="controlLabel">Days</label>
                                                    <div className="formWrapper">
                                                        <div className="iconPosition">
                                                            <FaUser className="icon agraicon" />
                                                        </div>
                                                        <Formfield
                                                            id={'laterdate'}
                                                            formdata={formdata.laterdate}
                                                            change={(element) => updateForm(element)}
                                                            myclass={'form-control'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="formGroup mb20">
                                                    <label className="controlLabel">Tax</label>
                                                    <div className="formWrapper">
                                                        <div className="iconPosition">
                                                            <FaUser className="icon agraicon" />
                                                        </div>
                                                        <Formfield
                                                            id={'currenttax'}
                                                            formdata={formdata.currenttax}
                                                            change={(element) => updateForm(element)}
                                                            myclass={'form-control'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="formGroup mb20">
                                                    <label className="controlLabel">Page size</label>
                                                    <div className="formWrapper">
                                                        <div className="iconPosition">
                                                            <FaUser className="icon agraicon" />
                                                        </div>
                                                        <Formfield
                                                            id={'pagesize'}
                                                            formdata={formdata.pagesize}
                                                            change={(element) => updateForm(element)}
                                                            myclass={'form-control'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-xs-12">
                                                {
                                                    formSuccess ?
                                                        <div className="successLabel">
                                                            SUCCESS, PLEASE WAIT...
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    formError ?
                                                        <div className="errorLabel">
                                                            {errorMessage}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className={`${isMobile ? "col-md-12 col-xs-12" : "col-md-12 col-xs-12 posAbsolute confirmInvoiceButton"}`}>
                                                <div
                                                    className="inlineFlex"
                                                    style={{
                                                        width: '100%',
                                                        paddingRight: 15,
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <div className="doubleButton">
                                                        <button
                                                            onClick={(event) => updateNewSettings(event)}
                                                            className="agrapanaBtn agrapanaBtnPrimary divBtn ml10" >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )

}

export default SettingMainPage;