import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import MainLayout from '../../hoc/mainlayout';
import QuotationTable from './table'

import {
    getUsers
} from '../../../reduceractions/actions/admin/user_actions';

import {
    getQuotations,
    getRequests,
    getQuotationHistory
} from '../../../reduceractions/actions/general/quotation_actions';

import {
    getProducts
} from '../../../reduceractions/actions/stock/product_actions';

import {
    getTerms
} from '../../../reduceractions/actions/admin/termofpayment_actions';

import {
    getCustomers
} from '../../../reduceractions/actions/admin/customer_actions';

const MainQuotations = () => {

    const customers = useSelector(state => state.customers.customers && state.customers.customers.customers);
    const products = useSelector(state => state.product.products && state.product.products.products);
    const terms = useSelector(state => state.termofpayment.terms && state.termofpayment.terms.termofpayments);
    const quotationhistories = useSelector(state => state.quotation.quotationhistory && state.quotation.quotationhistory.quotationhistory);
    const quotations = useSelector(state => state.quotation.quotations && state.quotation.quotations.quotations);
    const requests = useSelector(state => state.quotation.requests && state.quotation.requests.requests);
    const userdata = useSelector(state => state.user.userData);
    const allusers = useSelector(state => state.user.users && state.user.users.users)

    const dispatch = useDispatch();

    const [width, widthHandler] = React.useState(window.innerWidth);
    const isMobile = width <= 767.98;

    const [mycustomers, mycustomersHandler] = React.useState([]);
    const [myquotations, myquotationsHandler] = React.useState([]);

    // const [alldata, alldataHandler] = React.useState([]);
    // const [allprovince, allprovinceHandler] = React.useState([]);
    // const [allcity, allcityHandler] = React.useState([]);

    const [selectedHistory, selectedHistoryHandler] = React.useState([]);

    const [number, numberHandler] = React.useState('');

    const [createstatus, createstatusHandler] = React.useState(false);
    const [editstatus, editstatusHandler] = React.useState(false);
    const [revisistatus, revisistatusHandler] = React.useState(false);
    // const [historystatus, historystatusHandler] = React.useState(false);
    // const [changepassword, changepasswordHandler] = React.useState(false);
    const [showhistory, showhistoryHandler] = React.useState(false);

    const [selectedThisData, selectedThisDataHandler] = React.useState({});
    const [allQuotationHistory, allQuotationHistoryHandler] = React.useState([]);
    // const [selectedThisHistory, selectedThisHistoryHandler] = React.useState({});

    React.useEffect(() => {

        function handleWindowSizeChange() {
            widthHandler(window.innerWidth);
        };

        // Bind the event listener
        document.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (userdata && customers && customers.length > 0) {
                let mycustomers = [];
                customers.forEach((item, index) => {
                    if (item.uid._id.toString() === userdata.id.toString()) {
                        mycustomers.push(item)
                    }
                })
                mycustomersHandler(mycustomers)
                numberHandler(userdata.number)
            }
            if (userdata && quotations && quotations.length > 0) {
                let myquotations = [];
                quotations.forEach((item, index) => {
                    if (item.uid._id.toString() === userdata.id.toString()) {
                        myquotations.push(item)
                    }
                })
                myquotationsHandler(myquotations)
            }
        }
        return () => {
            mounted = false;
        };
    }, [userdata, customers, quotations])

    const selectedNumber = (selected) => {
        numberHandler(selected)
    }

    const onRefresh = async () => {

        const fetchData = async (URLs) => {
            const promise = await Promise.all(URLs.map(fetchBulkData));
            const allEqual = promise.every((val) => val.success === true);
            return allEqual;
        }
        let URLs = [
            dispatch(getCustomers()),
            dispatch(getProducts()),
            dispatch(getTerms()),
            dispatch(getQuotationHistory()),
            dispatch(getQuotations()),
            dispatch(getRequests()),
            dispatch(getUsers())
        ]

        await fetchData(URLs)
    }

    async function fetchBulkData(URL) {
        return URL.then(function (response) {
            // console.log(response, '<<<response')
            if (response.payload.success) {
                return response.payload;
            } else {
                let failedResponse = { success: false };
                return failedResponse
            }
        }).catch(function (error) {
            return { success: false, error: error };
        });
    }

    const showhistoryFunction = (selected) => {
        if (quotationhistories && quotationhistories.length > 0) {
            var newHistory = new Promise((resolve, reject) => {
                let selectedhistory = [];
                quotationhistories.forEach((item, index) => {
                    // console.log(item, "<============== itemitemtimteimteitmeitmeitmiete")
                    if (item.noquotation === selected._id) {
                        // console.log(item, "<!!!!!!!!!!!!!!!!!!!!!!!! itemitemtimteimteitmeitmeitmiete")
                        selectedhistory.push(item);
                    }
                })

                if (selectedhistory) {
                    resolve(selectedhistory)
                } else {
                    reject("error")
                }
            })

            newHistory.then(historyresult => {
                allQuotationHistoryHandler(historyresult)
                showhistoryHandler(prevState => !prevState.showhistory)
            }).catch(error => {
                console.log(error)
            })
        }
    }
    let yearNow = new Date().getFullYear().toString();
    var datanumber = `${userdata.code}/QT/${yearNow}/${number + 1}`;

    return (
        <MainLayout>
            <div className="masterContainer">
                <div className="masterContent">
                    <div className="row">
                        <div className="col-md-12">
                        <div className={`${isMobile ? "card" : "card minHeight680px"}`}>
                                                <h4 className="cardTitle"><strong>Quotation</strong>
                                                    {
                                                        createstatus &&
                                                            number !== "" ? <span> No: {datanumber}</span>
                                                            : editstatus && !revisistatus ? <span> Edit: {selectedThisData.quotationid}</span>
                                                                : editstatus && revisistatus ? <span> Revision page </span>
                                                                    : showhistory ? <span> History: {selectedHistory.quotationid}</span>
                                                                        : <span> List</span>
                                                    }
                                                    <br />{userdata.max ? `(disc: ${userdata.max - 1}%, ${userdata.max2 - 1}%, ${userdata.max3 - 1}%)` : null}
                                                </h4>
                                                <QuotationTable
                                                    userdata={userdata}
                                                    allusers={allusers}
                                                    allrequests={requests}
                                                    allquotations={quotations}
                                                    allproducts={products}
                                                    allterms={terms}
                                                    allquotationhistory={quotationhistories}
                                                    allcustomers={customers}
                                                    handleRefresh={onRefresh}
                                                    tableName="quotationTable"
                                                    isMobile={isMobile}
                                                    createstatus={createstatus}
                                                    createstatusHandler={createstatusHandler}
                                                    editstatus={editstatus}
                                                    editstatusHandler={editstatusHandler}
                                                    revisistatus={revisistatus}
                                                    revisistatusHandler={revisistatusHandler}
                                                    showhistoryFunction={showhistoryFunction}
                                                    showhistory={showhistory}
                                                    showhistoryHandler={showhistoryHandler}
                                                    selectedNumber={selectedNumber}
                                                    selectedThisData={selectedThisData}
                                                    selectedThisDataHandler={selectedThisDataHandler}
                                                    selectedHistory={selectedHistory}
                                                    selectedHistoryHandler={selectedHistoryHandler}
                                                    allQuotationHistory={allQuotationHistory}
                                                    datanumber={datanumber}
                                                    myquotations={myquotations}
                                                    mycustomers={mycustomers}
                                                />
                                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default MainQuotations;