import React from 'react';
import {
    useDispatch
} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FormField from '../../../utils/form/formfield';
import MyButton from '../../../utils/button';
import { update, generateData, isFormValid } from '../../../utils/form/formactions';

import { loginUser } from '../../../reduceractions/actions/admin/user_actions';

import { FaEnvelope, FaLock } from 'react-icons/fa'

const LoginPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formError, formErrorHandler] = React.useState(false);
    // const [formSuccess, formSuccessHandler] = React.useState(false);
    const [errorMessage, errorMessageHandler] = React.useState('');
    const [formdata, formdataHandler] = React.useState({
        email: {
            element: 'input',
            value: '',
            config: {
                name: 'emailInput',
                type: 'email',
                placeholder: 'Enter your email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        password: {
            element: 'input',
            value: '',
            config: {
                name: 'passwordInput',
                type: 'password',
                placeholder: 'Enter your password'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    })

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'login');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }

    const submitForm = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let dataToSubmit = generateData(formdata, 'login');
        let formIsValid = isFormValid(formdata, 'login');

        if (formIsValid) {
            dispatch(loginUser(dataToSubmit)).then(response => {
                if (response.payload.success) {
                    navigate('/admin/dashboard');
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(response.payload.message);
                }
            });
        } else {
            formErrorHandler(true);
            errorMessageHandler('PLEASE CHECK YOUR DATA');
        }

    }

    return (
        <div>

            <form onSubmit={(event) => submitForm(event)}>
                <div className="formGroup">
                    <label className="controlLabel">Email</label>
                    <div className="formWrapper">
                        <div className="iconPosition">
                            <FaEnvelope className="icon agraicon" />
                        </div>
                        <FormField
                            id={'email'}
                            formdata={formdata.email}
                            change={(element) => updateForm(element)}
                            myclass={'form-control'}
                        />
                    </div>
                </div>
                <div className="formGroup">
                    <label className="controlLabel">Password</label>
                    <div className="formWrapper">
                        <div className="iconPosition">
                            <FaLock className="icon agraicon" />
                        </div>
                        <FormField
                            id={'password'}
                            formdata={formdata.password}
                            change={(element) => updateForm(element)}
                            myclass={'form-control'}
                        />
                    </div>
                </div>
                {
                    formError ?
                        <div className="errorLabel mt8">
                            {/* PLEASE CHECK YOUR DATA */}
                            {errorMessage}
                        </div>
                        : null
                }
                <div className="formActions">
                    <MyButton
                        name="memberlogin"
                        classname="submitButton submitButtonShadow blueColor"
                        type="submit"
                        title="LOGIN"
                    />
                </div>
            </form>
        </div>
    );
}

export default LoginPage;