import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import MainLayout from '../../hoc/mainlayout';
import RoleTable from './table';

import {
    getUsers
} from '../../../reduceractions/actions/admin/user_actions';

import {
    getRoles
} from '../../../reduceractions/actions/general/role_actions';
import { useNavigate } from 'react-router-dom';

const MainUsers = () => {

    const userdata = useSelector(state => state.user.userData);
    const getallusers = useSelector(state => state.user.users && state.user.users.users)
    const getallroles = useSelector(state => state.role.roles && state.role.roles.roles)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [width, widthHandler] = React.useState(window.innerWidth);
    const isMobile = width <= 767.98;

    const [number, numberHandler] = React.useState('');
    const [currentnumber, currentnumberHandler] = React.useState();

    const [selectedThisData, selectedThisDataHandler] = React.useState({});

    const [alldata, alldataHandler] = React.useState([]);
    const [alldataTable, alldataTableHandler] = React.useState([]);
    const [allroles, allrolesHandler] = React.useState([]);

    const [createstatus, createstatusHandler] = React.useState(false);
    const [editstatus, editstatusHandler] = React.useState(false);
    const [changepassword, changepasswordHandler] = React.useState(false);

    React.useEffect(() => {

        function handleWindowSizeChange() {
            widthHandler(window.innerWidth);
        };

        // Bind the event listener
        document.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    }, [])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                if (getallusers && getallusers.length > 0) {
                    let afterRawData = [];

                    let yearNow = new Date().getFullYear().toString();

                    var datanumber = `/${yearNow}/`;

                    const allusersCopy = getallusers;

                    allusersCopy.forEach((item, index) => {
                        if (item.masteradmin !== 1 && item.isConfirmed !== false) {
                            afterRawData.push(item)
                        }
                    })

                    const number = afterRawData.filter((item) => {
                        return item.usercode.includes(datanumber)
                    })
                    if (getallroles && getallroles.length > 0) {
                        allrolesHandler(getallroles);
                    }
                    if (userdata && userdata.masteradmin === 1) {
                        alldataHandler(getallusers);
                        alldataTableHandler(afterRawData);
                        currentnumberHandler(number.length);
                    } else {
                        navigate('/admin/notauthorize')
                    }
                }
            }
            fetchData()

        }
        return () => {
            mounted = false;
        };
    }, [userdata, getallroles, getallusers, navigate])

    const onRefresh = async () => {
        const getroles = await dispatch(getRoles());
        const getusers = await dispatch(getUsers());

        let afterRawData = [];

        let yearNow = new Date().getFullYear().toString();

        var datanumber = `/${yearNow}/`;

        const allusersCopy = getusers.payload.users;

        allusersCopy.forEach((item, index) => {
            if (item.masteradmin !== 1 && item.isConfirmed !== false) {
                afterRawData.push(item)
            }
        })

        const number = afterRawData.filter((item) => {
            return item.usercode.includes(datanumber)
        })
        if (userdata) {
            alldataHandler(getusers.payload.users);
            alldataTableHandler(afterRawData);
            allrolesHandler(getroles.payload.roles);
            currentnumberHandler(number.length);
        }
    }

    const selectedNumber = (selected) => {
        numberHandler(selected);
    }

    var code = 'KAR'
    let yearNow = new Date().getFullYear().toString();
    let monthNow = new Date().getMonth().toString();
    var datanumber = `${code}/${monthNow}/${yearNow}/${currentnumber + 1}`;

    return (
        <MainLayout>
            <div className="masterContainer">
                <div className="masterContent">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${isMobile ? "card" : "card minHeightCustomer"}`}>
                                <h4 className="cardTitle"><strong>User</strong>
                                    {
                                        createstatus &&
                                            datanumber !== "" ? <span> No: {datanumber}</span>
                                            : editstatus ? <span> Edit: {selectedThisData && selectedThisData.usercode}</span>
                                                : changepassword ? <span> Change Password: {selectedThisData && selectedThisData.usercode}</span>
                                                    : <span> List</span>
                                    }
                                </h4>

                                <RoleTable
                                    userdata={userdata}
                                    alldata={alldata}
                                    allroles={allroles}
                                    handleRefresh={onRefresh}
                                    tableName="roleTable"
                                    isMobile={isMobile}
                                    createstatusHandler={createstatusHandler}
                                    editstatusHandler={editstatusHandler}
                                    changepasswordHandler={changepasswordHandler}
                                    selectednumber={selectedNumber}
                                    selectedThisData={selectedThisData}
                                    selectedThisDataHandler={selectedThisDataHandler}
                                    currentnumber={currentnumber}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );

}

export default MainUsers;