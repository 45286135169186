import axios from 'axios';
import {
    GET_ROLES,
    ADD_ROLE,
    CLEAR_ROLE,
    UPDATE_ROLE,
    CLEAR_UPDATE_ROLE,
    DELETE_ROLE
} from './types';

import { ROLE_SERVER } from '../../misc';

export function getRoles(){
    const request = axios.get(`${ROLE_SERVER}/roles`)
    .then(response => response.data);

    return {
        type: GET_ROLES,
        payload: request
    }
}

export function addRole(dataToSubmit){
    const request = axios.post(`${ROLE_SERVER}/role`,dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_ROLE,
        payload: request
    }
}

export function clearRole(){
    return {
        type: CLEAR_ROLE,
        payload: ''
    }
}

export function updateRole(dataToSubmit){
    const request = axios.post(`${ROLE_SERVER}/update`,dataToSubmit)
        .then(response => response.data);
        // .then(response => {
        //     return response.data
        // });

    return {
        type: UPDATE_ROLE,
        payload: request
    }
}

export function clearUpdateRole(){
    return {
        type: CLEAR_UPDATE_ROLE,
        payload: ''
    }
}

export function deleteRole(id){
    const request = axios.delete(`${ROLE_SERVER}/delete/${id}`)
        .then(response => response.data);
    
    return {
        type: DELETE_ROLE,
        payload: request
    }
}