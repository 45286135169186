import React from 'react';
import { useDispatch } from 'react-redux';

import {
    uploadAllProducts
} from '../../../reduceractions/actions/stock/product_actions';

import { FaCheck, FaChevronLeft } from 'react-icons/fa';

const UploadForm = ({
    showUpload,
    uploadstatus,
    uploadstatusHandler,
    showUploadFormHandler,
    handleRefresh,
    isMobile,
    height
}) => {

    const dispatch = useDispatch();

    const [fileData, fileDataHandler] = React.useState(null);
    const [buttonClicked, buttonClickedHandler] = React.useState(false);

    const [submitSuccess, submitSuccessHandler] = React.useState(false);

    const [formError, formErrorHandler] = React.useState(false);
    const [formSuccess, formSuccessHandler] = React.useState(false);
    const [errorMessage, errorMessageHandler] = React.useState('');

    const goBackToTable = () => {
        uploadstatusHandler(false);
        showUploadFormHandler(false);
        handleRefresh();
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const onFileChange = (event) => {
        formErrorHandler(false);
        errorMessageHandler("");
        submitSuccessHandler(false);

        var fileTypes = ['csv', 'xls', 'xlsx'];  //acceptable file types
        const input = event.target;
        var extension = input.files[0].name.split('.').pop().toLowerCase();  //file extension from input file
        var isSuccess = fileTypes.indexOf(extension) > -1;  //is extension in acceptable types
        if (isSuccess) {
            submitSuccessHandler(true);
            const reader = new FileReader();
            reader.onload = () => {
                const fileData = reader.result;
                const wb = window.XLSX.read(fileData, { type: 'binary' });
                wb.SheetNames.forEach((sheetName) => {
                    const rowObj = window.XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
                    const jsonObj = rowObj;
                    fileDataHandler(jsonObj)
                })
            };
            reader.readAsBinaryString(input.files[0]);
        } else {
            formErrorHandler(true);
            errorMessageHandler("Please upload only file csv / xls / xlsx");
            submitSuccessHandler(false);
        }
    }

    const handleDataSubmit = (event) => {
        event.preventDefault();

        var headerExcel = ['id', 'productId', 'name', 'price', 'categories', 'quantity'];
        let datauploaded = [];
        let products = [];

        if (fileData.length > 0) {
            products = fileData;
            Object.keys(products[0]).forEach((result) => {
                datauploaded.push(result)
            })
            var isVerified = headerExcel.some(r => datauploaded.indexOf(r) > -1)
            if (isVerified) {
                fileDataHandler(null);
                buttonClickedHandler(true);
                dispatch(uploadAllProducts(products)).then((getproducts) => {
                    // console.log(getproducts, "<<<getgetget")
                    if (getproducts.payload.success) {
                        formSuccessHandler(true)
                        setTimeout(() => {
                            buttonClickedHandler(false);
                            formSuccessHandler(false);
                            refreshPage();
                        }, 1500)
                    } else {
                        formErrorHandler(true);
                        buttonClickedHandler(false);
                        formSuccessHandler(false);
                        errorMessageHandler(getproducts.payload.message);
                        submitSuccessHandler(false);
                    }
                })
            } else {
                formErrorHandler(true);
                buttonClickedHandler(false);
                formSuccessHandler(false);
                errorMessageHandler("PLEASE UPLOAD EXCEL WITH CORRECT DATA");
                submitSuccessHandler(false);

            }
        } else {
            formErrorHandler(true);
            buttonClickedHandler(false);
            formSuccessHandler(false);
            errorMessageHandler("FILE INCORRECT OR EMPTY, PLEASE UPLOAD THE CORRECT EXCEL FILE");
            submitSuccessHandler(false);
        }
    }

    const className = `${showUpload ? 'cardBody' : 'displayNone'}`;

    return (
        <div className={className}>
            {
                formSuccess ?
                    <div className='row'>
                        <div
                            className="col-md-12 col-xs-12"
                            style={{
                                minHeight: 500
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                minHeight: 500,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <FaCheck
                                        style={{
                                            fontSize: 50,
                                            color: '#213e96'
                                        }}
                                    />
                                    <div style={{
                                        padding: 20
                                    }}>
                                        add success, please wait
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    buttonClicked ?
                        <div className='row'>
                            <div
                                className="col-md-12 col-xs-12"
                                style={{
                                    minHeight: 500
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    minHeight: 500,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>

                                        <div style={{
                                            padding: 20
                                        }}>
                                            Please wait, add data to database
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <input
                                    type="file"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={onFileChange}
                                />
                            </div>
                            <div className="col-md-12 col-xs-12 mt10">
                                {
                                    formError ?
                                        <div className="errorLabel">
                                            {errorMessage}
                                        </div>
                                        : null

                                }
                            </div>
                            <div className={`${isMobile ? "col-md-12 col-xs-12" : "col-md-12 col-xs-12 posAbsolute confirmInvoiceButton"}`}>
                                <div
                                    className="inlineFlex"
                                    style={{
                                        width: '100%',
                                        paddingRight: 15,
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div
                                        className="agrapanaBtn redBackground p8"
                                        title=""
                                        onClick={() => goBackToTable()}
                                    >
                                        <FaChevronLeft className="icon agraicon w18px" />
                                    </div>

                                    {
                                        submitSuccess ?
                                            <div className="doubleButton"
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <button
                                                    onClick={(event) => handleDataSubmit(event)}
                                                    disabled={buttonClicked}
                                                    className={`${buttonClicked ? "agrapanaBtn agrapanaBtnPrimary divBtn ml10 disabled" : "agrapanaBtn agrapanaBtnPrimary divBtn ml10"}`}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                            :
                                            <div className="doubleButton">
                                                <button disabled className="agrapanaBtn agrapanaBtnPrimary divBtn ml10 disabled2">
                                                    Submit
                                                </button>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
            }

        </div>
    )

}


export default UploadForm;