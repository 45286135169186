import React from 'react';

import Zoom from 'react-reveal/Zoom';
import { IoMdMail, IoMdCall } from "react-icons/io";
import { FiMapPin } from "react-icons/fi";

const GetinTouch = (props) => {
    return (
        <div id="contact" style={{ marginBottom: '80px' }}>
            <h2 className="sectiontitleku" style={{ paddingTop: '80px' }}>Get in touch</h2>

            <div className="container">

                <div className="title" style={{ marginBottom: '20px' }}>Request our Latest Catalog</div>
                <span>Contact us now!</span>
                <Zoom duration={500} delay={500}>
                    <div className="row icons" style={props.isMobile ? { marginTop: '60px', marginBottom: '30px' } : { marginTop: '60px', marginBottom: '50px' }}>
                        <div 
                            className={props.isMobile ? "column col-xs-12" : "column col-md-6"}
                            style={props.isMobile ? { marginBottom: '30px' } : null}
                        >
                            <IoMdMail />
                            <a href="mailto:sales@wingleysetiajaya.co.id" id="mail" target="blank">sales@wingleysetiajaya.co.id</a>
                        </div>
                        <div className="column col-md-6 col-xs-12">
                            <FiMapPin />
                            <span>KP.Citerep GK No.01 RT.003 RW.006, Gunung Sindur,
                                <br />Bogor, Jawa Barat, 16340</span>
                        </div>
                    </div>
                </Zoom>
                <Zoom duration={500} delay={700}>
                    <div className="row icons">
                        <div className="column col-md-4 col-xs-12 marginbottom20">
                            <IoMdCall />
                            <a href="tel:0212966239697" id="phone">+6221-29662396-97</a>
                        </div>
                        <div className="column col-md-4 col-xs-12 marginbottom20">
                            <IoMdCall />
                            <a href="tel:02125665840" id="phone">+6221-25665840</a>
                        </div>
                        <div className="column col-md-4 col-xs-12">
                            <IoMdCall />
                            <a href="tel:02129665843" id="phone">+6221-29665843</a>
                        </div>
                    </div>
                </Zoom>

            </div>

        </div>
    );
};

export default GetinTouch;