import React from 'react';

import Lists from './lists';
import Pagination from '../pagination';

const CannotAddFunction = ({
    show,
    handleChange,
    totalData,
    filterText,
    pageLimit,
    pageChanged,
    currentPage,
    title,
    selectedItems
}) => {
    const className = `overlayForm ${show ? 'open' : ''}`;
    const [mydata, mydataHandler] = React.useState([]);
    const [dataCount, dataCountHandler] = React.useState("");
    React.useEffect(() => {
        var alldata = [];

        var offset = (currentPage - 1) * pageLimit;
        if (totalData) {
            totalData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            if (filterText) {
                if (title === 'products') {
                    alldata = totalData.filter((data, index) => (
                        data.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                        || data.productId.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                    ))
                } else {
                    alldata = totalData.filter((data, index) => (data.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1))
                }
            } else {
                alldata = totalData

            }
            alldata = alldata.map((data, index) => <Lists items={data} key={index} number={index} selectedItems={selectedItems} title={title} />)
            var semuadata = [...alldata];
            var mydatas = semuadata.slice(offset, offset + pageLimit);
            dataCountHandler(semuadata.length);
            mydataHandler(mydatas);
        }

    }, [filterText, currentPage, pageLimit, selectedItems, totalData, title])

    return (
        <div className={className}>
            <h4 className="cardTitle"><strong>Choose</strong> {title}</h4>
            <div className="cardBody minHeight530px">
                <div className="row">
                    <div className="col-md-12 col-xs-12 mb15">
                        <input
                            type="text"
                            name="Search"
                            placeholder="Search"
                            title="Type in a name"
                            value={filterText || ''}
                            // ref="filterTextInput"
                            onChange={(event) => handleChange(event)}
                        />
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <ul className="menu invoiceForm mb50">
                            {
                                mydata.length === 0
                                    ?
                                    filterText ?
                                        <li className="menuItem">
                                            <div
                                                className="menuLink textBlack"
                                            >
                                                {title} not exist, Please add first
                                            </div>
                                        </li>
                                        :
                                        <li className="menuItem">
                                            <div
                                                className="menuLink textBlack"
                                            >
                                                No Data, Please add first
                                            </div>
                                        </li>
                                    : mydata
                            }
                        </ul>
                    </div>
                    <nav className="col-md-12 col-xs-12">
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Pagination Size={dataCount} pageLimit={pageLimit} onPageChanged={pageChanged} currentPage={currentPage} />
                        </div>
                    </nav>
                    <div className="col-md-12 col-xs-12">
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <div className="pageNumber dinFlex">{dataCount ? `${currentPage}/${parseInt((dataCount / pageLimit) + ((dataCount % pageLimit) > 0 ? 1 : 0))}` : 0} Pages</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default CannotAddFunction;
