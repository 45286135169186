import axios from 'axios';
import {
    REGISTER_USER,
    CLEAR_REGISTER_USER,
    LOGIN_USER,
    AUTH_USER,
    LOGOUT_USER,
    USER_CONFIRMATION,
    CHANGE_PASSWORD,
    RECONFIRM_EMAIL_USER,
    ADD_IMAGES,
    FORGOT_PASSWORD,
    UPDATE_DATA_USER,
    CLEAR_UPDATE_USER_DATA,
    ADD_ADDRESS,
    UPDATE_ADDRESS,
    UPDATE_SELECTED_ADDRESS,
    CLEAR_ADDRESS,
    NOTIFICATIONS_USER,
    GET_USERS,
    UPDATE_DATA_PASSWORD,
    CLEAR_UPDATE_PASSWORD
} from './types';

import { USER_SERVER } from '../../misc';
import { RESOURCE_IS_LOADING } from '../general/types';


export function loading(status) {
    return {
        type: RESOURCE_IS_LOADING,
        status
    }
}

export function notifications(status) {
    return {
        type: NOTIFICATIONS_USER,
        status
    }
}

export function getUsers(){
    const request = axios.get(`${USER_SERVER}/users`)
    .then(response => response.data);

    return {
        type: GET_USERS,
        payload: request
    }
}

export function forgotPassword(dataToSubmit) {
    const request = axios.post(`${USER_SERVER}/resetuser`, dataToSubmit)

    return {
        type: FORGOT_PASSWORD,
        payload: request
    }
}

export function changePassword(dataToSubmit) {
    const request = axios.post(`${USER_SERVER}/resetpasswordpage`, dataToSubmit)

    return {
        type: CHANGE_PASSWORD,
        payload: request
    }
}

export function userConfirmation(data) {
    const request = axios.get(`${USER_SERVER}/confirmation/${data}`)

    return {
        type: USER_CONFIRMATION,
        payload: request
    }
}

export function reconfirmEmail(dataToSubmit) {
    const request = axios.post(`${USER_SERVER}/reconfirmation`, dataToSubmit)
        .then(response => response.data);

    return {
        type: RECONFIRM_EMAIL_USER,
        payload: request
    }
}

export function loginUser(dataToSubmit) {
    const request = axios.post(`${USER_SERVER}/login`, dataToSubmit)
        .then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function logoutUser() {
    const request = axios.get(`${USER_SERVER}/logout`)
        .then(response => response.data);

    return {
        type: LOGOUT_USER,
        payload: request
    }
}

export function registerUser(dataToSubmit) {
    const request = axios.post(`${USER_SERVER}/register`, dataToSubmit)
        .then(response => response.data);

    return {
        type: REGISTER_USER,
        payload: request
    }
}

export function clearRegisterUser(){
    return {
        type: CLEAR_REGISTER_USER,
        payload: ''
    }
}

export function auth() {
    const request = axios.get(`${USER_SERVER}/auth`)
        .then(response => response.data);

    return {
        type: AUTH_USER,
        payload: request
    }
}

export function addImages(dataToSubmit) {
    const request = axios.post(`${USER_SERVER}/uploadimage`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_IMAGES,
        payload: request
    }
}

export function updateUserData(dataToSubmit) {

    const request = axios.post(`${USER_SERVER}/updateprofile`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_DATA_USER,
        payload: request
    }
}

export function clearUpdateUser() {

    return {
        type: CLEAR_UPDATE_USER_DATA,
        payload: ''
    }
}

export function updatePassword(dataToSubmit) {

    const request = axios.post(`${USER_SERVER}/updatepassword`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_DATA_PASSWORD,
        payload: request
    }
}

export function clearUpdatePassword() {

    return {
        type: CLEAR_UPDATE_PASSWORD,
        payload: ''
    }
}

export function addAddress(dataToSubmit, type) {

    const request = axios.post(`${USER_SERVER}/addaddress?type=${type}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_ADDRESS,
        payload: request
    }
}

export function updateAddress(dataToSubmit) {

    const request = axios.post(`${USER_SERVER}/updateaddress`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_ADDRESS,
        payload: request
    }
}

export function updateSelectedAddress(id) {
    const request = axios.post(`${USER_SERVER}/selectedaddress?id=${id}`)
        .then(response => response.data);

    return {
        type: UPDATE_SELECTED_ADDRESS,
        payload: request
    }
}

export function clearAddress() {

    return {
        type: CLEAR_ADDRESS,
        payload: ''
    }
}