import {
    GET_REQUESTS,
    GET_QUOTATIONS,
    GET_QUOTATION_HISTORY,
    ADD_QUOTATION,
    CLEAR_QUOTATION,
    UPDATE_QUOTATION,
    CLEAR_UPDATE_QUOTATION,
    REVISI_QUOTATION,
    CLEAR_REVISI_QUOTATION,
    APPROVE_QUOTATION,
    REJECT_QUOTATION
} from '../../actions/general/types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_REQUESTS:
            return {
                ...state,
                requests: action.payload
            }
        case GET_QUOTATIONS:
            return {
                ...state,
                quotations: action.payload
            }
        case GET_QUOTATION_HISTORY:
            return {
                ...state,
                quotationhistory: action.payload
            }
        case APPROVE_QUOTATION:
            return {
                ...state,
                approveQuotation: action.payload
            }
        case REJECT_QUOTATION:
            return {
                ...state,
                rejectQuotation: action.payload
            }
        case ADD_QUOTATION:
            return {
                ...state,
                addQuotation: action.payload
            }
        case CLEAR_QUOTATION:
            return {
                ...state,
                addQuotation: action.payload
            }
        case UPDATE_QUOTATION:
            return {
                ...state,
                updateQuotation: action.payload
            }
        case CLEAR_UPDATE_QUOTATION:
            return {
                ...state,
                updateQuotation: action.payload
            }
        case REVISI_QUOTATION:
            return {
                ...state,
                revisiQuotation: action.payload
            }
        case CLEAR_REVISI_QUOTATION:
            return {
                ...state,
                revisiQuotation: action.payload
            }
        // case DELETE_CUSTOMER:
        //     return {
        //         ...state,
        //         deleteCustomer: action.payload
        //     }
        default:
            return state;
    }
}