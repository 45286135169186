import React from 'react';
import {
    Routes,
    Route
} from "react-router-dom";

import Layout from '../hoc/layout';

import RegisterConfirmation from '../components/login/registerconfirmation';
import Home from '../components/home';
import NoMatch from '../components/nomatch';

const PublicLayout = () => {
    // console.log(props)
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="confirmation/:token" element={<RegisterConfirmation />} />
            </Route>
            <Route
                path="*"
                element={<NoMatch />}
            />
        </Routes>
    );
};

export default PublicLayout;