// SERVER ROUTES
export const USER_SERVER = '/api/users';
export const PRODUCT_SERVER = '/api/product';
export const ORDER_SERVER = '/api/order';
export const ONGKIR_SERVER = '/api/ongkir';
export const PURCHASE_SERVER = '/api/allpurchase';
export const PRODUCT_TO_PURCHASE_SERVER = '/api/producttopurchases';
export const IMAGE_SERVER = '/api/images';
export const ADDRESS_SERVER = '/api/alladdresses';
export const VENDOR_SERVER = '/api/allvendors';
export const CUSTOMER_SERVER = '/api/allcustomers';
export const INVOICE_SERVER = '/api/allinvoices';
export const DELIVERY_SERVER = '/api/alldeliveries';
export const PDF_SERVER = '/api/uploadpdf';
export const ROLE_SERVER = '/api/allroles';
export const SETTING_SERVER = '/api/setting';
export const QUOTATION_SERVER = '/api/quotation';
export const TERMOFPAYMENT_SERVER = '/api/termofpayment';

export const MEMBER_SERVER = '/api/members';

export const LOADING_SERVER = '/api/loading';