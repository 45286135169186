
export const RESOURCE_IS_LOADING = 'resource_is_loading';
/////////////////////////////////////////////////////////////////////
////////////////////////////// PDF //////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const UPLOADPDF_INVOICE = 'uploadpdf_invoice';
export const UPLOADPDF_DELIVERY = 'uploadpdf_delivery';
export const UPLOADPDF_QUOTATION = 'uploadpdf_quotation';

/////////////////////////////////////////////////////////////////////
///////////////////////////// REQUEST ///////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_REQUESTS = 'get_requests';

/////////////////////////////////////////////////////////////////////
////////////////////////////// ROLE /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_ROLES = 'get_roles';
export const ADD_ROLE = 'add_role';
export const CLEAR_ROLE = 'clear_role';
export const UPDATE_ROLE = 'update_role';
export const CLEAR_UPDATE_ROLE = 'clear_update_role';
export const DELETE_ROLE = 'delete_role';

/////////////////////////////////////////////////////////////////////
////////////////////////////// ROLE /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_QUOTATIONS = 'get_quotations';
export const ADD_QUOTATION = 'add_quotation';
export const CLEAR_QUOTATION = 'clear_quotation';
export const UPDATE_QUOTATION = 'update_quotation';
export const CLEAR_UPDATE_QUOTATION = 'clear_update_quotation';
export const REVISI_QUOTATION = 'revisi_quotation';
export const CLEAR_REVISI_QUOTATION = 'clear_revisi_quotation';
export const GET_QUOTATION_HISTORY = 'get_quotation_history';
export const APPROVE_QUOTATION = 'approve_quotation';
export const REJECT_QUOTATION = 'reject_quotation';

/////////////////////////////////////////////////////////////////////
////////////////////////////// SETTING /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_SETTING = 'get_setting';
export const UPDATE_SETTING = 'update_setting';
export const CLEAR_UPDATE_SETTING = 'clear_update_setting';