import React from 'react';

const FooterSection = () => {

    let today = new Date().getFullYear().toString()
    return (
        <footer>
            <div id="copyright">
                <img src={window.location.origin + "/assets/images/formicalogo.png"} alt="logo" /> &nbsp;
				<img src={window.location.origin + "/assets/images/performlogonegative.png"} alt="logo" />
                <span className="block" style={{ lineHeight: 2 }}>
                    PT. Wingley Setia Jaya Copyright {today}.<br />
                    All Rights Reserved.<br />
                    Powered by <a href="https://www.lumisoft.co.id/" target="_blank" rel="noopener noreferrer">Lumisoft</a>.
                </span>
            </div>

        </footer>
    );
};

export default FooterSection;