import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import configureStore from './configureStore';

import './resources/styles.css';

import MyRoutes from './routes';

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container)

const renderApp = () =>
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <MyRoutes />
            </Provider>
        </React.StrictMode>
    );

// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('./myroutes', renderApp)
// }

renderApp();