import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// import {
//     Outlet
// } from "react-router-dom";

import { FaHome, FaUser, FaListUl, FaBoxes, FaUsers, FaEdit, FaTasks, FaExclamation, FaCheck, FaTimes, FaBuffer, FaLock } from 'react-icons/fa'

const MainLayout = ({ children }) => {

    const user = useSelector(state => state.user);
    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings)

    const location = useLocation();

    const [height, heightHandler] = React.useState(window.innerHeight);
    const [settings, settingsHandler] = React.useState([]);

    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    settingsHandler(selected);
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    const fixedHeight = height - 56;

    const main = [
        {
            name: 'Dashboard',
            linkTo: '/admin/dashboard',
            faicons: <FaHome className="icon agraicon w18px mr20" />
        },
        {
            name: 'Customers',
            linkTo: '/admin/customers',
            faicons: <FaUser className="icon agraicon w18px mr20" />
        },
        {
            name: 'Quotations',
            linkTo: '/admin/quotations',
            faicons: <FaListUl className="icon agraicon w18px mr20" />
        },
        {
            name: 'Products',
            linkTo: '/admin/products',
            faicons: <FaBoxes className="icon agraicon w18px mr20" />
        }
    ]

    const managerowner = [
        {
            name: 'Users',
            linkTo: '/admin/users',
            faicons: <FaUsers className="icon agraicon w18px mr20" />
        },
        {
            name: 'Terms',
            linkTo: '/admin/terms',
            faicons: <FaBuffer className="icon agraicon w18px mr20" />
        }
    ]

    const webmasteronly = [
        {
            name: 'Roles',
            linkTo: '/admin/roles',
            faicons: <FaLock className="icon agraicon w18px mr20" />
        }
    ]

    React.useEffect(() => {

        function handleWindowSizeChange() {
            heightHandler(window.innerHeight);
        };

        // Bind the event listener
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const generateLinks = (links) => {
        return links.map((item, i) => (
            <li key={i} className="menuItem">
                <NavLink
                    to={item.linkTo}
                    className={(navData) => navData.isActive ? "linksEach menuLink active" : "linksEach menuLink"}
                >
                    {item.faicons}
                    {item.name}
                </NavLink>
            </li>
        ))
    }

    // const managerLinks = (links) => {
    //     return links.map((item, i) => (
    //         <li key={i} className="menuItem">
    //             <NavLink
    //                 to={item.linkTo}
    //                 className={(navData) => navData.isActive ? "linksEach menuLink active" : "linksEach menuLink" }
    //             >
    //                 {item.faicons}
    //                 {item.name}
    //             </NavLink>
    //         </li>
    //     ))
    // }

    return (
        <div className="pageWrapper">
            <div className="memberpageLeftNav">
                <nav className="sidebarNavigation agContainer">
                    <ul className="menu">
                        <li
                            className="navigationHeader"
                            style={{
                                backgroundColor: '#4368d6',
                                color: '#ffffff',
                            }}
                        >Main Page</li>
                        {generateLinks(main)}
                        {
                            user.userData ?
                                user.userData.masteradmin > 0 ?
                                    <li
                                        className="navigationHeader"
                                        style={{
                                            backgroundColor: '#4368d6',
                                            color: '#ffffff'
                                        }}
                                    >Restricted Link</li>
                                    : null
                                : null
                        }
                        {
                            user.userData ?
                                user.userData.masteradmin > 0 ?
                                    generateLinks(managerowner)
                                    : null
                                : null
                        }
                        {
                            user.userData ?
                                user.userData.email === 'emailnaxxxx@gmail.com' ?
                                    generateLinks(webmasteronly)
                                    : null
                                : null
                        }
                        <li className="version">v {settings.version}</li>
                        {
                            location.pathname === "/admin/quotations" ?
                                <li style={{ marginLeft: '10px', marginTop: '5px', marginBottom: '5px', display: "flex" }}>
                                    <div
                                        className="agrapanaBtn midnightblueBackground p8 mr5"
                                        title=""
                                    >
                                        <FaEdit className="icon agraicon w18px" /> = EDIT
                                    </div>
                                </li>
                                : null
                        }
                        {
                            location.pathname === "/admin/quotations" ?
                                <li style={{ marginLeft: '10px', marginBottom: '5px', display: "flex" }}>
                                    <div
                                        className="agrapanaBtn grayBackground p8 mr5"
                                        title=""
                                    >
                                        <FaTasks className="icon agraicon w18px" /> = HISTORY
                                    </div>
                                </li>
                                : null
                        }
                        {
                            location.pathname === "/admin/quotations" ?
                                <li style={{ marginLeft: '10px', marginBottom: '5px', display: "flex" }}>
                                    <div
                                        className="agrapanaBtn salmonBackground p8 mr5"
                                        title=""
                                    >
                                        <FaExclamation className="icon agraicon w18px" /> = CAN APPROVE
                                    </div>
                                </li>
                                : null
                        }
                        {
                            location.pathname === "/admin/quotations" ?
                                <li style={{ marginLeft: '10px', marginBottom: '5px', display: "flex" }}>
                                    <div
                                        className="agrapanaBtn redBackground p8 mr5"
                                        title=""
                                    >
                                        <FaExclamation className="icon agraicon w18px" /> = ON REVISION
                                    </div>
                                </li>
                                : null
                        }
                        {
                            location.pathname === "/admin/quotations" ?
                                <li style={{ marginLeft: '10px', marginBottom: '5px', display: "flex" }}>
                                    <div
                                        className="agrapanaBtn blueBackground p8 mr5"
                                        title=""
                                    >
                                        <FaCheck className="icon agraicon w18px" /> = APPROVED
                                    </div>
                                </li>
                                : null
                        }
                        {
                            location.pathname === "/admin/quotations" ?
                                <li style={{ marginLeft: '10px', marginBottom: '5px', display: "flex" }}>
                                    <div
                                        className="agrapanaBtn redBackground p8 mr5"
                                        title=""
                                    >
                                        <FaTimes className="icon agraicon w18px" /> = REJECTED
                                    </div>
                                </li>
                                : null
                        }
                    </ul>
                </nav>
            </div>
            <div className="masterContainer" style={{
                minHeight: fixedHeight
            }}>
                {children}
            </div>
        </div>
    );

}

export default MainLayout;