import React from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';

import { useSelector } from 'react-redux';

import DrawerToggle from '../sidedrawer/drawertoggle';

import {
    // FaUser, 
    FaCog,
    // FaCalendar, 
    // FaQuestion, 
    FaSignOutAlt,
    FaChevronUp,
    FaChevronDown
} from 'react-icons/fa'

const AdminHeader = (props) => {

    const userdata = useSelector(state => state.user.userData);

    const navigate = useNavigate();

    const [name, nameHandler] = React.useState('')
    const [profilemenu, profilemenuHandler] = React.useState(false);
    const [dontblur, dontblurHandler] = React.useState(false);

    // const account = [
    //     {
    //         name: 'Profile',
    //         linkTo: '/admin/profile',
    //         faicons: <FaUser className="icon" />
    //     },
    //     {
    //         name: 'Settings',
    //         linkTo: '/admin/settings',
    //         faicons: <FaCog className="icon" />
    //     },
    //     {
    //         name: 'My Calendar',
    //         linkTo: '/admin/calendar',
    //         faicons: <FaCalendar className="icon" />
    //     }
    // ]

    const account = [
        {
            name: 'Settings',
            linkTo: '/admin/settings',
            faicons: <FaCog className="icon" />
        }
    ]

    const accountbottom = [
        // {
        //     name: 'Help',
        //     linkTo: '/admin/help',
        //     faicons: <FaQuestion className="icon" />
        // },
        {
            name: 'Logout',
            linkTo: '/auth/logout',
            faicons: <FaSignOutAlt className="icon" />
        }
    ]

    React.useEffect(() => {
        if (userdata) {
            nameHandler(userdata.name);
        }
    }, [userdata])

    const showprofilemenu = () => {
        profilemenuHandler(true);
    }

    const hideprofilemenu = () => {
        profilemenuHandler(false);
    }

    const onMouseEnter = () => {
        dontblurHandler(true);
    }

    const onMouseLeave = () => {
        dontblurHandler(false);
    }

    const logoutLink = (link) => {
        navigate(link)
    }

    const showLinks = (type) => {
        let list = [];

        type.forEach((item) => {
            list.push(item)
        });

        return list.map((item, i) => (
            <li
                key={i}
                onClick={() => logoutLink(item.linkTo)}
                className="listgroupitem"
            >
                <span className="memberHeaderLink">
                    {item.name}
                    {item.faicons}
                </span>

            </li>
        ))
    }

    return (
        <div id="#main">
            <header className="navbar navbarfixedtop">
                <div className="navbarlogowrapper">
                    <Link
                        to="/admin/dashboard"
                        className="navbarlogoimage"
                    >
                        <img className="logo_img mobileHidden" src={window.location.origin + "/assets/images/logo.png"} alt="" />
                    </Link>
                </div>
                <div className="memberToggleButton">
                    <DrawerToggle click={props.drawerClickHandler} />
                </div>
                <ul className="nav navbarnav navbarright">
                    <li className={profilemenu ? "dropdown dropdownfuse open" : "dropdown dropdownfuse"}>
                        <div
                            className="dropdowntoggle ml0 memberDiv"
                            onBlur={dontblur ? null : hideprofilemenu}
                            onFocus={showprofilemenu}
                            tabIndex={0}
                        >
                            <span className="hiddenXs hiddenSm">
                                <span className="name">{name}</span>
                            </span>
                            <span className="hiddenXs hiddenSm">
                                {
                                    profilemenu ?
                                        <FaChevronUp className="icon" />
                                        : <FaChevronDown className="icon" />
                                }
                            </span>
                            <span className="profile-online">
                                <img src={window.location.origin + "/assets/images/demonix18.jpg"} alt="avatar" />
                            </span>

                        </div>
                        {
                            profilemenu &&
                            <ul
                                className="dropdownmenu listgroup w190px profilemenu"
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                            >
                                {
                                    userdata && userdata.masteradmin === 1 ?
                                        showLinks(account)
                                        : null
                                }
                                {
                                    userdata && userdata.masteradmin === 1 ?
                                        <li className="divider"></li>
                                        : null
                                }
                                {showLinks(accountbottom)}
                            </ul>
                        }
                    </li>
                </ul>
            </header>
        </div>
    );
}

export default AdminHeader;