import {
    GET_ROLES,
    ADD_ROLE,
    CLEAR_ROLE,
    UPDATE_ROLE,
    CLEAR_UPDATE_ROLE,
    DELETE_ROLE
} from '../../actions/general/types';

export default function(state={},action){
    switch(action.type){
        case GET_ROLES:
            return {
                ...state, 
                roles: action.payload
            }
        case ADD_ROLE:
            return {
                ...state,
                addRole: action.payload
            }
        case CLEAR_ROLE:
            return {
                ...state,
                addRole: action.payload
            }
        case UPDATE_ROLE:
            return {
                ...state,
                updateRole: action.payload
            }
        case CLEAR_UPDATE_ROLE:
            return {
                ...state,
                updateRole: action.payload
            }
        case DELETE_ROLE:
            return {
                ...state,
                deleteRole: action.payload
            }
        default:
            return state;
    }
}