import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

// import CircularProgress from '@material-ui/core/CircularProgress';
import BackDrop from '../../../admin/components/headerfooter/backdrop';
// import BackDropWithAdd from '../../../admin/components/headerfooter/backdrop';
import DoubleBackDrop from '../../../admin/components/headerfooter/doublebackdrop';
// import TripleBackDrop from '../../../admin/components/headerfooter/doublebackdrop';
import Pagination from '../../../utils/pagination';

import SearchForm from '../../../utils/searchform/cannotadd';
// import SearchFormWithAdd from '../../../utils/searchform';
// import PopupMessage from '../../../utils/popupmessage';
import PopupMessageNoAdd from '../../../utils/popupmessage/noadd';

import FormField from '../../../utils/form/formfield';
import {
    update,
    validate,
    generateData,
    isFormValid,
    resetFields,
    resetAdditional,
    // populateOptionFields,
    resetProducts,
    convertToRupiah
} from '../../../utils/form/formactions';
// import {
//     addTerm,
//     clearTerm
// } from '../../../reduceractions/actions/admin/termofpayment_actions';

import {
    addQuotation,
    clearQuotation
} from '../../../reduceractions/actions/general/quotation_actions';

import { FaCalendarCheck, FaCheck, FaChevronLeft, FaEnvelope, FaHandshake, FaListAlt, FaMapMarker, FaPhone, FaTimes, FaUser, FaUserCircle } from 'react-icons/fa'

const QuotationForm = ({
    // allusers,
    allrequests,
    // allquotations,
    allproducts,
    allterms,
    allcustomers,
    show,
    showFormStatus,
    // handleRefresh,
    isMobile,
    numberThisData,
    selectednumber,
    // createstatus,
    createstatusHandler,
    // datanumber,
    userdata,
    // myquotations,
    mycustomers
}) => {
    const dispatch = useDispatch();

    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings);

    const [overlayForm, overlayFormHandler] = React.useState(false);
    const [filterText, filterTextHandler] = React.useState('');
    const [currentPage, currentPageHandler] = React.useState(1);
    const [pageSize, pageSizeHandler] = React.useState(10);

    const [process, processHandler] = React.useState(false);
    const [myformData, myformDataHandler] = React.useState('');
    const [dataCount, dataCountHandler] = React.useState('');
    const [mydata, mydataHandler] = React.useState('');
    const [myformTitle, myformTitleHandler] = React.useState('');

    const [inputAdditionalProductData, inputAdditionalProductDataHandler] = React.useState(false);
    const [popupMessage, popupMessageHandler] = React.useState(false);
    const [formError, formErrorHandler] = React.useState(false);
    const [formSuccess, formSuccessHandler] = React.useState(false);

    const [itemProductDelete, itemProductDeleteHandler] = React.useState("");
    const [indexProductDelete, indexProductDeleteHandler] = React.useState("");

    const [confirmProductDelete, confirmProductDeleteHandler] = React.useState(false);

    const [errorMessage, errorMessageHandler] = React.useState('');
    const [additionalerrorMessage, additionalerrorMessageHandler] = React.useState('');

    const [productSelected, productSelectedHandler] = React.useState();
    const [selectedCustomer, selectedCustomerHandler] = React.useState();
    const [selectedTerm, selectedTermHandler] = React.useState();

    const [formdata, formdataHandler] = React.useState({
        title: {
            element: 'input',
            value: '',
            config: {
                name: 'titleInput',
                type: 'text',
                placeholder: 'Enter Title'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        date: {
            element: 'input',
            value: '',
            config: {
                name: 'dateInput',
                type: 'text',
                placeholder: 'Enter date'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        picname: {
            element: 'input',
            value: '',
            config: {
                name: 'picnameInput',
                type: 'text',
                placeholder: 'Enter PIC name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        picphone: {
            element: 'input',
            value: '',
            config: {
                name: 'phoneInput',
                type: 'text',
                placeholder: 'Enter PIC phone'
            },
            validation: {
                required: true,
                phone: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        customer: {
            element: 'input',
            value: '',
            config: {
                name: 'customerInput',
                options: [],
                placeholder: 'Choose your Customer'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        termofpayment: {
            element: 'input',
            value: '',
            config: {
                name: 'termofpaymentInput',
                options: [],
                placeholder: 'Choose your Term of payment'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        products: {
            element: 'input',
            value: [],
            config: {
                name: 'productInput',
                options: [],
                placeholder: 'Choose Product'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    })
    const [additional, additionalHandler] = React.useState({
        quantity: {
            element: 'input',
            value: '',
            config: {
                name: 'quantityInput',
                type: 'text',
                placeholder: 'Input Quantity'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        discount: {
            element: 'input',
            value: '',
            config: {
                name: 'discountInput',
                type: 'text',
                placeholder: 'Input Discount'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    })

    const [taxinvoice, taxinvoiceHandler] = React.useState(11);
    const [later, laterHandler] = React.useState();
    // const taxinvoice = 11;
    // const later = new Date().getTime() + (25 * 24 * 60 * 60 * 1000);
    const [date, dateHandler] = React.useState();
    const [subtotal, subtotalHandler] = React.useState();
    const [discount, discountHandler] = React.useState();
    const [totals, totalsHandler] = React.useState();
    const [tax, taxHandler] = React.useState();
    const [grandtotal, grandtotalHandler] = React.useState();
    const [allproductSelected, allproductSelectedHandler] = React.useState([]);
    const [allTotalPrice, allTotalPriceHandler] = React.useState([]);
    const [allTotalDiscount, allTotalDiscountHandler] = React.useState([]);
    const [idchangetopurple, idchangetopurpleHandler] = React.useState([]);
    // const [products, productsHandler] = React.useState([]);

    // const executedOnceRef = React.useRef(false);
    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    taxinvoiceHandler(selected.currenttax);
                    let now = new Date();
                    let laterdate = selected.laterdate;
                    now.setDate(now.getDate() + laterdate);
                    laterHandler(now);
                    pageSizeHandler(selected.pagesize)
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (allproducts && allproducts.length > 0) {
                formdataHandler(prevState => {
                    let newFormdata = { ...prevState };
                    newFormdata['products'].config.options = allproducts;
                    if (allterms && allterms.length > 0) {
                        newFormdata['termofpayment'].config.options = allterms;
                    }
    
                    if (userdata.masteradmin === 1 && allcustomers && allcustomers.length > 0) {
                        newFormdata['customer'].config.options = allcustomers;
                    }
                    if (userdata.masteradmin !== 1 && mycustomers && mycustomers.length > 0) {
                        newFormdata['customer'].config.options = mycustomers;
                    }
    
                    return newFormdata
                })
            }
        }
        return () => {
            mounted = false;
        };
    }, [allproducts, allterms, allcustomers, mycustomers, userdata.masteradmin])
    // React.useEffect(() => {
    //     if (executedOnceRef.current) { return; }
    //     if (allproducts && allproducts.length > 0) {
    //         formdataHandler(prevState => {
    //             let newFormdata = { ...prevState };
    //             newFormdata['products'].config.options = allproducts;
    //             if (allterms && allterms.length > 0) {
    //                 newFormdata['termofpayment'].config.options = allterms;
    //             }

    //             if (userdata.masteradmin === 1 && allcustomers && allcustomers.length > 0) {
    //                 newFormdata['customer'].config.options = allcustomers;
    //             }
    //             if (userdata.masteradmin !== 1 && mycustomers && mycustomers.length > 0) {
    //                 newFormdata['customer'].config.options = mycustomers;
    //             }

    //             return newFormdata
    //         })
    //         executedOnceRef.current = true;
    //     }
    // }, [allproducts, allterms, allcustomers, mycustomers, userdata.masteradmin])

    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber);
    }

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'quotation');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }

    const onDateChange = (date) => {

        const newFormdata = {
            ...formdata
        }

        const newElement = {
            ...newFormdata['date']
        }

        newElement.value = date._d;

        let validData = [true, ""];
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];

        newFormdata['date'] = newElement;

        const newFormDataAdditional = resetAdditional(additional, 'additional');
        formdataHandler(newFormdata);
        additionalHandler(newFormDataAdditional);
        dateHandler(date._d);
        processHandler(false);
        formErrorHandler(false);
        inputAdditionalProductDataHandler(false);
        formSuccessHandler(false);
        errorMessageHandler('');
        currentPageHandler(1);
        productSelectedHandler({})
        allproductSelectedHandler([])
        allTotalDiscountHandler([])
        allTotalPriceHandler([])
    }

    const selectedItems = (selected) => {
        var formTitle = myformTitle;
        const newFormDataAdditional = resetAdditional(additional, 'additional');
        additionalHandler(newFormDataAdditional);
        processHandler(false);
        formErrorHandler(false);
        inputAdditionalProductDataHandler(false);
        formSuccessHandler(false);
        errorMessageHandler('')

        if (formTitle === "products") {
            // console.log(selected)
            let existingProductSelected = [{}];
            const allProductSelectedCopy = allproductSelected;
            for (let key in allProductSelectedCopy) {
                existingProductSelected[key] = allproductSelected[key]._id;
            }
            if (existingProductSelected.indexOf(selected._id) === -1) {
                inputAdditionalProductDataHandler(true);
                const newProductSelected = selected;
                formErrorHandler(false);
                productSelectedHandler(newProductSelected);
            } else {
                alert('Please choose other product!');
            }

            backdropClickHandler()
        } else {
            if (formTitle === "customer" || formTitle === "termofpayment") {

                const newFormdata = {
                    ...formdata
                }

                const newElement = {
                    ...newFormdata[formTitle]
                }
                newElement.value = selected._id;

                let validData = validate(newElement, formdata);
                newElement.valid = validData[0];
                newElement.validationMessage = validData[1];

                newFormdata[formTitle] = newElement;

                if (formTitle === "customer") {
                    // const newFormData = globalFormAdd(this.state.formdata, selected.name, 'role')
                    formErrorHandler(false);
                    formdataHandler(newFormdata);
                    selectedCustomerHandler(selected);
                    backdropClickHandler()
                }
                if (formTitle === "termofpayment") {
                    // const newFormData = globalFormAdd(this.state.formdata, selected.name, 'user')
                    formErrorHandler(false);
                    formdataHandler(newFormdata);
                    selectedTermHandler(selected);
                    backdropClickHandler()
                }
            }
        }
    }

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        // myformTitleHandler('');
        filterTextHandler('');
        currentPageHandler(1);
        overlayFormHandler(false)
    }

    const backdropPopupMessage = () => {
        document.body.style.overflow = 'overlay';
        goBackToLists();
    }

    const goBackToLists = () => {
        formErrorHandler(false);
        popupMessageHandler(false);
        filterTextHandler('');
        errorMessageHandler('');
    }

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1);
    }

    const searchForm = (item, title, itemid, commonId) => {
        myformDataHandler(item);
        myformTitleHandler(title);
        overlayFormHandler(prevState => !prevState.overlayForm)
    }

    const goBackToTable = () => {
        const newFormData = resetFields(formdata, 'quotation');
        const newFormAdditional = resetAdditional(additional, 'additional');
        formdataHandler(newFormData);
        additionalHandler(newFormAdditional);
        filterTextHandler('');
        currentPageHandler(1);
        createstatusHandler(false)
        showFormStatus(false)
        resetEditFieldHandler()
        selectednumber(numberThisData)
        productSelectedHandler("")
        // productsHandler([]);
        dateHandler("Select date");
        allproductSelectedHandler([])
        allTotalPriceHandler([]);
        allTotalDiscountHandler([]);
        idchangetopurpleHandler([])
    }

    const resetEditFieldHandler = () => {
        formErrorHandler(false);
        formSuccessHandler(false);
        processHandler(false);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const submitNewAddress = (event) => {
        event.preventDefault();

        let yearNow = new Date().getFullYear().toString();
        let number = `${userdata.code}/QT/${yearNow}/${numberThisData + 1}`;
        let dataToSubmit = generateData(formdata, 'addNewAddressCustomer');
        let formIsValid = isFormValid(formdata, 'addNewAddressCustomer');

        let totalDataToSubmit = {
            ...dataToSubmit,
            quotationid: number,
            subtotal: subtotal,
            discount: discount,
            tax: tax,
            totals: totals,
            grandtotal: grandtotal,
            uid: userdata.id,
            idtopurple: idchangetopurple,
            allrequests: allrequests,
            revision: 0
        }

        if (formIsValid) {
            // console.log(totalDataToSubmit, "<============+++++++++++++++++++++++++++ totalDataToSubmit")
            dispatch(addQuotation(totalDataToSubmit)).then((thisfeedback) => {
                if (thisfeedback.payload.success) {
                    formSuccessHandler(true);
                    setTimeout(() => {
                        dispatch(clearQuotation());
                        formSuccessHandler(false);
                        resetEditFieldHandler();
                        refreshPage();
                    }, 1000)
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(thisfeedback.payload.message);
                }
            })
        } else {
            // console.log("error 3");
            formErrorHandler(true);
            errorMessageHandler('Form not valid');
            processHandler(false);
        }
    }

    const additionalUpdateForm = (element) => {
        const newFormdata = update(element, additional, 'additional');
        formErrorHandler(false);
        additionalHandler(newFormdata);
        processHandler(false);
    }

    const addAdditionalData = (event) => {
        event.preventDefault();

        let existingPriceSelected = [];
        let existingDiscountSelected = [];
        let existingProductRequestedFiltered = [];
        let existingProductRequested = allrequests;
        let quantity = parseInt(additional.quantity.value);
        let discount = parseInt(additional.discount.value);
        let date = formdata.date.value;
        let formIsValid = isFormValid(additional, 'additional');
        let lessthan3weeks = Date.parse(date) < later;

        if (date !== "") {
            if (formIsValid) {
                processHandler(true);
                formErrorHandler(false);
                if (lessthan3weeks) {
                    let cnt = 0;
                    let color = "";
                    let totalquantity = 0;
                    existingProductRequestedFiltered = existingProductRequested.filter((data, index) => Date.parse(data.date) < later);
                    existingProductRequestedFiltered.forEach((item, index) => {
                        const id = item.productid && item.productid._id;
                        if (id.toString() === productSelected._id.toString()) {
                            if (item.valid) {
                                totalquantity = totalquantity + item.qty;
                                cnt = cnt + 1;
                            }
                        }
                    })
                    totalquantity = totalquantity + quantity;
                    let insufficient = totalquantity > productSelected.quantity;

                    // if (productSelected.quantity < quantity) {
                    //     this.setState({
                    //         formError: true,
                    //         errorMessage: `STOCK INSUFFICIENT, SELECT DATE MORE THAN ${moment(this.state.later).format('DD-MMM-YYYY')}`
                    //     })
                    // } else {
                    if (insufficient && cnt === 0) {
                        color = "#FF0000";
                    } else if (insufficient && cnt > 0) {
                        color = "#800080";
                        let newIdChangeToPurple = [...idchangetopurple]
                        newIdChangeToPurple.push(productSelected._id)
                        idchangetopurpleHandler(newIdChangeToPurple)
                    } else {
                        color = "#008000"
                    }

                    if (productSelected.categories === 'formica') {
                        if (discount < userdata.max) {
                            const newAllTotalDiscount =
                            {
                                // totalDiscount: (quantity * discount) // =====> discount amount per item
                                totalDiscount: (((productSelected.price * quantity) * discount) / 100) // =======> percentage discount
                            }

                            const newAllTotalPrice =
                            {
                                totalPrice: (productSelected.price * quantity)
                            }

                            let newProductSelected = { ...productSelected }
                            newProductSelected['quantityrequested'] = quantity;
                            newProductSelected['discount'] = discount;
                            newProductSelected['color'] = color;
                            productSelectedHandler(newProductSelected);

                            let newallProductSelected = [...allproductSelected];
                            newallProductSelected.push(newProductSelected)
                            allproductSelectedHandler(newallProductSelected)

                            let newallTotalPrice = [...allTotalPrice]
                            newallTotalPrice.push(newAllTotalPrice);
                            allTotalPriceHandler(newallTotalPrice)

                            let newallTotalDiscount = [...allTotalDiscount]
                            newallTotalDiscount.push(newAllTotalDiscount);
                            allTotalDiscountHandler(newallTotalDiscount)

                            const newFormProducts = {
                                ...formdata
                            }
                            newFormProducts['products'].value = newallProductSelected;
                            newFormProducts['products'].valid = true;
                            newFormProducts['products'].touched = true;
                            formdataHandler(newFormProducts);
                            formSuccessHandler(true);

                            setTimeout(() => {
                                for (let key in newallTotalPrice) {
                                    existingPriceSelected[key] = newallTotalPrice[key].totalPrice;
                                }

                                for (let key in newallTotalDiscount) {
                                    existingDiscountSelected[key] = newallTotalDiscount[key].totalDiscount;
                                }

                                var subtotal = existingPriceSelected.reduce((a, b) => a + Number(b), 0);
                                var discount = existingDiscountSelected.reduce((a, b) => a + Number(b), 0);
                                var totals = Math.round(subtotal - discount);
                                var tax = Math.round(((totals * taxinvoice) / 100));
                                var grandtotal = (totals + tax);

                                const newFormData = resetAdditional(additional, 'additional');

                                formErrorHandler(false);
                                formSuccessHandler(false);
                                processHandler(false);
                                inputAdditionalProductDataHandler(false);
                                additionalHandler(newFormData);
                                productSelectedHandler("");
                                currentPageHandler(1);
                                subtotalHandler(subtotal);
                                discountHandler(discount);
                                totalsHandler(totals);
                                taxHandler(tax);
                                grandtotalHandler(grandtotal);
                            }, 500)
                        } else {
                            formErrorHandler(true);
                            processHandler(false);
                            additionalerrorMessageHandler(`DISCOUNT NO MORE THAN ${userdata.max}%`)
                        }
                    } else if (productSelected.categories === 'perform') {
                        if (discount < userdata.max2) {

                            const newAllTotalDiscount =
                            {
                                // totalDiscount: (quantity * discount) // =====> discount amount per item
                                totalDiscount: (((productSelected.price * quantity) * discount) / 100) // =======> percentage discount
                            }

                            const newAllTotalPrice =
                            {
                                totalPrice: (productSelected.price * quantity)
                            }
                            let newProductSelected = { ...productSelected }
                            newProductSelected['quantityrequested'] = quantity;
                            newProductSelected['discount'] = discount;
                            newProductSelected['color'] = color;
                            productSelectedHandler(newProductSelected);

                            let newallProductSelected = [...allproductSelected];
                            newallProductSelected.push(newProductSelected)
                            allproductSelectedHandler(newallProductSelected)

                            let newallTotalPrice = [...allTotalPrice]
                            newallTotalPrice.push(newAllTotalPrice);
                            allTotalPriceHandler(newallTotalPrice)

                            let newallTotalDiscount = [...allTotalDiscount]
                            newallTotalDiscount.push(newAllTotalDiscount);
                            allTotalDiscountHandler(newallTotalDiscount)

                            const newFormProducts = {
                                ...formdata
                            }
                            newFormProducts['products'].value = newallProductSelected;
                            newFormProducts['products'].valid = true;
                            newFormProducts['products'].touched = true;

                            formdataHandler(newFormProducts);
                            formSuccessHandler(true);

                            setTimeout(() => {

                                for (let key in newallTotalPrice) {
                                    existingPriceSelected[key] = newallTotalPrice[key].totalPrice;
                                }

                                for (let key in newallTotalDiscount) {
                                    existingDiscountSelected[key] = newallTotalDiscount[key].totalDiscount;
                                }

                                var subtotal = existingPriceSelected.reduce((a, b) => a + Number(b), 0);
                                var discount = existingDiscountSelected.reduce((a, b) => a + Number(b), 0);
                                var totals = Math.round(subtotal - discount);
                                var tax = Math.round(((totals * taxinvoice) / 100));
                                var grandtotal = (totals + tax);

                                const newFormData = resetAdditional(additional, 'additional');

                                formErrorHandler(false);
                                formSuccessHandler(false);
                                processHandler(false);
                                inputAdditionalProductDataHandler(false);
                                additionalHandler(newFormData);
                                productSelectedHandler("");
                                currentPageHandler(1);
                                subtotalHandler(subtotal);
                                discountHandler(discount);
                                totalsHandler(totals);
                                taxHandler(tax);
                                grandtotalHandler(grandtotal);
                            }, 500)
                        } else {
                            formErrorHandler(true);
                            processHandler(false);
                            additionalerrorMessageHandler(`DISCOUNT NO MORE THAN ${userdata.max2}%`)
                        }
                    } else {
                        if (discount < userdata.max3) {
                            const newAllTotalDiscount =
                            {
                                // totalDiscount: (quantity * discount) // =====> discount amount per item
                                totalDiscount: (((productSelected.price * quantity) * discount) / 100) // =======> percentage discount
                            }

                            const newAllTotalPrice =
                            {
                                totalPrice: (productSelected.price * quantity)
                            }
                            let newProductSelected = { ...productSelected }
                            newProductSelected['quantityrequested'] = quantity;
                            newProductSelected['discount'] = discount;
                            newProductSelected['color'] = color;
                            productSelectedHandler(newProductSelected);

                            let newallProductSelected = [...allproductSelected];
                            newallProductSelected.push(newProductSelected)
                            allproductSelectedHandler(newallProductSelected)

                            let newallTotalPrice = [...allTotalPrice]
                            newallTotalPrice.push(newAllTotalPrice);
                            allTotalPriceHandler(newallTotalPrice)

                            let newallTotalDiscount = [...allTotalDiscount]
                            newallTotalDiscount.push(newAllTotalDiscount);
                            allTotalDiscountHandler(newallTotalDiscount)

                            const newFormProducts = {
                                ...formdata
                            }
                            newFormProducts['products'].value = newallProductSelected;
                            newFormProducts['products'].valid = true;
                            newFormProducts['products'].touched = true;

                            formdataHandler(newFormProducts);
                            formSuccessHandler(true);

                            setTimeout(() => {

                                for (let key in newallTotalPrice) {
                                    existingPriceSelected[key] = newallTotalPrice[key].totalPrice;
                                }

                                for (let key in newallTotalDiscount) {
                                    existingDiscountSelected[key] = newallTotalDiscount[key].totalDiscount;
                                }

                                var subtotal = existingPriceSelected.reduce((a, b) => a + Number(b), 0);
                                var discount = existingDiscountSelected.reduce((a, b) => a + Number(b), 0);
                                var totals = Math.round(subtotal - discount);
                                var tax = Math.round(((totals * taxinvoice) / 100));
                                var grandtotal = (totals + tax);

                                const newFormData = resetAdditional(additional, 'additional');

                                formErrorHandler(false);
                                formSuccessHandler(false);
                                processHandler(false);
                                inputAdditionalProductDataHandler(false);
                                additionalHandler(newFormData);
                                productSelectedHandler("");
                                currentPageHandler(1);
                                subtotalHandler(subtotal);
                                discountHandler(discount);
                                totalsHandler(totals);
                                taxHandler(tax);
                                grandtotalHandler(grandtotal);
                            }, 500)
                        } else {
                            formErrorHandler(true);
                            processHandler(false);
                            additionalerrorMessageHandler(`DISCOUNT NO MORE THAN ${userdata.max3}%`)
                        }
                    }
                    // }
                }
                else {
                    let color = "#add8e6"
                    if (productSelected.categories === 'formica') {
                        if (discount < userdata.max) {
                            const newAllTotalDiscount =
                            {
                                // totalDiscount: (quantity * discount) // =====> discount amount per item
                                totalDiscount: (((productSelected.price * quantity) * discount) / 100) // =======> percentage discount
                            }

                            const newAllTotalPrice =
                            {
                                totalPrice: (productSelected.price * quantity)
                            }

                            let newProductSelected = { ...productSelected }
                            newProductSelected['quantityrequested'] = quantity;
                            newProductSelected['discount'] = discount;
                            newProductSelected['color'] = color;
                            productSelectedHandler(newProductSelected);

                            let newallProductSelected = [...allproductSelected];
                            newallProductSelected.push(newProductSelected)
                            allproductSelectedHandler(newallProductSelected)

                            let newallTotalPrice = [...allTotalPrice]
                            newallTotalPrice.push(newAllTotalPrice);
                            allTotalPriceHandler(newallTotalPrice)

                            let newallTotalDiscount = [...allTotalDiscount]
                            newallTotalDiscount.push(newAllTotalDiscount);
                            allTotalDiscountHandler(newallTotalDiscount)

                            const newFormProducts = {
                                ...formdata
                            }
                            newFormProducts['products'].value = newallProductSelected;
                            newFormProducts['products'].valid = true;
                            newFormProducts['products'].touched = true;

                            formdataHandler(newFormProducts);
                            formSuccessHandler(true);

                            setTimeout(() => {

                                for (let key in newallTotalPrice) {
                                    existingPriceSelected[key] = newallTotalPrice[key].totalPrice;
                                }

                                for (let key in newallTotalDiscount) {
                                    existingDiscountSelected[key] = newallTotalDiscount[key].totalDiscount;
                                }

                                var subtotal = existingPriceSelected.reduce((a, b) => a + Number(b), 0);
                                var discount = existingDiscountSelected.reduce((a, b) => a + Number(b), 0);
                                var totals = Math.round(subtotal - discount);
                                var tax = Math.round(((totals * taxinvoice) / 100));
                                var grandtotal = (totals + tax);

                                const newFormData = resetAdditional(additional, 'additional');

                                formErrorHandler(false);
                                formSuccessHandler(false);
                                processHandler(false);
                                inputAdditionalProductDataHandler(false);
                                additionalHandler(newFormData);
                                productSelectedHandler("");
                                currentPageHandler(1);
                                subtotalHandler(subtotal);
                                discountHandler(discount);
                                totalsHandler(totals);
                                taxHandler(tax);
                                grandtotalHandler(grandtotal);
                            }, 500)
                        } else {
                            formErrorHandler(true);
                            processHandler(false);
                            additionalerrorMessageHandler(`DISCOUNT NO MORE THAN ${userdata.max}%`)
                        }
                    } else if (productSelected.categories === 'perform') {
                        if (discount < userdata.max2) {
                            const newAllTotalDiscount =
                            {
                                // totalDiscount: (quantity * discount) // =====> discount amount per item
                                totalDiscount: (((productSelected.price * quantity) * discount) / 100) // =======> percentage discount
                            }

                            const newAllTotalPrice =
                            {
                                totalPrice: (productSelected.price * quantity)
                            }
                            let newProductSelected = { ...productSelected }
                            newProductSelected['quantityrequested'] = quantity;
                            newProductSelected['discount'] = discount;
                            newProductSelected['color'] = color;
                            productSelectedHandler(newProductSelected);

                            let newallProductSelected = [...allproductSelected];
                            newallProductSelected.push(newProductSelected)
                            allproductSelectedHandler(newallProductSelected)

                            let newallTotalPrice = [...allTotalPrice]
                            newallTotalPrice.push(newAllTotalPrice);
                            allTotalPriceHandler(newallTotalPrice)

                            let newallTotalDiscount = [...allTotalDiscount]
                            newallTotalDiscount.push(newAllTotalDiscount);
                            allTotalDiscountHandler(newallTotalDiscount)

                            const newFormProducts = {
                                ...formdata
                            }
                            newFormProducts['products'].value = newallProductSelected;
                            newFormProducts['products'].valid = true;
                            newFormProducts['products'].touched = true;

                            formdataHandler(newFormProducts);
                            formSuccessHandler(true);

                            setTimeout(() => {

                                for (let key in newallTotalPrice) {
                                    existingPriceSelected[key] = newallTotalPrice[key].totalPrice;
                                }

                                for (let key in newallTotalDiscount) {
                                    existingDiscountSelected[key] = newallTotalDiscount[key].totalDiscount;
                                }

                                var subtotal = existingPriceSelected.reduce((a, b) => a + Number(b), 0);
                                var discount = existingDiscountSelected.reduce((a, b) => a + Number(b), 0);
                                var totals = Math.round(subtotal - discount);
                                var tax = Math.round(((totals * taxinvoice) / 100));
                                var grandtotal = (totals + tax);

                                const newFormData = resetAdditional(additional, 'additional');

                                formErrorHandler(false);
                                formSuccessHandler(false);
                                processHandler(false);
                                inputAdditionalProductDataHandler(false);
                                additionalHandler(newFormData);
                                productSelectedHandler("");
                                currentPageHandler(1);
                                subtotalHandler(subtotal);
                                discountHandler(discount);
                                totalsHandler(totals);
                                taxHandler(tax);
                                grandtotalHandler(grandtotal);
                            }, 500)
                        } else {
                            formErrorHandler(true);
                            processHandler(false);
                            additionalerrorMessageHandler(`DISCOUNT NO MORE THAN ${userdata.max}%`)
                        }
                    } else {
                        if (discount < userdata.max3) {
                            const newAllTotalDiscount =
                            {
                                // totalDiscount: (quantity * discount) // =====> discount amount per item
                                totalDiscount: (((productSelected.price * quantity) * discount) / 100) // =======> percentage discount
                            }

                            const newAllTotalPrice =
                            {
                                totalPrice: (productSelected.price * quantity)
                            }

                            let newProductSelected = { ...productSelected }
                            newProductSelected['quantityrequested'] = quantity;
                            newProductSelected['discount'] = discount;
                            newProductSelected['color'] = color;
                            productSelectedHandler(newProductSelected);

                            let newallProductSelected = [...allproductSelected];
                            newallProductSelected.push(newProductSelected)
                            allproductSelectedHandler(newallProductSelected)

                            let newallTotalPrice = [...allTotalPrice]
                            newallTotalPrice.push(newAllTotalPrice);
                            allTotalPriceHandler(newallTotalPrice)

                            let newallTotalDiscount = [...allTotalDiscount]
                            newallTotalDiscount.push(newAllTotalDiscount);
                            allTotalDiscountHandler(newallTotalDiscount)

                            const newFormProducts = {
                                ...formdata
                            }
                            newFormProducts['products'].value = newallProductSelected;
                            newFormProducts['products'].valid = true;
                            newFormProducts['products'].touched = true;

                            formdataHandler(newFormProducts);
                            formSuccessHandler(true);

                            setTimeout(() => {

                                for (let key in newallTotalPrice) {
                                    existingPriceSelected[key] = newallTotalPrice[key].totalPrice;
                                }

                                for (let key in newallTotalDiscount) {
                                    existingDiscountSelected[key] = newallTotalDiscount[key].totalDiscount;
                                }

                                var subtotal = existingPriceSelected.reduce((a, b) => a + Number(b), 0);
                                var discount = existingDiscountSelected.reduce((a, b) => a + Number(b), 0);
                                var totals = Math.round(subtotal - discount);
                                var tax = Math.round(((totals * taxinvoice) / 100));
                                var grandtotal = (totals + tax);

                                const newFormData = resetAdditional(additional, 'additional');

                                formErrorHandler(false);
                                formSuccessHandler(false);
                                processHandler(false);
                                inputAdditionalProductDataHandler(false);
                                additionalHandler(newFormData);
                                productSelectedHandler("");
                                currentPageHandler(1);
                                subtotalHandler(subtotal);
                                discountHandler(discount);
                                totalsHandler(totals);
                                taxHandler(tax);
                                grandtotalHandler(grandtotal);
                            }, 500)
                        } else {
                            formErrorHandler(true);
                            processHandler(false);
                            additionalerrorMessageHandler(`DISCOUNT NO MORE THAN ${userdata.max3}%`)
                        }
                    }
                }

            } else {
                formErrorHandler(true);
                errorMessageHandler("PLEASE FILL QUANTITY & DISCOUNT FORM")
            }
        } else {
            formErrorHandler(true);
            errorMessageHandler("PLEASE SELECT DATE FIRST")
        }
    }

    const onProductDelete = (item, index) => {
        confirmProductDeleteHandler(true);
        itemProductDeleteHandler(item._id);
        indexProductDeleteHandler(index);
    }

    const cancelProductDeleteData = () => {
        confirmProductDeleteHandler(false);
        itemProductDeleteHandler("");
        indexProductDeleteHandler("");
    }

    const confirmProductDeleteData = () => {
        let existingPriceSelected = [];
        let existingDiscountSelected = [];
        let itemProduct = itemProductDelete;
        let indexProduct = indexProductDelete;

        var allproductSelectedCopy = allproductSelected;
        var allTotalPriceCopy = allTotalPrice;
        var allTotalDiscountCopy = allTotalDiscount;
        var allIdChangeToPurple = idchangetopurple;

        allproductSelectedCopy = allproductSelectedCopy.filter((item, index) => {
            return itemProduct !== item._id;
        });

        allIdChangeToPurple = allIdChangeToPurple.filter((item, index) => {
            return itemProduct !== item;
        });

        allTotalPriceCopy = allTotalPriceCopy.filter((item, index) => {
            return indexProduct !== index;
        });

        allTotalDiscountCopy = allTotalDiscountCopy.filter((item, index) => {
            return indexProduct !== index;
        });

        if (allproductSelectedCopy.length === 0 || allproductSelectedCopy.length < 1) {
            // console.log("reset all product formdata")
            const newFormData = resetProducts(formdata, 'products');
            formdataHandler(newFormData);

        } else {
            // console.log("delete one product formdata")

            const newFormProducts = {
                ...formdata
            }
            newFormProducts['products'].value = allproductSelectedCopy;
            newFormProducts['products'].valid = true;
            newFormProducts['products'].touched = true;
            formdataHandler(newFormProducts);
        }

        for (let key in allTotalPriceCopy) {
            existingPriceSelected[key] = allTotalPriceCopy[key].totalPrice;
        }

        for (let key in allTotalDiscountCopy) {
            existingDiscountSelected[key] = allTotalDiscountCopy[key].totalDiscount;
        }

        var subtotal = existingPriceSelected.reduce((a, b) => a + Number(b), 0);
        var discount = existingDiscountSelected.reduce((a, b) => a + Number(b), 0);
        var totals = Math.round(subtotal - discount);
        var tax = Math.round(((totals * taxinvoice) / 100));
        var grandtotal = (totals + tax);

        allproductSelectedHandler(allproductSelectedCopy);
        allTotalPriceHandler(allTotalPriceCopy);
        allTotalDiscountHandler(allTotalDiscountCopy);
        idchangetopurpleHandler(allIdChangeToPurple);
        subtotalHandler(subtotal);
        discountHandler(discount);
        totalsHandler(totals);
        taxHandler(tax);
        grandtotalHandler(grandtotal);
        formErrorHandler(false);
        confirmProductDeleteHandler(false);
        indexProductDeleteHandler("");
    }

    React.useEffect(() => {
        var myconfirmationproducts = [];
        var confirmationproducts = [];

        var offset = (currentPage - 1) * pageSize;
        if (allproductSelected) {
            // console.log(allproductSelected, '<<<<<allproductSelectedallproductSelected')
            confirmationproducts = allproductSelected.map((item, index) => {
                console.log(item, '<<<item')
                return (
                    <li
                        key={index}
                        className="menuItem mb5 p2"
                        onClick={() => onProductDelete(item, index)}
                        style={
                            item.color ?
                                {
                                    backgroundColor: `${item.color}`,
                                    color: `#FFF`
                                }
                                :
                                {
                                    color: `#000`
                                }
                        }
                    >
                        {
                            item.color === `#800080` || item.color === `#008000` ?
                                <div className={`${isMobile ? "menuLink textWhite noBorder h17px p0 fs12" : "menuLink textWhite noBorder h17px p0"}`}>{item.productId} {isMobile ? null : `(${item.quantityrequested} pcs)`}
                                    <div className={`${isMobile ? "iconPosition right10 textGray fs12" : "iconPosition right10 textGray"}`}>Rp. {convertToRupiah(item.price * item.quantityrequested)}</div>
                                </div>
                                :
                                <div className={`${isMobile ? "menuLink textBlack noBorder h17px p0 fs12" : "menuLink textBlack noBorder h17px p0"}`}>{item.productId} {isMobile ? null : `(${item.quantityrequested} pcs)`}
                                    <div className={`${isMobile ? "iconPosition right10 textGray fs12" : "iconPosition right10 textGray"}`}>Rp. {convertToRupiah(item.price * item.quantityrequested)}</div>
                                </div>
                        }
                        {
                            item.color === `#800080` || item.color === `#008000` ?
                                <div className={`${isMobile ? "menuLink textWhite noBorder h17px p0 fs12" : "menuLink textWhite noBorder h17px p0"}`}>{item.discount}%<div className="divider"></div>{isMobile ? `(${item.quantityrequested} pcs)` : convertToRupiah(((item.price * item.quantityrequested) * item.discount) / 100)}
                                    <div className={`${isMobile ? "iconPosition right10 textWhite fw500 fs12" : "iconPosition right10 textWhiteImportant fw500"}`}>Rp. {convertToRupiah((item.price * item.quantityrequested) - (((item.price * item.quantityrequested) * item.discount) / 100))}</div>
                                </div>
                                :
                                <div className={`${isMobile ? "menuLink textBlack noBorder h17px p0 fs12" : "menuLink textBlack noBorder h17px p0"}`}>{item.discount}%<div className="divider"></div>{isMobile ? `(${item.quantityrequested} pcs)` : convertToRupiah(((item.price * item.quantityrequested) * item.discount) / 100)}
                                    <div className={`${isMobile ? "iconPosition right10 textBlack fw500 fs12" : "iconPosition right10 textBlack fw500"}`}>Rp. {convertToRupiah((item.price * item.quantityrequested) - (((item.price * item.quantityrequested) * item.discount) / 100))}</div>
                                </div>
                        }

                    </li>
                )
            })
            var semuadata = [...confirmationproducts];
            myconfirmationproducts = semuadata.slice(offset, offset + pageSize);
            dataCountHandler(confirmationproducts.length);
            mydataHandler(myconfirmationproducts);
        }

    }, [filterText, currentPage, pageSize, allproductSelected, isMobile])

    const className = `${show ? 'cardBody' : 'displayNone'}`;
    var chooseproducts = allproductSelected.length > 0 ? "Add another Product" : "Choose Product";
    return (
        <div className={className}>
            <SearchForm
                // ref={'SearchForm'}
                show={overlayForm}
                handleChange={handleChange}
                totalData={myformData}
                filterText={filterText}
                pageLimit={pageSize}
                pageChanged={pageChanged}
                currentPage={currentPage}
                title={myformTitle}
                selectedItems={selectedItems}
            />
            <PopupMessageNoAdd
                show={popupMessage}
                overlayFormStatus={backdropPopupMessage}
                errorMessage={errorMessage}
            />
            {
                overlayForm &&
                <BackDrop click={backdropClickHandler} />
            }

            {
                popupMessage &&
                <DoubleBackDrop click={backdropPopupMessage} />
            }

            {
                <div className="row">
                    <div className="col-md-4 col-xs-12">
                        <div className="formGroup mb20">
                            <label className="controlLabel">Title</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaUser className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'title'}
                                    formdata={formdata.title}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                        <div className="formGroup mb20">
                            <label className="controlLabel">PIC Name</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaUser className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'picname'}
                                    formdata={formdata.picname}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                        <div className="formGroup mb20">
                            <label className="controlLabel">PIC Phone</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaPhone className="icon agraicon" />
                                </div>
                                <FormField
                                    id={'picphone'}
                                    formdata={formdata.picphone}
                                    change={(element) => updateForm(element)}
                                    myclass={'form-control'}
                                />
                            </div>
                        </div>
                        <div className="formGroup mb20">
                            <label className="controlLabel">Customer</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaUserCircle className="icon agraicon" />
                                </div>
                                <div
                                    id={'customer'}
                                    onClick={() => searchForm(formdata.customer.config.options, "customer")}
                                    className={`${formdata.customer.config.options && formdata.customer.config.options.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                                >
                                    {
                                        selectedCustomer ? selectedCustomer.name : formdata.customer.config.placeholder
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="formGroup mb20">
                            <label className="controlLabel">Term of payment</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaHandshake className="icon agraicon" />
                                </div>
                                <div
                                    id={'termofpayment'}
                                    onClick={() => searchForm(formdata.termofpayment.config.options, "termofpayment")}
                                    className={`${formdata.termofpayment.config.options && formdata.termofpayment.config.options.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                                >
                                    {
                                        selectedTerm ? (selectedTerm.name).substring(0, 35) : formdata.termofpayment.config.placeholder
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className="formGroup mb20">
                            <label className="controlLabel">Date (Shipping date {">"} {moment(later).format('DD-MMM-YYYY')})</label>
                            <div className="formWrapper">
                                <div className="iconPosition">
                                    <FaCalendarCheck className="icon agraicon" />
                                </div>
                                <div className="formBlock">
                                    <DatePicker
                                        dateFormat="DD-MM-YYYY"
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        value={date ? date : "Select date"}
                                        onChange={(date) => onDateChange(date)}
                                    // myclass={'form-control'}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="formGroup mb20">
                            <div className="formGroup mb20">
                                <label className="controlLabel">Add Product</label>
                                <div className="formWrapper">
                                    <div className="iconPosition">
                                        <FaListAlt className="icon agraicon" />
                                    </div>
                                    <div
                                        id={'products'}
                                        onClick={() => searchForm(formdata.products.config.options, "products")}
                                        className={`${allproducts && allproducts.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                                    >
                                        {
                                            allproducts ? chooseproducts : "Now Loading..."
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                productSelected && inputAdditionalProductData &&
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <div className="formGroup mb20">
                                            <label className="controlLabel">
                                                {
                                                    productSelected.name
                                                }
                                            </label>
                                            <div className="formWrapper">
                                                <div className="iconPosition">
                                                    <FaMapMarker className="icon agraicon" />
                                                </div>
                                                <FormField
                                                    id={'quantity'}
                                                    formdata={additional.quantity}
                                                    change={(element) => additionalUpdateForm(element)}
                                                    myclass={'form-control'}
                                                />
                                            </div>
                                        </div>
                                        <div className="formGroup mb20">
                                            <div className="formWrapper">
                                                <div className="iconPosition">
                                                    <FaEnvelope className="icon agraicon" />
                                                </div>
                                                <FormField
                                                    id={'discount'}
                                                    formdata={additional.discount}
                                                    change={(element) => additionalUpdateForm(element)}
                                                    myclass={'form-control'}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-xs-12 noPadding">
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <div>
                                                    {
                                                        formSuccess ?
                                                            <div className="successLabel">
                                                                SUCCESS, NOW LOADING...
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        formError ?
                                                            <div className="errorLabel">
                                                                {additionalerrorMessage}
                                                            </div>
                                                            : null

                                                    }
                                                </div>
                                                <div className="mt40">
                                                    <div onClick={(event) => addAdditionalData(event)} className={`${process ? "agrapanaBtn agrapanaBtnPrimary divBtn disabled2" : "agrapanaBtn agrapanaBtnPrimary divBtn"}`}>
                                                        Next
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className="formGroup mb20">
                            {
                                allproductSelected.length > 0 ?
                                    <div>
                                        <div className="minHeight500px">
                                            <div className="row">
                                                {/* <div id="divToPrint"> */}
                                                <div className="col-md-12 col-xs-12">
                                                    <div className="formGroup mb10">
                                                        <label className="controlLabel mb10">Product List</label>
                                                        <ul className="menu invoiceForm mb0 pb5px borderbottom">
                                                            {
                                                                mydata
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-xs-12">
                                                    <div className="menuLink textBlack noBorder h20px p0">Subtotal
                                                        <div className="iconPosition right10 textBlack fw500">Rp. {subtotal ? convertToRupiah(subtotal) : "-"}</div>
                                                    </div>
                                                    <div className="menuLink textBlack noBorder h20px p0">Discount
                                                        <div className="iconPosition right10 textBlack fw500">Rp. {discount ? convertToRupiah(discount) : "-"}</div>
                                                    </div>
                                                    <div className="menuLink textBlack noBorder h20px p0">Total
                                                        <div className="iconPosition right10 textBlack fw500">Rp. {totals ? convertToRupiah(totals) : "-"}</div>
                                                    </div>
                                                    <div className="menuLink textBlack noBorder h20px p0">Tax
                                                        <div className="iconPosition right10 textBlack fw500">Rp. {tax ? convertToRupiah(tax) : "-"}</div>
                                                    </div>
                                                    <div className="menuLink textBlack noBorder h20px p0">Grand Total
                                                        <div className="iconPosition right10 textBlack fw500">Rp. {grandtotal ? convertToRupiah(grandtotal) : "-"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <nav className="mt10">
                                            <Pagination Size={dataCount} pageLimit={pageSize} onPageChanged={pageChanged} currentPage={currentPage} width={isMobile} />
                                        </nav>
                                        <div className="col-md-12 col-xs-12 textCenter">
                                            <div className={`${isMobile ? "pagination justifyContentCenter mb30 posInherit" : "pagination justifyContentCenter mb10"}`}>{currentPage}/{parseInt((dataCount / pageSize) + ((dataCount % pageSize) > 0 ? 1 : 0))} Pages</div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>


                    <div className={`${isMobile ? "col-md-12 col-xs-12" : "col-md-12 col-xs-12 posAbsolute confirmInvoiceButton"}`}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <div className="mb20">
                                {
                                    formSuccess ?
                                        <div className="successLabel">
                                            SUCCESS, NOW LOADING...
                                        </div>
                                        : null
                                }
                                {
                                    formError ?
                                        <div className="errorLabel">
                                            {errorMessage}
                                        </div>
                                        : null

                                }
                            </div>
                            <div className="inlineFlex">
                                <div
                                    className="agrapanaBtn redBackground p8"
                                    title=""
                                    onClick={() => goBackToTable()}
                                >
                                    <FaChevronLeft className="icon agraicon w18px" />
                                </div>
                                {
                                    confirmProductDelete ? null
                                        :
                                        <div className="doubleButton">
                                            <button onClick={(event) => submitNewAddress(event)} className={`${process ? "agrapanaBtn agrapanaBtnPrimary divBtn ml10 disabled2" : "agrapanaBtn agrapanaBtnPrimary divBtn ml10"}`}>
                                                Submit
                                            </button>
                                        </div>
                                }

                                {/* {
                                    termofpayments !== "Choose Term of Payment" && rekenings !== "Choose Rekening" ?
                                        <div className="doubleButton">
                                            <button onClick={(event) => this.submitForm(event)} className={`${this.state.process ? "agrapanaBtn agrapanaBtnPrimary divBtn ml10 disabled" : "agrapanaBtn agrapanaBtnPrimary divBtn ml10"}`}>
                                                Submit
                                            </button>
                                        </div>
                                        : null
                                } */}
                                {
                                    confirmProductDelete ?
                                        <div className="dinFlex">
                                            <div
                                                className="agrapanaBtn redBackground p8 ml10"
                                                title=""
                                                onClick={() => confirmProductDeleteData()}
                                            >
                                                <FaCheck className="icon agraicon w18px" /> Delete
                                            </div>
                                            <div
                                                className="agrapanaBtn redBackground p8 ml10"
                                                title=""
                                                onClick={() => cancelProductDeleteData()}
                                            >
                                                <FaTimes className="icon agraicon w18px" /> Cancel
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}

export default QuotationForm;