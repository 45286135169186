import React from 'react';

const PopupMessageNoAdd = (props) => {
    const className = `doubleoverlayForm ${props.show ? `open ${props.additionalClass ? props.additionalClass : null}` : ''}`;

    return (
        <div className={className}>
            <h4 className="cardTitle">
                {
                    props.errorMessage ?
                        "SORRY" : null
                }

            </h4>
            <div className="cardBody" style={{
                minHeight: 200
            }}>
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        {
                            props.errorMessage ?
                                <span>{props.errorMessage}<br /><br />Please contact administrator</span> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopupMessageNoAdd;