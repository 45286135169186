import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotAuthorize = () => {

    const navigate = useNavigate();

    const gotoDashboard = () => {
        navigate("/admin/dashboard")
    }

    return (
        <div
            className="row wFullscreen hFullscreen noMargin pt10p"
            style={{
                backgroundColor: '#fafafa'
            }}
        >
            <div className="col-agmd-12 col-xs-12">
                <div className="mxAuto myCard cardTransparent textCenter">
                    <h1
                        className="lh1 mb50"
                        style={{
                            fontSize: 50
                        }}
                    >FORBIDDEN</h1>
                    {/* <hr className="w30px"></hr> */}
                    <h3 className="textUppercase">Contact Administrator for further action</h3>
                    <p className="lead">Please go back to your dashboard, to continue using this app. </p>
                    <div
                        className="col-agmd-12 col-xs-12"
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            style={{
                                padding: 5,
                                backgroundColor: '#213e96',
                                width: 200,
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#fff',
                                borderRadius: 8,
                                cursor: 'pointer'
                            }}
                            onClick={() => gotoDashboard()}
                        >
                            Click here to go to Dashboard
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );

}

export default NotAuthorize;