import React from 'react';
import { Parallax } from 'react-parallax';

const ParallaxPage = () => {
    return (
        <div>
            <Parallax bgImage={require('../../../assets/formica4.jpg')} strength={500}>
                <div className="container quote">
                    <h2 className="parallaxquote">Design for today with forever in mind.</h2>
                    <span className="quoteauthor">- FORMICA&reg;</span>
                </div>
            </Parallax>
        </div>
    );
};

export default ParallaxPage;