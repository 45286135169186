import React from 'react';

const Lists = (props) => {

    const [width, widthHandler] = React.useState(window.innerWidth);
    const isMobile = width <= 767.98;

    let hiddenNumber = isMobile ? 15 : 24;

    function fn(text, count) {
        // return text.slice(0, count);
        return text.slice(0, count) + (text.length > count ? "..." : "");
    }

    React.useEffect(() => {

        function handleWindowSizeChange() {
            widthHandler(window.innerWidth);
        };

        // Bind the event listener
        document.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <li className="menuItem">
            <div onClick={() => props.selectedItems(props.items)} className="menuLink textBlack">
                {
                    props.title === 'products' ?
                    `${props.items.productId} - ${fn(props.items.name, hiddenNumber)} (${props.items.quantity})`
                    : props.items.name
                }
            </div>
        </li>
    );
}

export default Lists;