import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

///////////////////////////////////////////////////////////
////////// Lumibusiness Administrator Login Page //////////
///////////////////////////////////////////////////////////
import BusinessAuth from './admin/layout/generalAuth';

///////////////////////////////////////////////////////////
///////// Lumibusiness Main Administrator Page ////////////
///////////////////////////////////////////////////////////
import MainAdministrator from './admin/layout/protected';

///////////////////////////////////////////////////////////
//////////////// Lumibusiness Website Page ////////////////
///////////////////////////////////////////////////////////
import Website from './website/layout/public';

const MyRoutes = () => {
    return (
        <Router>
            <Routes>
                {/***** Lumibusiness General Login Page *****/}
                <Route path="auth/*" element={<BusinessAuth />} />

                {/* ******** Lumibusiness Main Administrator Page *********/}
                <Route path="admin/*" element={<MainAdministrator />} />

                {/********* Lumibusiness Website Page *********/}
                <Route path="/*" element={<Website />} />
                <Route
                    path="404"
                    element={
                        <main style={{ padding: "1rem" }}>
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
                <Route
                    path="*"
                    element={<Navigate replace to="/404" />}
                />
            </Routes>
        </Router>
    );
}

export default MyRoutes;