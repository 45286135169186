import React from 'react';
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import MainPage from '../hoc/mainpage';

import MainDashboard from '../components/maindashboard';
import MainCustomers from '../components/customers';
import MainQuotations from '../components/quotations';
import MainUsers from '../components/users';
import MainProducts from '../components/products';
import MainTerms from '../components/terms';
import MainRoles from '../components/roles';
import MainSettings from '../components/settings';
import MainNotauthorize from '../components/notauthorize';
import NoMatch from '../components/nomatch';

const MainLayout = () => (
    <Routes>
        <Route element={<MainPage />}>
            <Route path="dashboard" element={<MainDashboard />} />
            <Route path="customers" element={<MainCustomers />} />
            <Route path="quotations" element={<MainQuotations />} />
            <Route path="terms" element={<MainTerms />} />
            <Route path="roles" element={<MainRoles />} />
            <Route path="users" element={<MainUsers />} />
            <Route path="products" element={<MainProducts />} />
            <Route path="settings" element={<MainSettings />} />
            <Route path="notauthorize" element={<MainNotauthorize />} />
        </Route>
        <Route
            path="404"
            element={<NoMatch />}
        />
        <Route
            path="*"
            element={<Navigate replace to="/admin/404" />}
        />
    </Routes>
);

export default MainLayout;