import React, { Component } from 'react';

import { Element } from 'react-scroll';

// import { connect } from 'react-redux';

// import { loading } from '../../reducersactions/actions/ecommerce/user_actions';

import Header from '../components/headerfooter/header';
import Featured from '../components/featured';
import Aboutus from '../components/home';
import ParallaxPage from '../components/parallax';
import Footer from '../components/headerfooter/footer';
import Associate from '../components/associate';
import Contact from '../components/getintouch';
import Location from '../components/location';
import SideDrawer from '../components/headerfooter/sidedrawer';
import BackDrop from '../components/headerfooter/backdrop';

class Layout extends Component {

    state = {
        sideDrawerOpen: false,
        width: window.innerWidth,
    };

    // componentDidMount() {
    //     this.props.dispatch(loading());
    // }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    drawerToggleClickHandler = () => {
        this.setState((prevState) => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen };
        });
    };

    backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        this.setState({ sideDrawerOpen: false })
    }

    render() {
        const {
            width,
            sideDrawerOpen
        } = this.state;
        const isMobile = width <= 767.98;
        const isTablet = width >= 767.99 && width <= 1025.98;
        const isMoreThanLaptop = width >= 1025.99;
        return (
            <div style={{ height: '100%' }}>
                <Header
                    drawerClickHandler={this.drawerToggleClickHandler}
                    isMobile={isMobile}
                />
                <SideDrawer show={sideDrawerOpen} click={this.backdropClickHandler} />
                <Element name="home">
                    <Featured isMobile={isMobile} isTablet={isTablet} />
                </Element>

                <Element name="about">
                    <Aboutus isMobile={isMobile} isTablet={isTablet} />
                </Element>

                <ParallaxPage />
                <Element name="contact">
                    <Contact isMobile={isMobile} />
                </Element>

                <Location />
                <Associate isMobile={isMobile} isTablet={isTablet}/>
                <Footer />
                {
                    sideDrawerOpen &&
                    <BackDrop click={this.backdropClickHandler} />
                }
                {/* {
                    this.props.isLoading ?
                        null
                    :
                        <div>
                            <Header 
                                drawerClickHandler={this.drawerToggleClickHandler} 
                                notificationClickHandler={this.notificationDrawerToggleClickHandler} 
                            />
                            <SideDrawer show={sideDrawerOpen} click={this.backdropClickHandler} />
                            {
                                notificationDrawerOpen ? 
                                    <NotificationDrawer show={notificationDrawerOpen} click={this.notificationBackdropClickHandler} />
                                : null
                            }
                            {
                                sideDrawerOpen &&
                                <BackDrop click={this.backdropClickHandler} />
                            }
                            {
                                notificationDrawerOpen &&
                                <NotificationsBackdrop click={this.notificationBackdropClickHandler} />
                            }
                        </div>
                } */}
                {/* <div className="page_container">
                    {this.props.children}
                </div> */}
            </div>
        );
    }
}

// const mapStateToProps = ({ loading: { isLoading } }) => ({
//     isLoading
// })

// export default connect(mapStateToProps, {})(Layout);

export default Layout;