import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import MainLayout from '../../hoc/mainlayout';
import ProductTable from './productTable';

import { getProducts } from '../../../reduceractions/actions/stock/product_actions';

const MainProducts = () => {

    const userdata = useSelector(state => state.user.userData);

    const dispatch = useDispatch();

    const [width, widthHandler] = React.useState(window.innerWidth);
    const isMobile = width <= 767.98;

    const [height, heightHandler] = React.useState(window.innerWidth);

    const [alldata, alldataHandler] = React.useState([]);
    const [createstatus, createstatusHandler] = React.useState(false);
    const [uploadstatus, uploadstatusHandler] = React.useState(false);

    React.useEffect(() => {

        function handleWindowSizeChange() {
            widthHandler(window.innerWidth);
            heightHandler(window.innerHeight);
        };

        // Bind the event listener
        document.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    })

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const getproducts = await dispatch(getProducts());
                if (getproducts.payload.success) {
                    alldataHandler(getproducts.payload.products);
                } else {
                    alldataHandler([])
                }
            }
            fetchData()

        }
        return () => {
            mounted = false;
        };
    }, [dispatch])

    const onRefresh = () => {
        dispatch(getProducts()).then(response => {
            if (response.payload.success) {
                alldataHandler(response.payload.products);
            } else {
                alldataHandler([])
            }
        })
    }

    return (
        <MainLayout>
            <div className="masterContainer">
                <div className="masterContent">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${isMobile ? "card" : "card minHeightProduct"}`}>
                                <h4 className="cardTitle"><strong>Product</strong>
                                    {
                                        createstatus ? <span> Add new product </span>
                                            : uploadstatus ? <span> Upload </span>
                                                : <span> List</span>
                                    }
                                </h4>
                                <ProductTable
                                    alldata={alldata}
                                    handleRefresh={onRefresh}
                                    tableName="productTable"
                                    isMobile={isMobile}
                                    height={height}
                                    createstatus={createstatus}
                                    createstatusHandler={createstatusHandler}
                                    uploadstatus={uploadstatus}
                                    uploadstatusHandler={uploadstatusHandler}
                                    userdata={userdata}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default MainProducts;