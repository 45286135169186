import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../../../reduceractions/actions/admin/user_actions';

import { FaHome, FaUser, FaListUl, FaBoxes, FaUsers, FaBuffer, FaLock } from 'react-icons/fa'

const SideDrawer = (props) => {

    const userdata = useSelector(state => state.user.userData);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const publicnav = [
        {
            name: 'Dashboard',
            linkTo: '/admin/dashboard',
            public: true,
            faicons: <FaHome className="icon" />
        },
        {
            name: 'Customers',
            linkTo: '/admin/customers',
            public: true,
            faicons: <FaUser className="icon" />
        },
        {
            name: 'Quotations',
            linkTo: '/admin/quotations',
            public: true,
            faicons: <FaListUl className="icon" />
        },
        {
            name: 'Products',
            linkTo: '/admin/products',
            public: true,
            faicons: <FaBoxes className="icon" />
        },
    ]

    const managerowner = [
        {
            name: 'Users',
            linkTo: '/admin/users',
            faicons: <FaUsers className="icon agraicon w18px mr20" />
        },
        {
            name: 'Terms',
            linkTo: '/admin/terms',
            faicons: <FaBuffer className="icon agraicon w18px mr20" />
        }
    ]

    const webmasteronly = [
        {
            name: 'Roles',
            linkTo: '/admin/roles',
            faicons: <FaLock className="icon agraicon w18px mr20" />
        }
    ]

    const logoutHandler = () => {
        dispatch(logoutUser()).then(response => {
            if (response.payload.success) {
                // console.log(response.payload);
                props.click()
                navigate('/');
            }
        })
    }

    const publicLink = (items) => (
        userdata && userdata.isAuth ?
            items.map((item, i) => (
                <li key={i}>
                    <NavLink
                        to={item.linkTo}
                        className={(navData) => navData.isActive ? "active" : ""}
                        onClick={() => props.click()}
                        end
                    >
                        <div className="itemContent">
                            <div className="itemIcon">
                                {item.faicons}
                            </div>
                            <div className="itemInner">
                                <span className="title">{item.name}</span>
                            </div>
                        </div>
                    </NavLink>
                </li>
            ))
            : null

    )

    const className = `sideDrawer ${props.show ? 'open' : ''}`;

    return (
        <nav className={className}>
            <div className="sideDrawerLogo">
                <img className="logo_img" src={window.location.origin + "/assets/images/lumisoft_side_white.png"} alt="" />
            </div>

            <ul>
                <li className="sidenavTitle">Main Page</li>
                {publicLink(publicnav)}
                {
                    userdata && userdata.masteradmin === 1 ?
                        <li className="sidenavTitle">Restricted Link</li>
                        : null
                }
                {
                    userdata && userdata.masteradmin === 1 ?
                        publicLink(managerowner)
                        : null
                }
                {
                    userdata && userdata.masteradmin === 1 && userdata.email === 'emailnaxxxx@gmail.com' ?
                        publicLink(webmasteronly)
                        : null
                }
            </ul>
        </nav >
    );
}

export default SideDrawer;