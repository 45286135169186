import React from 'react';
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import AuthLayout from '../hoc/authLayout';
// import Auth from '../hoc/auth';

import AuthLogin from '../components/login';
import AuthLogout from '../components/login/logout';
import NoMatch from '../components/nomatch';

const AuthLoginLayout = () => {
    // console.log(props)
    return (
        <Routes>
            <Route element={<AuthLayout />}>
                <Route path="logout" element={<AuthLogout />} />
                <Route index element={<AuthLogin />} />
            </Route>
            <Route
                path="404"
                element={<NoMatch />}
            />
            <Route
                path="*"
                element={<Navigate replace to="/auth/404" />}
            />
        </Routes>
    );
};

export default AuthLoginLayout;