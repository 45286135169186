import React from 'react';

const LocationPage = () => {
    return (
        <div style={{ position: 'relative' }}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.1854076194795!2d106.67525481483443!3d-6.370047195390521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e5cb5da03b29%3A0xc09f9510dd59e206!2sPT.%20Wingley%20Setia%20Jaya!5e0!3m2!1sen!2sid!4v1573282653305!5m2!1sen!2sid"
                width="100%"
                height="500px"
                frameBorder="0"
                // style="border:0;"
                allowFullScreen
            ></iframe>

            <div className="locationtag">
                <div>LOCATION</div>
            </div>
        </div>
    );
};

export default LocationPage;