import React from 'react';

import {
    useSelector,
    useDispatch
} from 'react-redux';

import {
    Outlet,
    useNavigate
} from "react-router-dom";

import { auth, getUsers, loading } from '../../reduceractions/actions/admin/user_actions';
import { getCustomers } from '../../reduceractions/actions/admin/customer_actions';
import {
    getProvince,
    getCity
} from '../../reduceractions/actions/admin/ongkir_actions';
import {
    getSetting
} from '../../reduceractions/actions/general/setting_action';

import Loadingscreen from '../../utils/loadingscreen';

import Header from '../components/headerfooter/header';
import SideDrawer from '../components/headerfooter/sidedrawer';
import DashboardBackDrop from '../components/headerfooter/dashboardbackdrop';
import { getProducts } from '../../reduceractions/actions/stock/product_actions';
import { getTerms } from '../../reduceractions/actions/admin/termofpayment_actions';
import { getQuotationHistory, getQuotations, getRequests } from '../../reduceractions/actions/general/quotation_actions';
import { getRoles } from '../../reduceractions/actions/general/role_actions';

const MainLayout = () => {

    const loadingpage = useSelector(state => state.loading.isLoading);
    const userdata = useSelector(state => state.user.userData);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [sideDrawerOpen, sideDrawerOpenHandler] = React.useState(false);

    React.useEffect(() => {
        let mounted = true;
        dispatch(loading(true))
        if (mounted) {
            const fetchUser = async () => {
                return dispatch(auth())
            }

            fetchUser().then(async (response) => {
                let user = response.payload;
                if (!user.isAuth) {
                    navigate('/auth')
                } else {
                    let URLs = [
                        dispatch(getSetting()),
                        dispatch(getCustomers()),
                        dispatch(getProvince()),
                        dispatch(getCity()),
                        dispatch(getProducts()),
                        dispatch(getTerms()),
                        dispatch(getQuotationHistory()),
                        dispatch(getQuotations()),
                        dispatch(getRequests()),
                        dispatch(getUsers()),
                        dispatch(getRoles())
                    ]
                    const fetchData = async (URLs) => {
                        const promise = await Promise.all(URLs.map(fetchBulkData));
                        const allEqual = promise.every((val) => val.success === true);
                        return allEqual;
                    }
                    fetchData(URLs).then(res => {
                        if (res) {
                            dispatch(loading(false));
                        }
                    })
                }
            })
        }

        return () => {
            mounted = false;
        };
    }, [dispatch, navigate])

    async function fetchBulkData(URL) {
        return URL.then(function (response) {
            // console.log(response, '<<<response')
            if (response.payload.success) {
                return response.payload;
            } else {
                let failedResponse = { success: false };
                return failedResponse
            }
        }).catch(function (error) {
            return { success: false, error: error };
        });
    }

    const drawerToggleClickHandler = () => {
        sideDrawerOpenHandler(prevState => !prevState.sideDrawerOpen)
    };

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        sideDrawerOpenHandler(false)
    }

    if (loadingpage) {
        return (
            <Loadingscreen />
        )
    } else {
        return (
            <div style={{ height: '100%' }}>
                {
                    userdata ?
                        <div>
                            <Header drawerClickHandler={drawerToggleClickHandler} />
                            <SideDrawer show={sideDrawerOpen} click={backdropClickHandler} />
                            {
                                sideDrawerOpen &&
                                <DashboardBackDrop click={backdropClickHandler} />
                            }
                        </div>
                        : null
                }
                {
                    userdata ?
                        <div className="page_container" style={{ paddingTop: 56 }}>
                            <Outlet />
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default MainLayout;