import {
  RESOURCE_IS_LOADING
} from '../actions/general/types';

const initialState = {
  isLoading: false
}

export default function LoadingState(state = initialState, action) {
  switch (action.type) {
    case RESOURCE_IS_LOADING:
      return {
        isLoading: action.status
      }
    default:
      return state;
  }
}