import axios from 'axios';
import {
    GET_PRODUCTS,
    ADD_PRODUCT,
    CLEAR_PRODUCT,
    UPDATE_PRODUCT,
    UPDATE_ALL_PRODUCT,
    UPDATE_PRODUCT_SINGLE,
    CLEAR_UPDATE_PRODUCT,
    DELETE_PRODUCT
} from './types';

import { PRODUCT_SERVER } from '../../misc';

export function getProducts(){
    const request = axios.get(`${PRODUCT_SERVER}/products`)
    .then(response => response.data);

    return {
        type: GET_PRODUCTS,
        payload: request
    }
}

export function uploadAllProducts(alldataToSubmit){
    const request = axios.post(`${PRODUCT_SERVER}/allproducts`,alldataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_ALL_PRODUCT,
        payload: request
    }
}

export function addProduct(dataToSubmit){
    const request = axios.post(`${PRODUCT_SERVER}/product`,dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_PRODUCT,
        payload: request
    }
}

export function clearProduct(){
    return {
        type: CLEAR_PRODUCT,
        payload: ''
    }
}

export function updateProduct(dataToSubmit){
    const request = axios.post(`${PRODUCT_SERVER}/update`,dataToSubmit)
        .then(response => response.data);
        // .then(response => {
        //     return response.data
        // });

    return {
        type: UPDATE_PRODUCT,
        payload: request
    }
}

export function updateSingle(dataToSubmit){
    const request = axios.post(`${PRODUCT_SERVER}/updatesingle`,dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_PRODUCT_SINGLE,
        payload: request
    }
}

export function clearUpdateProduct(){
    return {
        type: CLEAR_UPDATE_PRODUCT,
        payload: ''
    }
}

export function deleteProduct(id){
    const request = axios.delete(`${PRODUCT_SERVER}/delete/${id}`)
        .then(response => response.data);
    
    return {
        type: DELETE_PRODUCT,
        payload: request
    }
}