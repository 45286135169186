import React from 'react';

import {
    useSelector,
    useDispatch
} from 'react-redux';

import {
    Outlet,
    useNavigate
} from "react-router-dom";

import { auth, loading } from '../../reduceractions/actions/admin/user_actions';
import Loadingscreen from '../../utils/loadingscreen';

const AuthLayout = () => {

    const loadingpage = useSelector(state => state.loading.isLoading);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        let mounted = true;
        dispatch(loading(true))
        const fetchData = async () => {
            if (mounted) {
                return dispatch(auth())
            }

        }
        fetchData().then(async (response) => {
            let user = response.payload;
            if (user.isAuth) {
                navigate('/admin/dashboard')
            } else {
                dispatch(loading(false));
            }
        })

        return () => {
            mounted = false;
        };
    }, [dispatch, navigate])

    if (loadingpage) {
        return (
            <Loadingscreen />
        )
    } else {
        return (
            <div>
                <Outlet />
            </div>
        );
    }
}


export default AuthLayout;