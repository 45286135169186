import React from 'react';
// import { withRouter } from 'react-router-dom';

import MainLayout from '../../hoc/mainlayout';
import BackDrop from '../headerfooter/backdrop';
import moment from 'moment';

import { useSelector } from 'react-redux';

import { FaBars } from 'react-icons/fa'

const MainDashboard = () => {

    const userdata = useSelector(state => state.user.userData);
    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings);
    const customers = useSelector(state => state.customers.customers && state.customers.customers.customers);
    const quotations = useSelector(state => state.quotation.quotations && state.quotation.quotations.quotations);

    const [taxinvoice, taxinvoiceHandler] = React.useState(11);
    const [later, laterHandler] = React.useState();
    const [name, nameHandler] = React.useState('');
    const [isOpen, isOpenHandler] = React.useState(false);
    const [width, widthHandler] = React.useState(window.innerWidth);

    const [allcustomers, allcustomersHandler] = React.useState([]);
    const [mycustomers, mycustomersHandler] = React.useState([]);

    const [allquotations, allquotationsHandler] = React.useState([]);
    const [myquotations, myquotationsHandler] = React.useState([]);

    let isLessThanTablet = width < 1025;

    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (userdata && customers && customers.length > 0) {
                let mycustomers = [];
                customers.forEach((item, index) => {
                    if (item.uid._id.toString() === userdata.id.toString()) {
                        mycustomers.push(item)
                    }
                })
                allcustomersHandler(customers)
                mycustomersHandler(mycustomers)
            }
            if (userdata && quotations && quotations.length > 0) {
                let myquotations = [];
                quotations.forEach((item, index) => {
                    if (item.uid._id.toString() === userdata.id.toString()) {
                        myquotations.push(item)
                    }
                })
                allquotationsHandler(quotations)
                myquotationsHandler(myquotations)
            }
        }
        return () => {
            mounted = false;
        };
    }, [customers, userdata, quotations])

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    taxinvoiceHandler(selected.currenttax);
                    let now = new Date();
                    let laterdate = selected.laterdate;
                    now.setDate(now.getDate() + laterdate);
                    laterHandler(now);
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    React.useEffect(() => {
        if (userdata) {
            nameHandler(userdata && userdata.name);
        }
    }, [userdata])

    React.useEffect(() => {

        function handleWindowSizeChange() {
            widthHandler(window.innerWidth);
        };

        // Bind the event listener
        document.addEventListener("resize", handleWindowSizeChange);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const drawerToggleClickHandler = () => {
        isOpenHandler(prevState => !prevState.isOpen)
    };

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        isOpenHandler(false)
    }
    
    return (
        <MainLayout>
            {
                isOpen &&
                <BackDrop click={backdropClickHandler} />
            }
            <div className="masterDashboard simple rightsidebar">
                <div
                    style={{
                        padding: 30,
                        width: '100%'
                    }}
                >
                    <div
                        className="center ps"
                        style={{
                            backgroundColor: '#ffffff',
                            height: '100%',
                            borderRadius: '0.25rem'
                        }}
                    >
                        <div className="headerDashboard accent p25">
                            <div className="headerDashboardTitle">
                                <span className="headerDashboardName">Welcome, {name}!</span>
                                {
                                    isLessThanTablet ?
                                        <button
                                            className="toggle_button textWhite mt30"
                                            onClick={() => drawerToggleClickHandler()}
                                        >
                                            <FaBars className="icon" />
                                        </button>
                                        : null
                                }
                            </div>
                        </div>
                        <div style={{
                            padding: 30
                        }}>
                            <div className='row'>
                                <div className="col-md-4 col-xs-12">
                                    <div className="dashboardCard">
                                        <div className="cardTitles">My customers</div>
                                        <div className="cardBodys">{mycustomers.length}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <div className="dashboardCard">
                                        <div className="cardTitles">My quotations</div>
                                        <div className="cardBodys">{myquotations.length}</div>
                                    </div>
                                </div>
                            </div>
                            {
                                userdata && userdata.masteradmin === 1 ? <hr /> : null
                            }
                            {
                                userdata && userdata.masteradmin === 1 ?
                                    <div className='row'>
                                        <div className="col-md-4 col-xs-12">
                                            <div className="dashboardCard">
                                                <div className="cardTitles">Total customer</div>
                                                <div className="cardBodys">{allcustomers.length}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-12">
                                            <div className="dashboardCard">
                                                <div className="cardTitles">Total quotations</div>
                                                <div className="cardBodys">{allquotations.length}</div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className={isLessThanTablet ? isOpen ? "sidebarDashboard sidebar lockedopen open rightposition" : "sidebarDashboard sidebar rightposition lockedopen" : "sidebarDashboard sidebar open lockedopen"}>
                    <div className="sidebarContent">
                        <div className="sidebarWidgetGroup">
                            <div className="sidebarWidget pb0">
                                <div className="sidebarWidgetFront">
                                    <div className="sidewidget1">
                                        <div className="sidewidget1Text">
                                            Today is {moment().format('dddd')}
                                        </div>
                                    </div>
                                    <div className="sidewidget2">
                                        <div className="sidewidget2Text">
                                            <span>{moment().format('MMMM')}</span>
                                        </div>
                                        <div className="sidewidget2Text2">
                                            {moment().format('DD')}
                                        </div>
                                        <div className="sidewidget2Text3">
                                            {moment().format('YYYY')}
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebarWidgetFront mt30">
                                    <div className="sidewidget1">
                                        <div className="sidewidget1Text">
                                            Later date for shipping
                                        </div>
                                    </div>
                                    <div className="sidewidget2">
                                        <div className="sidewidget2Text">
                                            <span>{moment(later).format('MMMM')}</span>
                                        </div>
                                        <div className="sidewidget2Text2">
                                            {moment(later).format('DD')}
                                        </div>
                                        <div className="sidewidget2Text3">
                                            {moment(later).format('YYYY')}
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebarWidgetFront mt30">
                                    <div className="sidewidget1">
                                        <div className="sidewidget1Text">
                                            current tax is
                                        </div>
                                    </div>
                                    <div className="sidewidget2">
                                        <div className="sidewidget2Text2">
                                            {taxinvoice} %
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default MainDashboard;