import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
// import moment from 'moment';

import BackDrop from './backdrop';

import Pagination from '../../../utils/pagination';
// import Form from './form';
// import EditForm from './editForm';
import UploadForm from './uploadForm';
// import { Exportxls } from '../../../utils/exportxls';

import FormField from '../../../utils/form/formfield';
import {
    update,
    validate,
    generateData,
    isFormValid,
    resetFields
} from '../../../utils/form/formactions';
import { updateSingle } from '../../../reduceractions/actions/stock/product_actions'

import { FaCloudUploadAlt, FaRedoAlt, FaSearch } from 'react-icons/fa'

const ProductTable = ({
    alldata,
    handleRefresh,
    tableName,
    isMobile,
    height,
    createstatus,
    createstatusHandler,
    uploadstatus,
    uploadstatusHandler,
    userdata
}) => {

    const dispatch = useDispatch();

    const getallsettings = useSelector(state => state.settingreducer.settings && state.settingreducer.settings.settings);

    const numberThisData = alldata && alldata.length;
    const [filterText, filterTextHandler] = React.useState('');
    const [currentPage, currentPageHandler] = React.useState(1);
    const [pageSize, pageSizeHandler] = React.useState(10);

    const [showForm, showFormHandler] = React.useState(false);
    const [showEditForm, showEditFormHandler] = React.useState(false);
    const [showUploadForm, showUploadFormHandler] = React.useState(false);

    const [selectedData, selectedDataHandler] = React.useState();

    const [dataCount, dataCountHandler] = React.useState("");
    const [mydata, mydataHandler] = React.useState([]);

    const [formError, formErrorHandler] = React.useState(false);
    const [formSuccess, formSuccessHandler] = React.useState(false);
    const [errorMessage, errorMessageHandler] = React.useState('');
    const [formdata, formdataHandler] = React.useState({
        name: {
            element: 'input',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Name'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        quantity: {
            element: 'input',
            value: 0,
            config: {
                name: 'quantityInput',
                type: 'number',
                placeholder: 'Quantity'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    })

    const executedOnceRef = React.useRef(false);

    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (getallsettings && getallsettings.length > 0) {
                    let selected = getallsettings[0];
                    pageSizeHandler(selected.pagesize)
                    executedOnceRef.current = true;
                }
            }
        }
        fetchData();
        return () => {
            mounted = false;
        };
    }, [getallsettings])

    const gotoDetails = React.useCallback((data) => {
        let newFormdata = { ...formdata }
        let temporaryFormdata = { ...newFormdata };
        const newElementName = {
            ...temporaryFormdata['name']
        }

        const newElementQuantity = {
            ...temporaryFormdata['quantity']
        }

        newElementName.value = data.name;
        newElementQuantity.value = Number(data.quantity) !== 0 ? Number(data.quantity) : '0';

        temporaryFormdata['name'] = newElementName;
        temporaryFormdata['quantity'] = newElementQuantity;

        formdataHandler(temporaryFormdata);
        filterTextHandler("");
        currentPageHandler(1);
        selectedDataHandler(data);
        showEditFormHandler(prevState => !prevState.showEditForm)
    }, [selectedDataHandler, formdata])

    const gotoDetailsRef = React.useRef(gotoDetails);

    React.useEffect(() => {
        gotoDetailsRef.current = gotoDetails;
    }, [gotoDetails]);

    // function fn(text, count) {
    //     // return text.slice(0, count);
    //     return text.slice(0, count) + (text.length > count ? "..." : "");
    // }

    const TableRow = React.useCallback((thedata, index) => {

        return (
            <tr onClick={() => gotoDetailsRef.current(thedata)} key={index}>
                <td>
                    {index + 1}
                </td>
                <td className='productTableId'>
                    {thedata.productId}
                </td>
                <td className='productTableName'>
                    {thedata.name}
                </td>
                <td className='productTableCategories'>
                    {thedata.categories}
                </td>
                <td className='productTablePrice'>
                    {thedata.price}
                </td>
                <td className='productTableQuantity'>
                    {thedata.quantity}
                </td>
            </tr>
        );
    }, [])

    useEffect(() => {
        var results = [];
        var resultsdata = [];
        var offset = (currentPage - 1) * pageSize;
        if (alldata) {
            // var filterdata = alldata.filter((data, i) => data.name !== 'LUMISOFT');
            results = alldata.filter(data => (data.productId.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                || data.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                || data.categories.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                || data.price.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1))
            resultsdata = results.map((item, index) => TableRow(item, index))
            var semuadata = [...resultsdata];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            dataCountHandler(results.length);
            mydataHandler(mydatas);
        }

    }, [filterText, currentPage, alldata, pageSize, TableRow])


    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber);
    }

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'product');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1);
    }

    const handleUpdate = () => {
        filterTextHandler("");
        currentPageHandler(1);
        handleRefresh();
    }

    const handleUpload = () => {
        filterTextHandler("");
        currentPageHandler(1);
        uploadstatusHandler(true);
        showUploadFormHandler(prevState => !prevState.showUploadForm)
    }

    const handleEdit = (data) => {

        let newFormdata = { ...formdata }
        let temporaryFormdata = { ...newFormdata };
        const newElementName = {
            ...temporaryFormdata['name']
        }

        const newElementQuantity = {
            ...temporaryFormdata['quantity']
        }

        newElementName.value = data.thedata.name;
        newElementQuantity.value = Number(data.thedata.quantity) !== 0 ? Number(data.thedata.quantity) : '0';

        temporaryFormdata['name'] = newElementName;
        temporaryFormdata['quantity'] = newElementQuantity;

        formdataHandler(temporaryFormdata);
        filterTextHandler("");
        currentPageHandler(1);
        selectedDataHandler(data);
        showEditFormHandler(prevState => !prevState.showEditForm)
    }

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        showEditFormHandler(false);
        selectedDataHandler({});
        filterTextHandler("");
        currentPageHandler(1);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const submitDataHandler = (event) => {
        event.preventDefault();

        let dataToSubmit = generateData(formdata, 'updateproduct');
        let formIsValid = isFormValid(formdata, 'updateproduct');

        let totalDataToSubmit = {
            ...dataToSubmit,
            id: selectedData._id
        }

        if (formIsValid) {
            if (userdata && userdata.manager > 0) {
                dispatch(updateSingle(totalDataToSubmit)).then((updateFeedback) => {
                    if (updateFeedback.payload.success) {
                        formSuccessHandler(true);
                        setTimeout(() => {
                            formSuccessHandler(false);
                            refreshPage();
                        }, 1000)
                    } else {
                        formErrorHandler(true);
                        errorMessageHandler(updateFeedback.payload.message);
                    }
                })
            } else {
                formErrorHandler(true);
                errorMessageHandler('You are not authorize');
            }
        } else {
            formErrorHandler(true);
            errorMessageHandler('Failed, please try again');
        }
    }

    const wizardForm = `${showForm || showUploadForm ? 'displayNone' : 'cardBody'}`;

    return (
        <div>
            <div className={showEditForm ? "rightSideOption open" : "rightSideOption"}>
                {
                    showEditForm ?
                        <div className="actionTitle">
                            <div className="actionTitleText">Edit data: {selectedData.productId} ({selectedData.categories})</div>
                        </div>
                        : null
                }
                {
                    showEditForm ?
                        <div style={{
                            margin: 20
                        }}>
                            <div className="formGroup mb20">
                                <div className="formWrapper">
                                    <FormField
                                        id={'name'}
                                        formdata={formdata.name}
                                        change={(element) => updateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                            <div className="formGroup mb20">
                                <div className="formWrapper">
                                    <FormField
                                        id={'quantity'}
                                        formdata={formdata.quantity}
                                        change={(element) => updateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    showEditForm ?
                        <div className="rightSideButtonWrapper">
                            <div
                                className="rightSideButton mr20"
                                onClick={(event) => submitDataHandler(event)}
                            >
                                Submit
                            </div>
                        </div>
                        : null
                }
                {
                    showEditForm ?
                        formSuccess ?
                            <div className="successLabel">
                                SUCCESS, PLEASE WAIT...
                            </div>
                            :
                            formError ?
                                <div className="errorLabel">
                                    {
                                        errorMessage ?
                                            errorMessage
                                            : 'PLEASE CHECK YOUR DATA'
                                    }
                                </div>
                                : null
                        : null
                }
            </div>
            {
                showEditForm &&
                <BackDrop click={backdropClickHandler} />
            }

            <UploadForm
                showUpload={showUploadForm}
                uploadstatus={uploadstatus}
                uploadstatusHandler={uploadstatusHandler}
                showUploadFormHandler={showUploadFormHandler}
                handleRefresh={handleRefresh}
                isMobile={isMobile}
                height={height}
            />


            <div className={wizardForm}>
                <div className="flexbox mb20 centerXs">
                    <div className="lookup">
                        <div className="iconPosition">
                            <FaSearch className="icon agraicon w18px" />
                        </div>
                        <input
                            className="w200px"
                            type="text"
                            name="Search"
                            placeholder="Search"
                            title="Type in a name"
                            value={filterText}
                            // ref="filterTextInput"
                            onChange={(event) => handleChange(event)}
                        />
                        <div className="pageNumber">{currentPage}/{parseInt((dataCount / pageSize) + ((dataCount % pageSize) > 0 ? 1 : 0))} Pages</div>

                    </div>
                    <div className="btnToolbar">
                        <div className="btnGroup btnGroupSM">
                            <button
                                className="btn"
                                title=""
                                onClick={() => handleUpdate()}
                            >
                                <FaRedoAlt className="icon agraicon w18px" />
                            </button>
                            {
                                userdata.manager > 0 ?
                                    <button
                                        className="btn"
                                        title=""
                                        onClick={() => handleUpload()}
                                    >
                                        <FaCloudUploadAlt className="icon agraicon w18px" />
                                    </button>
                                    : null
                            }

                        </div>
                        {/* {
                                    isMobile ? null
                                        :
                                        <div className="btnGroup btnGroupSM mlHalfRem dFlex">
                                            <button
                                                className="btn"
                                                title=""
                                                onClick={() => exportXls()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    className="icon agraicon w18px"
                                                />
                                            </button>
                                        </div>
                                } */}

                    </div>
                </div>
                <div>
                    <div className="tableResponsiveSm">
                        <table className="table" id={tableName}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product ID</th>
                                    <th>Name</th>
                                    <th>Categories</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    alldata.length === 0 ?
                                        <tr className="textCenter fw500 bg_table textWhite">
                                            <td colSpan={"7"}>
                                                Now Loading....
                                            </td>
                                        </tr>
                                        :
                                        mydata.length === 0
                                            ?
                                            <tr className="textCenter fw500 bg_table textWhite">
                                                <td colSpan={"6"}>
                                                    Data not exist
                                                </td>
                                            </tr>
                                            : mydata
                                }
                            </tbody>

                        </table>
                    </div>
                    {
                        alldata.length === 0 ?
                            null
                            :
                            <nav className="mt10">
                                <Pagination Size={dataCount} pageLimit={pageSize} onPageChanged={pageChanged} currentPage={currentPage} width={isMobile} />
                            </nav>
                    }

                </div>

            </div>


        </div>
    );
}

export default ProductTable;