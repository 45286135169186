import React from 'react';
import { useDispatch } from 'react-redux';

// import moment from 'moment';

// import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

// import BackDrop from '../../../admin/components/headerfooter/backdrop';
// import BackDropWithAdd from '../../../admin/components/headerfooter/backdrop';
// import DoubleBackDrop from '../../../admin/components/headerfooter/doublebackdrop';
// import TripleBackDrop from '../../../admin/components/headerfooter/doublebackdrop';

// import SearchForm from '../../../utils/searchform/cannotadd';
// import SearchFormWithAdd from '../../../utils/searchform';
// import PopupMessage from '../../../utils/popupmessage';
// import PopupMessageNoAdd from '../../../utils/popupmessage/noadd';

import FormField from '../../../utils/form/formfield';
import {
    update,
    generateData,
    isFormValid,
    resetFields
    // validate,
    // populateOptionFields,
    // editFormAdd
} from '../../../utils/form/formactions';

// import { 
//     getProvince, 
//     getCity, 
//     getSubdistrict, 
//     postCost 
// } from '../../../reduceractions/actions/admin/ongkir_actions';
import {
    updatePassword,
    clearUpdatePassword
} from '../../../reduceractions/actions/admin/user_actions';

import { FaChevronLeft, FaLock, FaShieldAlt } from 'react-icons/fa';

const EditPasswordForm = ({
    showPassword,
    showPasswordFormHandler,
    // handleRefresh,
    isMobile,
    numberThisData,
    selectednumber,
    changepasswordHandler,
    selectedThisData,
    // selectedThisDataHandler,
    // userdata
}) => {
    const dispatch = useDispatch();

    const [process, processHandler] = React.useState(false);
    const [formError, formErrorHandler] = React.useState(false);
    const [formSuccess, formSuccessHandler] = React.useState(false);
    const [errorMessage, errorMessageHandler] = React.useState('');

    const [formdata, formdataHandler] = React.useState({
        password: {
            element: 'input',
            value: '',
            config: {
                name: 'passwordInput',
                type: 'password',
                placeholder: 'Enter your password'
            },
            validation: {
                required: true,
                strong: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        confirmPassword: {
            element: 'input',
            value: '',
            config: {
                name: 'confirmPasswordInput',
                type: 'password',
                placeholder: 'Confirm your password'
            },
            validation: {
                required: true,
                confirm: 'password'
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    })

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'password');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }

    const goBackToTable = () => {
        const newFormData = resetFields(formdata, 'password');
        formdataHandler(newFormData)
        resetEditFieldHandler()
        selectednumber(numberThisData)
        showPasswordFormHandler(false);

    }

    const resetFieldHandler = () => {
        const newFormData = resetFields(formdata, 'password');
        formdataHandler(newFormData)
        setTimeout(() => {
            resetEditFieldHandler()
        }, 500)
    }

    const resetEditFieldHandler = () => {
        formErrorHandler(false);
        formSuccessHandler(false);
        processHandler(false);
        changepasswordHandler(false);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const submitNewAddress = (event) => {
        event.preventDefault();

        let dataToSubmit = generateData(formdata, 'addNewAddressCustomer');
        let formIsValid = isFormValid(formdata, 'addNewAddressCustomer');

        let totalDataToSubmit = {
            ...dataToSubmit,
            _id: selectedThisData._id
        }
        if (formIsValid) {
            dispatch(updatePassword(totalDataToSubmit)).then((thisfeedback) => {
                if (thisfeedback.payload.success) {
                    formSuccessHandler(true);
                    setTimeout(() => {
                        selectednumber(0)
                        dispatch(clearUpdatePassword())
                        formSuccessHandler(false);
                        resetFieldHandler();
                        refreshPage();
                    }, 1000)
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(thisfeedback.payload.message);
                }
            })
        } else {
            // console.log("error 3");
            formErrorHandler(true);
            errorMessageHandler('Form not valid');
            processHandler(false);
        }
    }

    const className = `${showPassword ? 'cardBody' : 'displayNone'}`;

    return (
        <div className={className}>
            <div className="row">
                <div className="col-md-4 col-xs-12">
                    <div className="formGroup mb20">
                        <label className="controlLabel">Password</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaLock className="icon agraicon" />
                            </div>
                            <FormField
                                id={'password'}
                                formdata={formdata.password}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                    <div className="formGroup mb20">
                        <label className="controlLabel">Confirm Password</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaShieldAlt className="icon agraicon" />
                            </div>
                            <FormField
                                id={'confirmPassword'}
                                formdata={formdata.confirmPassword}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-xs-12">
                    {
                        formSuccess ?
                            <div className="successLabel">
                                CHANGE PASSWORD SUCCESS, RELOADING...
                            </div>
                            : null
                    }
                    {
                        formError ?
                            <div className="errorLabel">
                                {errorMessage}
                            </div>
                            : null
                    }
                </div>
                <div className={`${isMobile ? "col-md-12 col-xs-12" : "col-md-12 col-xs-12 posAbsolute confirmInvoiceButton"}`}>
                    <div
                        className="inlineFlex"
                        style={{
                            width: '100%',
                            paddingRight: 15,
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            className="agrapanaBtn redBackground p8"
                            title=""
                            onClick={() => goBackToTable()}
                        >
                            <FaChevronLeft className="icon agraicon w18px" />
                        </div>
                        <div className="doubleButton">
                            <button onClick={(event) => submitNewAddress(event)} className={`${process ? "agrapanaBtn agrapanaBtnPrimary divBtn ml10 disabled" : "agrapanaBtn agrapanaBtnPrimary divBtn ml10"}`}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPasswordForm;