import { combineReducers } from 'redux';
import user from './admin/user_reducer';
import customers from './admin/customer_reducer';
import ongkir from './admin/ongkir_reducer';
import product from './stock/stock_reducer';
import role from './general/role_reducer';
import quotation from './general/quotation_reducer';
import termofpayment from './admin/termofpayment_reducer';
import loading from './loading_reducer';
import uploadpdf from './general/pdf_reducer';
import settingreducer from './general/setting_reducer';
// import notifications from './notifications_reducer';


const rootReducer = combineReducers({
    user,
    customers,
    ongkir,
    product,
    role,
    quotation,
    termofpayment,
    loading,
    uploadpdf,
    settingreducer
    // notifications
});

export default rootReducer;