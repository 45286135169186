import {
    REGISTER_USER,
    CLEAR_REGISTER_USER,
    LOGIN_USER,
    AUTH_USER,
    LOGOUT_USER,
    USER_CONFIRMATION,
    CHANGE_PASSWORD,
    RECONFIRM_EMAIL_USER,
    ADD_IMAGES,
    FORGOT_PASSWORD,
    UPDATE_DATA_USER,
    CLEAR_UPDATE_USER_DATA,
    ADD_ADDRESS,
    UPDATE_ADDRESS,
    UPDATE_SELECTED_ADDRESS,
    CLEAR_ADDRESS,
    GET_USERS,
    UPDATE_DATA_PASSWORD,
    CLEAR_UPDATE_PASSWORD
} from '../../actions/admin/types';

export default function(state={},action){
    switch(action.type){
        case REGISTER_USER:
            return {
                ...state, 
                registerSuccess: action.payload
            }
        case CLEAR_REGISTER_USER:
            return {
                ...state,
                registerSuccess: action.payload
            }
        case LOGIN_USER:
            return {...state, loginSuccess: action.payload}
        case GET_USERS: 
            return {...state, users: action.payload}
        case AUTH_USER:
            return {...state, userData: action.payload}
        case LOGOUT_USER:
            return {...state}
        case USER_CONFIRMATION:
            return {...state, userConfirmation: action.payload}
        case CHANGE_PASSWORD:
            return {...state, changePassword: action.payload}
        case RECONFIRM_EMAIL_USER:
            return {...state, emailStatus: action.payload}
        case ADD_IMAGES:
            return {...state, addImages: action.payload}
        case FORGOT_PASSWORD:
            return {...state, forgotpassword: action.payload}
        case UPDATE_DATA_USER:
            return {
                ...state, updateUser: action.payload
            }
        case CLEAR_UPDATE_USER_DATA:
            return {
                ...state, updateUser: action.payload
            }
        case UPDATE_DATA_PASSWORD:
            return {
                ...state, updatePassword: action.payload
            }
        case CLEAR_UPDATE_PASSWORD:
            return {
                ...state, updatePassword: action.payload
            }
        case ADD_ADDRESS:
            return {
                ...state, addAddress: action.payload
            }
        case UPDATE_ADDRESS:
            return {
                ...state, updateAddress: action.payload
            }
        case UPDATE_SELECTED_ADDRESS:
            return{
                ...state, updateSelectedAddress: action.payload
            }
        case CLEAR_ADDRESS:
            return {
                ...state, updateAddress: action.payload
            }
        default:
            return state;
    }
}