import React from 'react';

const AssociatePage = (props) => {
    return (
        <div id="pricing-tables" className="gray" style={{ paddingTop: '60px' }}>
            <h2
                className="sectiontitleku"
                style={
                    props.isMobile ? { lineHeight: '1' } : null
                }
            >
                associate companies
                </h2>
            <div className="container">
                <div className="row">

                    <div className="column col-md-4 col-xs-12">
                        <div className="frontendtable">

                            <span className="tabletitle">Mica Range Sdn Bhd Company</span>
                            <ul>
                                <li>No.62, Jalan TSB 9, Taman Industri</li>
                                <li>47000 Sungai Buloh, Selangor</li>
                                <li>Malaysia</li>
                                <br />
                                <br />
                                <li>info@micarange.com.my</li>
                                <br />
                                <li>+603-6140 1512</li>

                            </ul>
                            {/* <a href="#" className="lm-button">visit us</a> */}
                        </div>

                    </div>
                    <div className="column col-md-4 col-xs-12">
                        <div className="frontendtable">

                            <span className="tabletitle">Metro Laminates Sdn Bhd Company</span>
                            <ul>
                                <li>No.16, Jalan Mutiara Emas 5/25</li>
                                <li>Taman Mount Austin</li>
                                <li>81100 Johor Bahru, Johor</li>
                                <li>Malaysia</li>
                                <br />
                                <li>metrolam@live.com</li>
                                <br />
                                <li>+607-352 9323</li>

                            </ul>
                            {/* <a href="#" className="lm-button">visit us</a> */}
                        </div>

                    </div>
                    <div className="column col-md-4 col-xs-12">
                        <div className="frontendtable">

                            <span className="tabletitle"> Jelita Laminasi Sdn Bhd Company</span>
                            <ul>
                                <li>No.1522, Lorong Makmur 3/2</li>
                                <li>Kawasan Perindustrian Makmur</li>
                                <li>09600 Lunas, Kedah</li>
                                <li>Malaysia</li>
                                <br />
                                <li>jelitalaminasi@gmail.com</li>
                                <br />
                                <li>+604-484 2623</li>

                            </ul>
                            {/* <a href="#" className="lm-button">visit us</a> */}
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default AssociatePage;