export const Exportxls = (head, data, products = [], headproducts = []) => {
    // console.log(head)
    // console.log(data)
    // console.log(products)
    // console.log(headproducts)
    var finalVal = "";
    var downloadLink;
    var txtArea1;
    var uri = 'data:application/vnd.ms-excel;base64,';
    // var data = [];
    var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    var base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)))
    };

    var format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    };

    var lines = head.length;
    
    if(headproducts){
        var productlines = headproducts.length;
    }
    // for (var item = 0; item < re.length; item++) {
    //     data.push(pushdata);
    // }

    if (lines > 0) {
        for (var a = 0; a < lines; a++) {
            finalVal = finalVal + '<th>' + head[a] + '</th>';
        }
        finalVal = finalVal + '<th></th>';
        for (var b = 0; b < productlines; b++){
            finalVal = finalVal + '<th>' + headproducts[b] + '</th>';
        }
        finalVal = `<table border='2px'><tr bgcolor="41cdf4">` + finalVal + '</tr>';
        // #87AFC6
    }

    for (var i = 0; i < data.length; i++) {
        var value = data[i];
        var innerValue = '';
        for (var j = 0; j < value.length; j++) {
            innerValue = value[j] === null ? '' : innerValue + ('<td>' + value[j].toString() + '</td>');
        }
        innerValue = innerValue + '<td></td>';
        if(products && products.length > 0) {
            if(products[i][0] && products[i][0].length > 0){
                for (var k = 0; k < products[i][0].length ; k++){
                    innerValue = innerValue + ('<td>' + products[i][0][k] +'</td>');
                }
            } else {
                innerValue = innerValue + ('<td></td>');
            }
        } else {
            innerValue = innerValue + ('<td></td>');
        }
        
        

        var result = innerValue.replace(/"/g, '""');
        finalVal = finalVal + ('<tr>' + result + '</tr>');
        // console.log(result, "<<<<<<<result<<<<<")
        // if (productlines > 0) {
        //     for (var hp = 0; hp < productlines; hp++) {
        //         var finalHead = finalHead + '<td>' + headproducts[hp] + '</td>'
        //     }
        //     finalVal = finalVal + (`<tr bgcolor="#9dddef">` + finalHead + '</tr>');
        //     finalHead = '';
        // }

        if (products && products.length > 0) {
            var valueProduct = products[i];
            
            for (var abc = 1; abc < valueProduct.length; abc++) {
                var doubleInnerProduct = valueProduct[abc];
                var innerProduct = '';
                // console.log(doubleInnerProduct, "<<<<<<<doubleInnerProduct<<<<<")
                for (var asdf = 0; asdf < data[i].length; asdf++) {
                    innerProduct = innerProduct + ('<td></td>');
                }
                innerProduct = innerProduct + ('<td></td>');
                for (var jkl = 0; jkl < doubleInnerProduct.length; jkl++) {
                    innerProduct = innerProduct + ('<td>' + doubleInnerProduct[jkl].toString() + '</td>')
                    // console.log(innerProduct, "<<<<<<<innerProduct<<<<<")
                }
                finalVal = finalVal + ('<tr bgcolor="#9dddef">' + innerProduct + '</tr>');
                doubleInnerProduct = '';
                innerProduct = '';
            }
            finalVal = finalVal + ('<tr></tr>');
        }
        innerValue = '';
    }
    finalVal = finalVal + "</table>";
    // console.log(finalVal, "<================= finalVal")
    // console.log(finalVal)
    // finalVal = finalVal.replace(/<A[^>]*>|<\/A>/g, "");//remove if u want links in your table
    // finalVal = finalVal.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
    // finalVal = finalVal.replace(/<input[^>]*>|<\/input>/gi, ""); // remove input params

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var filename = 'export_' + (new Date()).getTime() + '.xls';
    var ctx = {
        worksheet: 'Worksheet',
        table: finalVal
    }

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv[ :]*11\./))      // If Internet Explorer
    {
        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(finalVal);
        txtArea1.document.close();
        txtArea1.focus();
        downloadLink = txtArea1.document.execCommand("SaveAs", true, filename);
    } else {
        downloadLink = document.createElement('a');
        var data_type = uri + base64(format(template, ctx));
        // var data_type = 'data:application/vnd.ms-excel,' + encodeURIComponent(finalVal);
        downloadLink.href = data_type;
        downloadLink.download = filename;
        downloadLink.target = "_blank";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
};