import React from 'react';

import { IoIosLink, IoIosTrendingUp } from "react-icons/io";
import { GiPaintRoller } from "react-icons/gi";

const HomePage = (props) => {
    return (
        <div id="aboutus" className="menuin">
            <div className="sectionheader">
                <h2 className="sectiontitleku">
                    why&nbsp;
                            <img src={window.location.origin + "/assets/images/formicalogo.png"} alt="" />
                    &nbsp;?
                    </h2>
                <p className="sectiontagline">
                    Formica® is an iconic brand, which is intrinsically linked with the material, High Pressure Laminate(HPL). We have developed an unrivalled expertise that ensures products are fit for purpose and meet market demands. Working closely with architects, designers and specifiers, the company is ideally placed to offer new products and decors which complement current design trends, and has an ongoing product design and development process.
                    </p>
            </div>
            <div className="abouticons">
                <div className="container">
                    <div className="row">
                        <div className={
                            props.isMobile ? "column col-md-4 col-xs-12 bordermobile" 
                            : "column col-md-4 col-xs-12 border"}>
                            <div className={
                                props.isMobile ? "mainmobile" 
                                : props.isTablet ? "mainmobile" 
                                : "main"
                                }>
                                <IoIosLink />
                                <h2>Durability</h2>
                            </div>
                            <span className={
                                props.isMobile ? "description" 
                                : props.isTablet ? "description" 
                                : "desc"}>Formica® HPL are made from specially selected kraft and printed papers impregnated with thermosetting synthetic resins and fused together under heat and high-pressure in a controlled environment to form single high-density sheets of laminates.</span>
                        </div>
                        <div className={
                            props.isMobile ? "column col-md-4 col-xs-12 bordermobile" 
                            : "column col-md-4 col-xs-12 border"}>
                            <div className={
                                props.isMobile ? "mainmobile" 
                                : props.isTablet ? "mainmobile" 
                                : "main"}>
                                <GiPaintRoller />
                                <h2>Surfaces</h2>
                            </div>
                            <span className={
                                props.isMobile ? "description" 
                                : props.isTablet ? "description" 
                                : "desc"}>The surface patterns and colours on the the laminates are impregnated with melamine-based resins to provide higher resistance against abrasion, shock and impact, heat and discolouration.</span>
                        </div>
                        <div className="column col-md-4 col-xs-12">
                            <div className={
                                props.isMobile ? "mainmobile"
                                : props.isTablet ? "mainmobile" 
                                : "main"}>
                                <IoIosTrendingUp />
                                <h2>Grades</h2>
                            </div>
                            <span className={
                                props.isMobile ? "description" 
                                : props.isTablet ? "description" 
                                : "desc"}>All Formica® HPL are manufactured to the requirements of EN 438 and ISO 4586 and are available in many grades, each of which with specific properties. </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;