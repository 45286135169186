import {
    UPLOADPDF_QUOTATION
} from '../../actions/general/types';

export default function(state={},action){
    switch(action.type){
        case UPLOADPDF_QUOTATION:
            return {
                ...state,
                uploadpdfQuotation: action.payload
            }
        default:
            return state;
    }
}