import React from 'react';
import Slider from 'react-slick';

const CarouselPage = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500
    }

    const formica1 = props.isMobile ? "/assets/images/formica1mobile.jpg" : "/assets/images/formica1.jpg";
    const formica2 = props.isMobile ? "/assets/images/formica2mobile.jpg" : "/assets/images/formica2.jpg"
    const formica3 = props.isMobile ? "/assets/images/formica3mobile.jpg" : "/assets/images/formica3.jpg"
    return (
        <div
            className="slidercontainer"
            style={
                {
                    height: `${window.innerHeight}px`,
                    overflow: "hidden"
                }
            }
        >
            <Slider {...settings}>
                <div className="carouselimage">
                    <img src={window.location.origin + formica1} alt="" />
                </div>
                <div className="carouselimage">
                    <img src={window.location.origin + formica2} alt="" />
                </div>
                <div className="carouselimage">
                    <img src={window.location.origin + formica3} alt="" />
                </div>
            </Slider>
        </div>
    );
};

export default CarouselPage;