import React from 'react';
import { useDispatch } from 'react-redux';

import FormField from '../../../utils/form/formfield';
import { generateData, isFormValid, resetFields, update, validate } from '../../../utils/form/formactions';
import { FaChevronLeft, FaUser } from 'react-icons/fa';
import { addTerm, clearTerm } from '../../../reduceractions/actions/admin/termofpayment_actions';

const TermsFormAdd = ({
    show,
    isMobile,
    showFormHandler
}) => {

    const dispatch = useDispatch();

    const [formError, formErrorHandler] = React.useState(false);
    const [formSuccess, formSuccessHandler] = React.useState(false);
    const [errorMessage, errorMessageHandler] = React.useState('');

    const [formdata, formdataHandler] = React.useState({
        name: {
            element: 'input',
            value: '',
            config: {
                name: 'termInput',
                type: 'text',
                placeholder: 'Enter term of payment'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    })

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'terms');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }

    const goBackToTable = () => {
        const newFormData = resetFields(formdata, 'terms');
        formdataHandler(newFormData)
        // filterTextHandler('');
        // productSelectedHandler('');
        // currentPageHandler(1);
        // productsHandler([])
        showFormHandler(false)
        resetEditFieldHandler()
    }

    const resetEditFieldHandler = () => {
        formErrorHandler(false);
        formSuccessHandler(false);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const submitNewTerms = (event) => {
        event.preventDefault();

        let dataToSubmit = generateData(formdata, 'terms');
        let formIsValid = isFormValid(formdata, 'terms');

        if (formIsValid) {
            dispatch(addTerm(dataToSubmit)).then((thisfeedback) => {
                if (thisfeedback.payload.success) {
                    formSuccessHandler(true);
                    setTimeout(() => {
                        dispatch(clearTerm());
                        formSuccessHandler(false);
                        resetEditFieldHandler();
                        refreshPage();
                    }, 1000)
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(thisfeedback.payload.message);
                }
            })
        } else {
            // console.log("error 3");
            formErrorHandler(true);
            errorMessageHandler('Form not valid');
        }
    }

    const className = `${show ? 'cardBody' : 'displayNone'}`;

    return (
        <div className={className}>
            <div className="row">
                <div className="col-md-12 col-xs-12">
                    <div className="formGroup mb20">
                        <label className="controlLabel">Terms of Payment</label>
                        <div className="formWrapper">
                            <div className="iconPosition">
                                <FaUser className="icon agraicon" />
                            </div>
                            <FormField
                                id={'name'}
                                formdata={formdata.name}
                                change={(element) => updateForm(element)}
                                myclass={'form-control'}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12 col-xs-12">
                    {
                        formSuccess ?
                            <div className="successLabel">
                                SUCCESS, PLEASE WAIT...
                            </div>
                            : null
                    }
                    {
                        formError ?
                            <div className="errorLabel">
                                {errorMessage}
                            </div>
                            : null
                    }
                </div>
                <div className={`${isMobile ? "col-md-12 col-xs-12" : "col-md-12 col-xs-12 posAbsolute confirmInvoiceButton"}`}>
                    <div
                        className="inlineFlex"
                        style={{
                            width: '100%',
                            paddingRight: 15,
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            className="agrapanaBtn redBackground p8"
                            title=""
                            onClick={() => goBackToTable()}
                        >
                            <FaChevronLeft className="icon agraicon w18px" />
                        </div>
                        <div className="doubleButton">
                            <button
                                onClick={(event) => submitNewTerms(event)}
                                className="agrapanaBtn agrapanaBtnPrimary divBtn ml10" >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsFormAdd;