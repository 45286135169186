import axios from 'axios';
import {
    GET_PROVINCE,
    GET_CITY,
    GET_SUBDISTRICT,
    POST_COST
} from './types';

import { ONGKIR_SERVER } from '../../misc';

export function getProvince(){
    const request = axios.get(`${ONGKIR_SERVER}/province`)
    .then(response => response.data);

    return {
        type: GET_PROVINCE,
        payload: request
    }
}
export function getCity(){
    const request = axios.get(`${ONGKIR_SERVER}/city`)
    .then(response => response.data);

    return {
        type: GET_CITY,
        payload: request
    }
}
export function getSubdistrict(number){
    const request = axios.get(`${ONGKIR_SERVER}/subdistrict?number=${number}`)
    .then(response => response.data);

    return {
        type: GET_SUBDISTRICT,
        payload: request
    }
}
export function postCost(dataToSubmit){
    const request = axios.post(`${ONGKIR_SERVER}/cost`, dataToSubmit)
        .then(response => response.data);

    return {
        type: POST_COST,
        payload: request
    }
}