import axios from 'axios';
import {
    GET_TERMOFPAYMENTS,
    ADD_TERMOFPAYMENT,
    CLEAR_TERMOFPAYMENT,
    UPDATE_TERMOFPAYMENT,
    CLEAR_UPDATE_TERMOFPAYMENT
} from './types';

import { TERMOFPAYMENT_SERVER } from '../../misc';

export function getTerms(){
    const request = axios.get(`${TERMOFPAYMENT_SERVER}/termofpayments`)
    .then(response => response.data);

    return {
        type: GET_TERMOFPAYMENTS,
        payload: request
    }
}

export function addTerm(dataToSubmit){
    const request = axios.post(`${TERMOFPAYMENT_SERVER}/termofpayment`,dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_TERMOFPAYMENT,
        payload: request
    }
}

export function clearTerm(){
    return {
        type: CLEAR_TERMOFPAYMENT,
        payload: ''
    }
}

export function updateTerm(dataToSubmit){
    const request = axios.post(`${TERMOFPAYMENT_SERVER}/update`,dataToSubmit)
        .then(response => response.data);
        // .then(response => {
        //     return response.data
        // });

    return {
        type: UPDATE_TERMOFPAYMENT,
        payload: request
    }
}

export function clearUpdateTerm(){
    return {
        type: CLEAR_UPDATE_TERMOFPAYMENT,
        payload: ''
    }
}