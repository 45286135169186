import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import monitorReducersEnhancer from './monitorReducer';
import loggerMiddleware from './logger';
import rootReducer from './reduceractions/reducers/index';

import promiseMiddleware from 'redux-promise';

export default function configureStore(preloadedState) {

    let middlewares = [];
    let enhancers = [];

    if (process.env.NODE_ENV === 'development') {
        middlewares = [promiseMiddleware, loggerMiddleware, thunkMiddleware]
    } else {
        middlewares = [promiseMiddleware, thunkMiddleware]
    }

    const middlewareEnhancer = applyMiddleware(...middlewares);

    if (process.env.NODE_ENV === 'development') {
        enhancers = [middlewareEnhancer, monitorReducersEnhancer]
    } else {
        enhancers = [middlewareEnhancer]
    }

    const composedEnhancers = compose(...enhancers)

    const store = createStore(
        rootReducer,
        preloadedState,
        composedEnhancers
    );

    return store
}