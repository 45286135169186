import {
    GET_PRODUCTS,
    ADD_PRODUCT,
    CLEAR_PRODUCT,
    UPDATE_PRODUCT,
    UPDATE_ALL_PRODUCT,
    UPDATE_PRODUCT_SINGLE,
    CLEAR_UPDATE_PRODUCT,
    DELETE_PRODUCT
} from '../../actions/stock/types';

export default function(state={},action){
    switch(action.type){
        case GET_PRODUCTS:
            return {
                ...state, 
                products: action.payload
            }
        case UPDATE_ALL_PRODUCT:
            return {
                ...state,
                allupload: action.payload
            }
        case ADD_PRODUCT:
            return {
                ...state,
                addProduct: action.payload
            }
        case CLEAR_PRODUCT:
            return {
                ...state,
                addProduct: action.payload
            }
        case UPDATE_PRODUCT:
            return {
                ...state,
                updateProduct: action.payload
            }
        case UPDATE_PRODUCT_SINGLE:
            return {
                ...state,
                updateSingleProduct: action.payload
            }
        case CLEAR_UPDATE_PRODUCT:
            return {
                ...state,
                updateProduct: action.payload
            }
        case DELETE_PRODUCT:
            return {
                ...state,
                deleteProduct: action.payload
            }
        default:
            return state;
    }
}