import {
    GET_TERMOFPAYMENTS,
    ADD_TERMOFPAYMENT,
    CLEAR_TERMOFPAYMENT,
    UPDATE_TERMOFPAYMENT,
    CLEAR_UPDATE_TERMOFPAYMENT
} from '../../actions/admin/types';

export default function(state={},action){
    switch(action.type){
        case GET_TERMOFPAYMENTS:
            return {
                ...state, 
                terms: action.payload
            }
        case ADD_TERMOFPAYMENT:
            return {
                ...state,
                addTerm: action.payload
            }
        case CLEAR_TERMOFPAYMENT:
            return {
                ...state,
                addTerm: action.payload
            }
        case UPDATE_TERMOFPAYMENT:
            return {
                ...state,
                updateTerm: action.payload
            }
        case CLEAR_UPDATE_TERMOFPAYMENT:
            return {
                ...state,
                updateTerm: action.payload
            }
        default:
            return state;
    }
}