/////////////////////////////////////////////////////////////////////
////////////////////////////// USER /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const REGISTER_USER = 'register_user';
export const CLEAR_REGISTER_USER = 'clear_register_user';
export const LOGIN_USER = 'login_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const USER_CONFIRMATION = 'user_confirmation';
export const CHANGE_PASSWORD = 'change_password';
export const RECONFIRM_EMAIL_USER = 'reconfirm_email_user';
export const ADD_IMAGES = 'add_images';
export const FORGOT_PASSWORD = 'forgot_password';
export const UPDATE_DATA_USER = 'update_data_user';
export const CLEAR_UPDATE_USER_DATA = 'clear_update_user_data';
export const ADD_ADDRESS = 'add_address';
export const UPDATE_ADDRESS = 'update_address';
export const UPDATE_SELECTED_ADDRESS = 'update_selected_address';
export const CLEAR_ADDRESS = 'clear_address';
export const NOTIFICATIONS_USER = 'notifications_user';
export const GET_USERS = 'get_users';
export const UPDATE_DATA_PASSWORD = 'update_data_password';
export const CLEAR_UPDATE_PASSWORD = 'clear_update_password';

/////////////////////////////////////////////////////////////////////
//////////////////////////// CUSTOMERS //////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CUSTOMER = 'add_customer';
export const CLEAR_CUSTOMER = 'clear_customer';
export const UPDATE_CUSTOMER = 'update_customer';
export const CLEAR_UPDATE_CUSTOMER = 'clear_update_customer';
export const DELETE_CUSTOMER = 'delete_customer';

/////////////////////////////////////////////////////////////////////
///////////////////////////// ADDRESS ///////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_PROVINCE = 'get_province';
export const GET_CITY = 'get_city';
export const GET_SUBDISTRICT = 'get_subdistrict';
export const POST_COST = 'post_code';

/////////////////////////////////////////////////////////////////////
////////////////////////////// TERM /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_TERMOFPAYMENTS = 'get_termofpayments';
export const ADD_TERMOFPAYMENT = 'add_termofpayment';
export const CLEAR_TERMOFPAYMENT = 'clear_termofpayment';
export const UPDATE_TERMOFPAYMENT = 'update_termofpayment';
export const CLEAR_UPDATE_TERMOFPAYMENT = 'clear_update_termofpayment';